import React from 'react'
import bgImage from '../../assets/servicesImages/ml/hero.png'
import pic1 from '../../assets/servicesImages/ml/1.png'
import pic2 from '../../assets/servicesImages/ml/2.png'
import pic3 from '../../assets/servicesImages/ml/3.png'
import pic4 from '../../assets/servicesImages/ml/4.png'
import a from '../../assets/servicesImages/ml/a.png'
import b from '../../assets/servicesImages/ml/b.png'
import c from '../../assets/servicesImages/ml/c.png'
import a1 from '../../assets/servicesImages/ml/1a.png'
import b1 from '../../assets/servicesImages/ml/1b.png'
import c1 from '../../assets/servicesImages/ml/1c.png'
import ml from '../../assets/servicesImages/ml/ml.png'
import icon1 from '../../assets/servicesImages/ml/icon1.png'
import icon2 from '../../assets/servicesImages/ml/icon2.png'
import icon3 from '../../assets/servicesImages/ml/icon3.png'
import Slider from 'react-slick'
import imagea from '../../assets/servicesImages/ai/a.png'
import arrow from '../../assets/viewdetails.png'



const items = [
    {
        image: imagea,
        desc: 'Expertise: Our team comprises experienced AI professionals with deep knowledge across various industries.'

    },
    {
        image: imagea,
        title: 'Sales & Purchase Management',
        desc: 'Increase Productivity & Lower Costs By Managing Your Sales & Purchased. It Manages complete cycle from Purchases to Sales Order.  '
    },
    {
        image: imagea,
        title: 'Sales & Purchase Management',
        desc: 'Increase Productivity & Lower Costs By Managing Your Sales & Purchased. It Manages complete cycle from Purchases to Sales Order.  '
    },

]

const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    centerPading:'20px',
    dots:true,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />
    responsive: [
          {
              breakpoint: 1024, // Desktop view
              settings: {
                  slidesToShow: 3, // Show 3 slides at a time
                  centerPadding: "100px", // Keep the centerPadding for desktop
              },
          },
          {
              breakpoint: 768, // Tablet view
              settings: {
                  slidesToShow: 2, // Show 2 slides at a time
                  centerPadding: "60px", // Adjust padding for tablet
              },
          },
          {
              breakpoint: 480, // Mobile view
              settings: {
                  slidesToShow: 1, // Show 1 slide at a time
                  centerPadding: "0px", // No padding on mobile
                  centerMode: false, // Disable centerMode for mobile
              },
          },
      ],
};

const settings2 = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 1000,
    centerPading:'20px',
    dots:true,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />
    responsive: [
          {
              breakpoint: 1024, // Desktop view
              settings: {
                  slidesToShow: 3, // Show 3 slides at a time
                  centerPadding: "100px", // Keep the centerPadding for desktop
              },
          },
          {
              breakpoint: 768, // Tablet view
              settings: {
                  slidesToShow: 2, // Show 2 slides at a time
                  centerPadding: "60px", // Adjust padding for tablet
              },
          },
          {
              breakpoint: 480, // Mobile view
              settings: {
                  slidesToShow: 1, // Show 1 slide at a time
                  centerPadding: "0px", // No padding on mobile
                  centerMode: false, // Disable centerMode for mobile
              },
          },
      ],
};

const item = [
    {
        icon: a1,
        title: 'Consultation and Assessment',
        desc: 'We begin by understanding your business objectives and challenges. Our team conducts a thorough assessment to identify opportunities where ML can add value.'
    },
    {
        icon: b1,
        title: 'Data Preparation',
        desc: 'We gather, clean, and preprocess your data to ensure it’s ready for analysis. This step is crucial for building accurate and reliable models.'
    },
    {
        icon: c1,
        title: 'Model Development',
        desc: 'Using the latest ML algorithms and tools, we develop models tailored to your specific needs. We rigorously test and validate these models to ensure they meet your requirements.'
    },
]
const cards = [
    {
        image: pic1,
        tile: '  Data Visualization & Processing capabilities',
        desc: 'Data processing involves collecting, cleaning, and organizing data for analysis. Data visualization uses graphical tools to represent this data, highlighting key trends and insights for decision-making.',
    },
    {
        image: pic2,
        tile: 'Automates repetitive tasks',
        desc: 'Automation streamlines repetitive tasks, improving efficiency and accuracy by using tools like scripts, bots, or software solutions to handle routine processes without manual intervention.',
    },
    {
        image: pic3,

        tile: 'Analyzing massive data volumes',
        desc: 'Analyzing massive data volumes uses advanced tools and techniques to handle large datasets. This process extracts valuable insights and trends. It supports informed decision-making and strategic Plan.',
    },
    {
        image: pic4,
        tile: 'Recognizes complex patterns',
        desc: 'Recognizing complex patterns involves using advanced algorithms and machine learning to detect intricate trends within data. This process uncovers hidden relationships and correlations.',

    }
]
const ML = () => {
    return (
        <div className="flex flex-col gap-16">

        {/* Section 1: Hero Section */}
        <div
          className="h-screen w-full text-white flex items-center pt-24 relative bg-cover bg-center"
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <div className="absolute inset-0 bg-[rgba(89,119,221,0.15)]"></div>
          <div className="flex flex-col gap-6 px-6 md:px-16 z-10">
            <h1 className="text-3xl md:text-5xl font-bold leading-snug text-left max-w-md">
              Machine Learning Services
            </h1>
            <p className="text-sm md:text-base font-light leading-relaxed text-left max-w-md">
              A machine learning service provider allows businesses to predict better user behavior, lead up-sell and optimize processes.
            </p>
          </div>
        </div>
      
        {/* Section 2: Features Heading */}
        <div className="flex flex-col gap-6 px-6 md:px-16 text-center">
          <h2 className="text-2xl md:text-3xl font-semibold">Features of Machine Learning</h2>
          <p className="text-sm md:text-base leading-relaxed">
            In today’s fast-paced digital landscape, efficiency, scalability, and reliability are key. Our DevOps services are designed to bridge the gap between development and operations, ensuring that your software delivery process is seamless, automated, and robust.
          </p>
        </div>
      
        {/* Section 3: Cards */}
        <div className="flex flex-wrap justify-center gap-4 px-4 md:gap-8">
          {cards.map((item) => (
            <div className="w-full sm:w-[292px] rounded-lg shadow-lg bg-white p-4">
              <img src={item.image} alt="icon" className="w-full h-auto mb-4 rounded-md" />
              <h3 className="text-center text-base font-semibold">{item.tile}</h3>
              <p className="text-sm leading-relaxed text-justify">{item.desc}</p>
            </div>
          ))}
        </div>
      
        {/* Section 4: Solutions */}
        <div className="flex flex-col gap-6 px-6 md:px-16 text-center">
          <h2 className="text-2xl md:text-3xl font-semibold">Our Machine Learning Solutions</h2>
          <p className="text-sm md:text-base leading-relaxed text-justify">
            Our machine learning solutions leverage advanced algorithms to analyze data and generate insights. We provide customized models tailored to specific business needs, enhancing decision-making and operational efficiency.
          </p>
        </div>
      
        {/* Section 5: Images */}
        <div className="flex flex-wrap gap-4 justify-center px-4">
          {[a, b, c].map((src, idx) => (
            <img
              key={idx}
              src={src}
              alt={`Image ${idx + 1}`}
              className="w-full sm:w-[45%] lg:w-[30%] rounded-lg"
            />
          ))}
        </div>
      
        {/* Section 6: Why Choose Us */}
        <div className="flex flex-col gap-6 px-6 md:px-16">
          <h2 className="text-2xl md:text-3xl font-semibold text-center">Why Choose Us?</h2>
          <div className="flex flex-col md:flex-row justify-between items-center gap-8">
            <img src={ml} alt="Machine Learning" className="w-full md:w-1/2 rounded-lg" />
            <div className="flex flex-col gap-6 w-full md:w-1/2">
              {[
                { icon: icon1, title: "Expertise", desc: "Our team of seasoned data scientists and ML engineers..." },
                { icon: icon2, title: "Custom Solutions", desc: "We understand that every business is unique..." },
                { icon: icon3, title: "End-to-End Support", desc: "From initial consultation to deployment..." },
              ].map((item, idx) => (
                <div key={idx} className="flex items-start gap-4">
                  <img src={item.icon} alt={item.title} className="w-8 h-8" />
                  <div>
                    <h3 className="text-lg font-semibold">{item.title}</h3>
                    <p className="text-sm leading-relaxed">{item.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      
        {/* Slider Section */}
        <Slider {...settings2} className="overflow-hidden w-full px-6">
          {item.map((item) => (
            <div key={item.title} className="p-4">
              <div className="bg-white rounded-lg shadow-lg p-6">
                <img src={item.icon} alt="icon" className="w-16 h-16 mx-auto mb-4" />
                <h3 className="text-center text-lg font-semibold">{item.title}</h3>
                <p className="text-center text-sm">{item.desc}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      

        // <div className='flex flex-col gap-[64px]'>

        //     <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
        //         style={{ backgroundImage: `url(${bgImage})` }}>
        //         <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
        //         <div className='flex flex-col gap-[48px] p-28 z-10'>
        //             <div className='flex flex-col  gap-[32px]'>
        //                 <p className='w-[500px] text-[48px] leading-[67.2px] text-left'>
        //                     Machine Learning Services                      </p>
        //                 <p className="text-[18px] w-[500px] font-normal leading-[25.6px] text-left">
        //                     A machine learning service provider allows businesses to predict better user behavior, lead up-sell and optimize processes.
        //                 </p>
        //             </div>
        //         </div>
        //     </div>


        //     <div className='flex flex-col gap-[36px] px-28'>
        //         <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Features of Machine Learning</p>
        //         <p className='text-[16px] leading-[25.6px] text-justify'>
        //             In today’s fast-paced digital lanmlcape, efficiency, scalability, and reliability are key. Our DevOps services are designed to bridge the gap between development and operations, ensuring that your software delivery process is seamless, automated, and robust.
        //         </p>
        //     </div>

        //     <div className='flex justify-center gap-10'>

        //         {cards.map((item) => (
        //             <div className='w-[292px] h-[447px] rounded-lg shadow-[0px_4px_4px_0px_#00000040]' >
        //                 <img src={item.image} alt='icon1' className='mb-3' />
        //                 <div className='flex flex-col  gap-[12px] px-5'>
        //                     <p className='text-[16px] px-8 font-semibold text-center'>
        //                         {item.tile}
        //                     </p>
        //                     <p className="text-[16px] font-normal text-left">
        //                         {item.desc}
        //                     </p>
        //                 </div>
        //             </div>
        //         ))}

        //     </div>

        //     <div className='flex flex-col gap-[36px] px-28'>
        //         <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Our Machine Learning Solutions</p>
        //         <p className='text-[16px] leading-[25.6px] text-justify'>
        //             Our machine learning solutions leverage advanced algorithms to analyze data and generate insights. We provide customized models tailored to specific business needs, enhancing decision-making and operational efficiency. Our solutions cover various applications, from predictive analytics to natural language processing. With a focus on scalability and performance, we help organizations unlock the full potential of their data.
        //         </p>
        //     </div>

        //     <div className='flex h-[441px] gap-10 justify-center'>
        //         <img src={a} alt='a' className='w-[363.75px] rounded-lg' />
        //         <img src={b} alt='b' className='w-[363.75px] rounded-lg' />
        //         <img src={c} alt='c' className='w-[363.75px] rounded-lg' />
        //     </div>

        //     <div className='flex flex-col gap-[36px]'>
        //         <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'> Why Choose Us?</p>
        //         <div className='flex justify-center items-center gap-10  h-[421.83px]'>
        //             <img src={ml} alt='ml' className='w-[507.21px]' />
        //             <div className='w-[674.06px]'>
        //                 <div className='pb-5'>
        //                     <div className='flex items-center gap-3'>
        //                         <img src={icon1} alt='ml' className='w-[30px] h-[30px]' />
        //                         <p className='text-[20px]' >Expertise</p>
        //                     </div>
        //                     <p className='text-[16px] px-10 pt-2'>Our team of seasoned data scientists and ML engineers brings deep expertise across various industries. We stay at the forefront of technological advancements to deliver the best solutions to our clients.</p>
        //                 </div>
        //                 <div className='pb-5'>
        //                     <div className='flex items-center gap-3'>
        //                         <img src={icon2} alt='ml' className='w-[30px] h-[30px]' />
        //                         <p className='text-[20px]' >Custom Solutions</p>
        //                     </div>
        //                     <p className='text-[16px] px-10 pt-2'>We understand that every business is unique. Our solutions are tailored to meet your specific needs, ensuring maximum impact and ROI.</p>
        //                 </div>
        //                 <div className='pb-5'>
        //                     <div className='flex items-center gap-3'>
        //                         <img src={icon3} alt='ml' className='w-[30px] h-[30px]' />
        //                         <p className='text-[20px]' >End-to-End Support</p>
        //                     </div>
        //                     <p className='text-[16px] px-10 pt-2'>From initial consultation to deployment and maintenance, we provide comprehensive support throughout your ML journey. We work closely with you to ensure seamless integration and optimal performance.</p>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>


        //     <Slider {...settings2} className="overflow-hidden w-full px-10">
        //         {item.map((item) => (
        //             <div>

        //                 <div className='w-[362px] h-[377px] p-[24px_18px_24px_18px_] flex flex-col items-center justify-center bg-[white] gap-[8px] border-2 rounded-lg'>

        //                     <div className='w-[237px] flex justify-center'>
        //                         <img src={item.icon} alt='icon1' className='w-[60.33px] h-[59.49px]' />

        //                     </div>
        //                     <p className='text-[#3C4858] font-[900] font-[20px]'>
        //                         {item.title}
        //                     </p>
        //                     <p className='font-[#161C2D] font-[16px] leading-[25.6px] text-center px-3'>
        //                         {item.desc}
        //                     </p>
        //                 </div>
        //             </div>
        //         ))}
        //     </Slider>
        //     <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>
        //         Why Choose Esparse Matrix Solutions for Machine Learning Services?
        //     </p>
        //     <Slider {...settings} className="overflow-hidden w-full px-10">

        //         {items.map((item) => (
        //             <div className=''>
        //                 <div className='w-[292px] h-[fit] mb-10 flex flex-col bg-[white] gap-[8px] shadow-[0px_4px_4px_0px_#00000040] rounded-lg'>

        //                     <div className=' flex flex-col items-center gap-[10px] overflow-hidden  '>
        //                         <div className='h-[30%] w-auto overflow-hidden  rounded-tr-lg  rounded-tl-lg'>
        //                             <img
        //                                 src={item.image}
        //                                 alt="icon1"
        //                                 className="transition-transform duration-300 ease-in-out transform hover:scale-110"
        //                             />
        //                         </div>

        //                         <p className='font-[#161C2D] text-left font-semibold font-[16px] px-3'>
        //                             {item.desc}
        //                         </p>
        //                     </div>
        //                     <div className='flex text-[#0058AE] text-[12px] p-4 gap-1'>
        //                         <a href='/'>View Details</a>
        //                         <img src={arrow} alt='arrow' className='w-[9px]' />
        //                     </div>
        //                 </div>


        //             </div>


        //         ))}
        //     </Slider>


        // </div>
    )
}

export default ML