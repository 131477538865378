import React from "react";
import bgImage from "../../assets/servicesImages/ai/hero.png";
import card1 from "../../assets/servicesImages/ai/1.png";
import card2 from "../../assets/servicesImages/ai/2.png";
import card3 from "../../assets/servicesImages/ai/3.png";
import imagea from "../../assets/servicesImages/ai/a.png";
import Slider from "react-slick";
import arrow from "../../assets/viewdetails.png";

const items = [
  {
    image: imagea,
    desc: "Expertise: Our team comprises experienced AI professionals with deep knowledge across various industries.",
  },
  {
    image: imagea,
    title: "Sales & Purchase Management",
    desc: "Increase Productivity & Lower Costs By Managing Your Sales & Purchased. It Manages complete cycle from Purchases to Sales Order.  ",
  },
  {
    image: imagea,
    title: "Sales & Purchase Management",
    desc: "Increase Productivity & Lower Costs By Managing Your Sales & Purchased. It Manages complete cycle from Purchases to Sales Order.  ",
  },
];

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 4,
  speed: 500,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1024, // Desktop view
      settings: {
        slidesToShow: 4, // Show 3 slides at a time
        centerPadding: "100px", // Keep the centerPadding for desktop
      },
    },
    {
      breakpoint: 768, // Tablet view
      settings: {
        slidesToShow: 2, // Show 2 slides at a time
        centerPadding: "60px", // Adjust padding for tablet
      },
    },
    {
      breakpoint: 480, // Mobile view
      settings: {
        slidesToShow: 1, // Show 1 slide at a time
        centerPadding: "0px", // No padding on mobile
        centerMode: false, // Disable centerMode for mobile
      },
    },
  ],
};

const cards = [
  {
    card: card1,
    title: "AI Consulting ",
    desc: "Our AI Consulting experts analyze your operations, align with your business goals, pinpoint opportunities, and provide a seamless AI integration roadmap.",
  },
  {
    card: card2,
    title: "AI Software Devlopment",
    desc: "We specialize in the design, engineering, and deployment of custom end-to-end AI solutions that drive automation, informed decision-making, and innovation.",
  },
  {
    card: card3,
    title: "AI Date Architecture & Management",
    desc: "Leverage our expertise to unlock your data’s full potential, optimising collection, management, prediction, and automation with tailored solutions.",
  },
];

const services = [
  {
    card: card1,
    title: "AI Strategy & Consulting",
    desc: "Develop a strategic plan to integrate AI into your business operations effectively. We assess your current capabilities, identify opportunities, and create a roadmap tailored to your objectives.",
  },
  {
    card: card2,
    title: "Custom AI Solutions",
    desc: "Create bespoke AI models and systems designed to solve your      specific business problems. We tailor our solutions to meet your unique      needs and integrate seamlessly with your existing infrastructure.",
  },
  {
    card: card3,
    title: "Machine Learning (ML) Services",
    desc: "Build and deploy machine learning models to analyze data, generate insights, and support data-driven decision-making. We handle      model development, training, and optimization.",
  },
  {
    card: card3,
    title: "Natural Language Processing (NLP)",
    desc: "Leverage NLP technologies to analyse and understand human language data. We develop solutions for text analysis, sentiment      detection, and language-based automation.",
  },
  {
    card: card3,
    title: "Computer Vision",
    desc: "Implement computer vision solutions to interpret and analyse  visual data from images and videos. Our services include object detection, image recognition, and video analytics.",
  },
  {
    card: card3,
    title: "AI-Powered Automation",
    desc: "Automate repetitive tasks and workflows using AI technologies. We help you streamline processes and improve efficiency through intelligent automation solutions.",
  },
  {
    card: card3,
    title: "AI Integration and Deployment",
    desc: ": Integrate AI solutions into your existing systems and workflows. We ensure a smooth deployment process and provide ongoing support to optimize performance.",
  },
  {
    card: card3,
    title: "Ethical AI and Governance",
    desc: "Establish governance frameworks to ensure the ethical use of AI      technologies. We address compliance, transparency, and risk management to      align with best practices.",
  },
];

const AI = () => {
  return (
    <div className="flex flex-col gap-[64px]">
      <div
        className="h-screen w-full text-white flex justify-start items-center pt-24 relative bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)] z-0"></div>
        <div className="flex flex-col gap-12 p-6 sm:p-12 md:p-20 lg:p-28 z-10">
          <div className="flex flex-col max-w-[90%] sm:max-w-[470px] gap-6">
            <p className="font-nunito text-3xl sm:text-4xl md:text-5xl leading-tight text-left">
              Artificial Intelligence software Development
            </p>
            <p className="text-lg sm:text-xl font-normal leading-relaxed text-left">
              AI software development transforms data into intelligence,
              automating tasks and driving innovation in various industries.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:gap-[36px] md:px-36">
        <p className="text-center font-nunito text-2xl sm:text-3xl font-semibold leading-tight">
          Artificial Intelligence software Development
        </p>
        <p className="font-nunito text-[16px] px-3 mt-5 md:mt-0 md:px-0 font-medium leading-[25.6px] text-justify">
          With a human-centric approach and leveraging the power of AI, we focus
          on automating workflows, deploying advanced customer service chatbots,
          and offering predictive insights for strategic business decisions.
          This commitment ensures not just efficiency and cost savings, but also
          enriched experiences for customers and employees, shaping an
          AI-empowered future for your business that resonates with innovation
          and purpose.
        </p>
      </div>

      <div className="flex flex-col md:gap-[36px] md:px-36">
        <p className="text-center font-nunito text-2xl sm:text-3xl font-semibold leading-tight">
          Our Field of Specialization
        </p>
        <p className="font-nunito text-[16px] px-3 mt-5 md:mt-0 md:px-0 font-medium leading-[25.6px] text-justify">
          We provide a wide range of mobile app development services that
          benefit the businesses and organisations in which we collaborate. Our
          dedicated team of mobile app developers provides a variety of services
          to meet the various business needs. The following programmes are our
          specialties:{" "}
        </p>
      </div>

      <div className="flex flex-wrap gap-6 justify-center">
        {cards.map((c, index) => (
          <div
            key={index}
            className="w-full sm:w-[300px] md:w-[300px] lg:w-[300px] h-auto flex flex-col gap-4 p-6 items-center rounded-lg shadow-md border mx-3 md:mx-0"
          >
            <img
              src={c.card}
              alt="card1"
              className="h-[170px] w-[256px] rounded-lg object-cover"
            />
            <p className="font-bold text-[18px] sm:text-[20px] text-center">
              {c.title}
            </p>
            <p className="text-[14px] sm:text-[16px] text-center">{c.desc}</p>
          </div>
        ))}
      </div>

      <p className="text-center font-nunito text-2xl sm:text-3xl font-semibold leading-tight">
        Our AI Services
      </p>

      <div className="flex gap-12 flex-wrap justify-center">
        {services.map((c) => (
          <div className="w-[300px] h-[452px] flex flex-col gap-[8px] items-center rounded-lg shadow-[0px_4px_4px_0px_#00000040] mx-3 md:mx-0">
            <img
              src={c.card}
              alt="card1"
              className="rounded-tl-lg rounded-tr-lg h-[200px] w-full"
            />
            <div className="px-4 h-[180px]  flex flex-col gap-4 ">
              <p className="font-bold text-[16px] text-center">{c.title} </p>
              <p className="text-[16px]">{c.desc}</p>
            </div>
            <button className="bg-[#006BD3] w-[112px] h-[34px] rounded-md text-white">
              View Details
            </button>
          </div>
        ))}
      </div>


      <p className="text-center font-nunito text-2xl sm:text-3xl font-semibold leading-tight">
        Why Choose Esparse Matrix Solutions?
      </p>

      <Slider {...settings} className="overflow-hidden w-full px-10">
        {items.map((item) => (
          <div className="px-0 md:px-2">
            <div className="w-full h-[fit] mb-10 flex flex-col bg-[white] gap-[8px] shadow-[0px_4px_4px_0px_#00000040] rounded-lg border">
              <div className=" flex flex-col items-center gap-[10px] overflow-hidden  ">
                <div className="h-[30%] w-auto overflow-hidden  rounded-tr-lg  rounded-tl-lg">
                  <img
                    src={item.image}
                    alt="icon1"
                    className="transition-transform duration-300 ease-in-out transform hover:scale-110"
                  />
                </div>

                <p className="font-[#161C2D] text-left font-semibold text-sm px-3">
                  {item.desc}
                </p>
              </div>
              <div className="flex text-[#0058AE] text-[12px] p-4 gap-1">
                <a href="/">View Details</a>
                <img src={arrow} alt="arrow" className="w-[9px]" />
              </div>
            </div>
          </div>
        ))}
      </Slider>
      {/* {/ </div> /}
      {/ </div> /} */}
    </div>
  );
};

export default AI;
