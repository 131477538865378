import React from 'react'
import image1 from '../assets/productsNavbar/card1.png'
import image2 from '../assets/productsNavbar/card2.png'
import image3 from '../assets/productsNavbar/card3.png'
import icon1 from '../assets/homeServicesSection/icon3.png'
import icon2 from '../assets/homeServicesSection/icon1.png'
// import icon3 from '../assets/homeServicesSection/icon2.png'
import arrow from '../assets/viewdetails.png'
import { Link } from 'react-router-dom'


const services = [
    {
        icon: icon1,
        image: image1,
        title: "Avinya E-learning Platform",
        desc: 'Avinya E-learning is a platform for teachers to manage their online coaching. It offers tools for student management, course creation, and virtual classrooms. Sign up today to streamline your teaching process..',
        link: '/e-learn'

    },
    {
        icon: icon2,
        image: image2,
        title: "Avinya NGO app",
        desc: "Avinya NGO App is a mobile app for NGOs. It helps manage projects, volunteers, and donors efficiently. Download now to streamline your organization's operations.",
        link: '/ngo'

    },
    {
        icon: icon2,
        image: image3,
        title: "Esparse Logistics Software",
        desc: 'sparse Logistics Software streamlines supply chains with real-time tracking, route optimization, and inventory control. Improve your logistics efficiency today.',
        link: '/logistics'

    },
]
const ProductsNavbar = () => {
    return (
        <div className='w-full flex bg-white h-[390px] p-[24px] px-[16px] sm:px-[88px] shadow-[0px_4px_4px_0px_#00000040] gap-[6px] absolute top-20 left-0 z-10 overflow-x-auto'>
  <div className='w-full flex flex-nowrap sm:flex-wrap justify-start mx-auto gap-6'>
    {services.map((s) => (
      <div
        className='flex-shrink-0 w-full sm:w-[48%] md:w-[32%] lg:w-[209px] h-[330px] shadow-[0px_0px_3px_0px_#3C485826] flex flex-col'
        key={s.id}
      >
        {/* Image Section */}
        <div className='h-[120px] lg:h-[180px] w-full overflow-hidden rounded-t'>
          <img
            src={s.image}
            alt='service'
            className='w-full h-full object-cover'
          />
        </div>

        {/* Content Section */}
        <div className='w-full bg-white rounded-b flex flex-col items-center gap-4 p-4 relative'>
          <div className='w-[49px] h-[49px] p-[10px] rounded-full shadow-[0_2px_2px_0px_rgba(0,0,0,0.25)] bg-white absolute -top-6 left-1/2 transform -translate-x-1/2'>
            <img
              src={s.icon}
              alt='icon'
              className='w-full h-full object-contain'
            />
          </div>

          <div className='text-center w-[166px] flex flex-col gap-[9.19px] mt-8'>
            <p className='text-[12px] font-bold leading-[14.71px] text-black'>
              {s.title}
            </p>
            <p className='text-[10px] font-normal leading-[14.71px] text-black'>
              {s.desc}
            </p>
            <Link to={s.link}>
              <div className='flex items-center text-[12px] gap-1'>
                <span>View Details</span>
                <img src={arrow} alt='arrow' className='w-[9px]' />
              </div>
            </Link>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>

        // <div className='w-full flex bg-[white] h-[390px] p-[24px] px-[88px] shadow-[0px_4px_4px_0px_#00000040] gap-[6px] absolute top-20 left-0 z-10 '>

        //     <div className='w-[1264px] h-[333.3px] pt-[2px] pb-0 pl-0 pr-0 gap-[60px] relative flex'>

        //         {services.map((s) => (
        //             <div className='w-[209px] h-[330px] shadow-[0px_0px_3px_0px_#3C485826]'>

        //                 <img src={s.image} className='object-cover h-[150px] w-full rounded-t' alt='service' />

        //                 <div className='w-[209px] bg-[#FFF] rounded flex flex-col items-center  gap-4 '>
        //                     <div className='w-[49px] h-[49px] p-[10px] rounded-[79.48px] shadow-[0_2px_2px_0px_rgba(0,0,0,0.25)] bg-[#FFFFFF] absolute bottom-40'>
        //                         <img src={s.icon} alt='icon1' className=' '
        //                         />
        //                     </div>
        //                     <div className='text-center w-[166px] flex flex-col gap-[9.19px] mt-8'>
        //                         <p className='text-[12px] font-bold leading-[14.71px] text-centers text-[black]'>
        //                             {s.title}
        //                         </p>
        //                         <p className='text-[10px] font-normal leading-[14.71px] text-left text-[black] '>
        //                             {s.desc}
        //                         </p>
        //                         <Link to={s.link}>
        //                             <div className='flex items-center text-[12px] gap-1'>
        //                                 <a href='/webdev'>View Details</a>
        //                                 <img src={arrow} alt='arrow' className='w-[9px]' />

        //                             </div>
        //                         </Link>
        //                     </div>

        //                 </div>
        //             </div>
        //         ))}
        //     </div>
        // </div>

    )
}

export default ProductsNavbar