import React from 'react'
import bgImage from '../../assets/products/hero2.png'
import card from '../../assets/products/card.png'
import card2 from '../../assets/products/card2.png'


const items = new Array(8).fill(
    {
        image: card,
        title: 'Recorded Sessions',
        desc: 'Access to recorded classes for review and revision Flexible viewing options for students with different schedules',
        link: ''
    }
)
const items2 = new Array(3).fill(
    {
        image: card2,
        title: 'Encouraging Enrollment',
        desc: 'These mobile applications may also assist you in attracting global memberships in addition to your online job, which will ultimately help in the fundraising of funds for your non-profit organization.',
        link: ''
    }
)



const NGO = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

    <div className='h-screen w-full text-[#fff] flex justify-start items-center pt-24 relative bg-cover bg-center'
        style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)] z-0"></div>
        <div className='flex flex-col gap-[48px] p-6 md:p-16 lg:p-28 z-10'>
            <div className='flex flex-col gap-[32px]'>
                <p className='font-nunito text-[32px] md:text-[48px] lg:text-[48px] leading-[40px] md:leading-[67.2px] text-left'>
                    Avinya NGO App
                </p>
                <p className="text-[16px] md:text-[18px] lg:text-[18px] font-normal leading-[25.6px] text-left max-w-[470px]">
                    The Ultimate Learning Hub for Students
                </p>
            </div>
        </div>
    </div>

    <p className='text-center font-nunito text-[24px] md:text-[32px] lg:text-[32px] font-semibold leading-[23.4px]'>
        Features of Avinya NGO App
    </p>

    <div className='flex flex-wrap justify-center gap-[20px] md:gap-[30px]'>
        {items.map((item) => (
            <div className='w-[100%] sm:w-[292px] lg:w-[292px] h-[auto] flex flex-col items-center bg-white gap-[8px] border-2 rounded-lg'>
                <img src={item.image} alt='card' className='rounded-tr-lg rounded-tl-lg w-full' />
                <div className='h-[90%] flex flex-col items-center gap-[10px] px-6'>
                    <p className='text-[#3C4858] text-center font-bold text-[16px] md:text-[20px]'>
                        {item.title}
                    </p>
                    <p className='font-[#161C2D] text-center text-[14px] md:text-[16px] leading-[14px]'>
                        {item.desc}
                    </p>
                </div>
                <button className='bg-[#006BD3] w-[241px] h-[24px] rounded text-[white] mb-5'>View More</button>
            </div>
        ))}
    </div>

    <div className='flex flex-col gap-[32px] w-full'>
        <p className='text-center text-[24px] md:text-[32px] lg:text-[32px] font-semibold leading-[23.4px]'>
            Push Notifications
        </p>
        <p className='text-left text-[14px] md:text-[16px] lg:text-[16px] leading-[23.4px] px-6 md:px-32'>
            Our NGO app features Push Notifications to keep stakeholders informed and engaged. This feature allows you to send real-time alerts and updates directly to users' devices, including news, event reminders, and important announcements. Push Notifications ensure timely communication and enhance engagement with your NGO’s activities and initiatives.
        </p>
    </div>

    <div className='flex flex-wrap justify-center gap-[20px] md:gap-[30px] mb-10'>
        {items2.map((item) => (
            <div className='w-[100%] sm:w-[350px] lg:w-[350px] h-[auto] flex flex-col items-center bg-white gap-[8px] border-2 rounded-lg shadow-[0px_4px_4px_0px_#00000040]'>
                <img src={item.image} alt='card' className='rounded-tr-lg rounded-tl-lg w-full' />
                <div className='h-[90%] flex flex-col items-center gap-[10px] px-6'>
                    <p className='text-[#3C4858] text-center font-bold text-[16px] md:text-[20px]'>
                        {item.title}
                    </p>
                    <p className='font-[#161C2D] text-center text-[14px] md:text-[16px] leading-[24px]'>
                        {item.desc}
                    </p>
                </div>
            </div>
        ))}
    </div>

</div>

        // <div className='flex flex-col gap-[64px]'>

        //     <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
        //         style={{ backgroundImage: `url(${bgImage})` }}>
        //         <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
        //         <div className='flex flex-col gap-[48px] p-28 z-10'>
        //             <div className='flex flex-col  gap-[32px]'>
        //                 <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
        //                     Avinya NGO App
        //                 </p>
        //                 <p className="text-[18px] w-[470px] font-normal leading-[25.6px] text-left">
        //                     The Ultimate Learning Hub for Students
        //                 </p>
        //             </div>
        //         </div>
        //     </div>

        //     <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Features of Avinya NGO App</p>

        //     <div className='flex flex-wrap justify-center gap-[30px]'>
        //         {items.map((item) => (
        //             <div className=''>


        //                 <div className='w-[292px] h-[447px] flex flex-col items-center bg-[white] gap-[8px] border-2 rounded-lg'>
        //                     <img src={item.image} alt='card' className='rounded-tr-lg rounded-tl-lg' />

        //                     <div className=' h-[90%] flex flex-col items-center gap-[10px] px-6'>
        //                         <p className='text-[#3C4858] text-center font-bold font-[20px]'>
        //                             {item.title}
        //                         </p>
        //                         <p className='font-[#161C2D] text-center font-[16px] leading-[24px]'>
        //                             {item.desc}
        //                         </p>
        //                     </div>
        //                     <button className='bg-[#006BD3] w-[241px] h-[24px] rounded text-[white] mb-5'>View More</button>
        //                 </div>
        //             </div>

        //         ))}

        //     </div>

        //     <div className='flex flex-col  gap-[32px] w-full'>
        //         <p className='text-center text-[32px] font-semibold leading-[23.4px]'>Push Notifications</p>
        //         <p className='text-left text-[16px] leading-[23.4px] px-32'>Our NGO app features Push Notifications to keep stakeholders informed and engaged. This feature allows you to send real-time alerts and updates directly to users' devices, including news, event reminders, and important announcements. Push Notifications ensure timely communication and enhance engagement with your NGO’s activities and initiatives.</p>

        //     </div>


        //     <div className='flex flex-wrap justify-center gap-[30px] mb-10'>
        //         {items2.map((item) => (



        //             <div className='w-[350px] h-[441px] flex flex-col items-center bg-[white] gap-[8px] border-2 rounded-lg shadow-[0px_4px_4px_0px_#00000040]'>
        //                 <img src={item.image} alt='card' className='rounded-tr-lg rounded-tl-lg' />

        //                 <div className=' h-[90%] flex flex-col items-center gap-[10px] px-6'>
        //                     <p className='text-[#3C4858] text-center font-bold font-[20px]'>
        //                         {item.title}
        //                     </p>
        //                     <p className='font-[#161C2D] text-center font-[16px] leading-[24px]'>
        //                         {item.desc}
        //                     </p>
        //                 </div>
        //             </div>

        //         ))}

        //     </div>

        // </div>
    )
}

export default NGO