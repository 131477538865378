import React from 'react'

const AboutSection2 = () => {
    return (
        <div className="flex flex-col gap-6 px-4 sm:px-6 md:px-8 lg:px-16">
  {/* Title */}
  <p className="text-center text-black font-nunito text-[18px] sm:text-[24px] md:text-[28px] lg:text-[32px] font-semibold leading-tight">
    Who We Are?
  </p>

  {/* Description */}
  <p className="font-nunito text-[14px] sm:text-[16px] md:text-[18px] lg:text-[18px] font-medium leading-relaxed text-justify">
    The company was established in the year 2019, with the goal to provide high-quality, cost-effective services to businesses. We desire to grow day by day to become the most trusted partner for our clients, delivering software development services. Today we have a team of experienced and skillful professionals, software engineers, technology enthusiasts, and creative designers. Within a short time span, we have successfully delivered over 50+ projects to clients worldwide.
  </p>
</div>

        // <div className='flex flex-col gap-[36px] px-64'>
        //     <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Who We Are?</p>
        //     <p className='font-nunito text-[16px] font-medium leading-[25.6px] text-justify'>The company was established in the year 2019, with the goal to provide high quality cost effective services to the businesses. We desire to grow day to become the most trusted partner for our clients delivering Software development service. Today we have a team of experienced and skilful team of professional’s software engineers, technology enthusiasts with creative designers. Within a short time span, we have successfully delivered over 50+ projects to clients worldwide. </p>
        // </div>
    )
}

export default AboutSection2