import React from 'react'
import r1 from '../assets/readmore/image1.png'
import r2 from '../assets/readmore/image2.png'
import r3 from '../assets/readmore/image3.png'
import r4 from '../assets/readmore/image4.png'

const ReadmoreHome = () => {
    return (
      <div className="h-auto sm:h-[527px] px-4 sm:px-6 md:px-16 py-8 flex flex-col gap-10 items-center">
      <p className="text-[24px] sm:text-[18px] md:text-[32px] font-semibold leading-[1.3] text-center">
        Esparse Matrix Solutions News & Events
      </p>
    
      {/* Flex container for cards */}
      <div className="w-full flex flex-wrap gap-6 justify-center">
        {/* Card Template */}
        {[r1, r2, r3, r4].map((image, index) => (
          <div
            key={index}
            className="w-full sm:w-[45%] md:w-[22%] lg:w-[22%] h-auto rounded-lg shadow-[0px_0px_3px_0px_#3C485826] overflow-hidden"
          >
            <img
              src={image}
              alt={`Card ${index + 1}`}
              className="w-full h-[200px] object-cover rounded-t-lg"
            />
            <div className="p-6 flex flex-col gap-4">
              <p className="text-[14px] sm:text-[16px] leading-[1.5] text-left">
                {[
                  "How Might A Mobile App Help Your Non-Profit Company?",
                  "The importance of mobile apps for educational institutions",
                  "The Ultimate Tutorial: Developing Your Own Educational App in 2023",
                  "Trends in UI/UX Design for 2023",
                ][index]}
              </p>
              <button className="w-full font-medium py-2 px-3 rounded border-2 border-[#000] text-center">
                Read more...
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
      
//       <div className="h-auto sm:h-[527px] px-4 sm:px-6 md:px-16 py-8 flex flex-col gap-10 items-center">
//   <p className="text-[24px] sm:text-[18px] md:text-[32px] font-semibold leading-[1.3] text-center">
//     Esparse Matrix Solutions News & Events
//   </p>

//   {/* Flex container for cards */}
//   <div className="w-full flex flex-wrap gap-6 justify-center">
//     {/* Card Template */}
//     {[r1, r2, r3, r4].map((image, index) => (
//       <div
//         key={index}
//         className="w-full sm:w-[47%] md:w-[30%] lg:w-[22%] h-auto rounded-lg shadow-[0px_0px_3px_0px_#3C485826] overflow-hidden"
//       >
//         <img
//           src={image}
//           alt={`Card ${index + 1}`}
//           className="w-full h-[200px] object-cover rounded-t-lg"
//         />
//         <div className="p-6 flex flex-col gap-4">
//           <p className="text-[14px] sm:text-[16px] leading-[1.5] text-justify">
//             {[
//               "How Might A Mobile App Help Your Non-Profit Company?",
//               "The importance of mobile apps for educational institutions",
//               "The Ultimate Tutorial: Developing Your Own Educational App in 2023",
//               "Trends in UI/UX Design for 2023",
//             ][index]}
//           </p>
//           <button className="w-full font-medium py-2 px-3 rounded border-2 border-[#000] text-center">
//             Read more...
//           </button>
//         </div>
//       </div>
//     ))}
//   </div>
// </div>

//         <div className="h-auto sm:h-[527px] px-4 sm:px-6 md:px-16 py-8 flex flex-col gap-10 items-center">
//   <p className="text-[24px] sm:text-[18px] md:text-[32px] font-semibold leading-[1.3] text-center">
//     Esparse Matrix Solutions News & Events
//   </p>

//   {/* Flex container for cards */}
//   <div className="w-full sm:w-[1079px] flex flex-wrap gap-6 justify-center">
//     {/* Card Template */}
//     {[r1, r2, r3, r4].map((image, index) => (
//       <div
//         key={index}
//         className="w-full sm:w-[47%] md:w-[30%] lg:w-[22%] h-auto rounded-lg shadow-[0px_0px_3px_0px_#3C485826] overflow-hidden"
//       >
//         <img src={image} alt={`Card ${index + 1}`} className="w-full h-[200px] object-cover rounded-t-lg" />
//         <div className="p-6 flex flex-col gap-4">
//           <p className="text-[14px] sm:text-[16px] leading-[1.5] text-left">
//             {[
//               "How Might A Mobile App Help Your Non-Profit Company?",
//               "The importance of mobile apps for educational institutions",
//               "The Ultimate Tutorial: Developing Your Own Educational App in 2023",
//               "Trends in UI/UX Design for 2023",
//             ][index]}
//           </p>
//           <button className="w-full font-medium py-2 px-3 rounded border-2 border-[#000] text-center">
//             Read more...
//           </button>
//         </div>
//       </div>
//     ))}
//   </div>
// </div>

//         <div className='h-auto sm:h-[527px] p-[33px_89px] flex flex-col gap-10 items-center'>
//    <p className='text-[32px] sm:text-[14px] md:text-[32px] font-semibold leading-[42px] text-center'>
//     Esparse Matrix Solutions News & Events
// </p>



//     {/* <!-- Flex container for cards --> */}
//     <div className='w-full sm:w-[1079px] h-auto flex flex-wrap gap-[30px] justify-center'>

//         {/* <!-- Card 1 --> */}
//         <div className='w-full sm:w-[247px] h-auto sm:h-[373px] rounded-lg shadow-[0px_0px_3px_0px_#3C485826]'>
//             <img src={r1} alt='r1' className='object-cover rounded-t-lg' />
//             <div className='w-full h-auto p-[24px] flex flex-col gap-[12px]'>
//                 <p className='text-[16px] sm:text-[16px] h-[87px] leading-[30px] text-left'>
//                     How Might A Mobile App Help Your Non-Profit Company?
//                 </p>
//                 <button className='w-full font-medium p-[8px_12px] rounded border-2 border-[#000]'>
//                     Read more...
//                 </button>
//             </div>
//         </div>

//         {/* <!-- Card 2 --> */}
//         <div className='w-full sm:w-[247px] h-auto sm:h-[373px] rounded-lg shadow-[0px_0px_3px_0px_#3C485826]'>
//             <img src={r2} alt='r1' className='object-cover rounded-t-lg' />
//             <div className='w-full h-auto p-[24px] flex flex-col gap-[12px]'>
//                 <p className='text-[16px] sm:text-[16px] h-[87px] leading-[30px] text-left'>
//                     The importance of mobile apps for educational institutions
//                 </p>
//                 <button className='w-full font-medium p-[8px_12px] rounded border-2 border-[#000]'>
//                     Read more...
//                 </button>
//             </div>
//         </div>

//         {/* <!-- Card 3 --> */}
//         <div className='w-full sm:w-[247px] h-auto sm:h-[373px] rounded-lg shadow-[0px_0px_3px_0px_#3C485826]'>
//             <img src={r3} alt='r1' className='object-cover rounded-t-lg' />
//             <div className='w-full h-auto p-[24px] flex flex-col gap-[12px]'>
//                 <p className='text-[16px] sm:text-[16px] h-[87px] leading-[30px] text-left'>
//                     The Ultimate Tutorial: Developing Your Own Educational App in 2023
//                 </p>
//                 <button className='w-full font-medium p-[8px_12px] rounded border-2 border-[#000]'>
//                     Read more...
//                 </button>
//             </div>
//         </div>

//         {/* <!-- Card 4 --> */}
//         <div className='w-full sm:w-[247px] h-auto sm:h-[373px] rounded-lg shadow-[0px_0px_3px_0px_#3C485826]'>
//             <img src={r4} alt='r1' className='object-cover rounded-t-lg' />
//             <div className='w-full h-auto p-[24px] flex flex-col gap-[12px]'>
//                 <p className='text-[16px] sm:text-[16px] h-[87px] leading-[30px] text-left'>
//                     Trends in UI/UX Design for 2023
//                 </p>
//                 <button className='w-full font-medium p-[8px_12px] rounded border-2 border-[#000]'>
//                     Read more...
//                 </button>
//             </div>
//         </div>

//     </div>
// </div>

        // <div className='h-[527px] p-[33px_89px] flex flex-col gap-10 items-center'>
        //     <p className='text-[32px] font-semibold leading-[42px] text-center'>Esparse Matrix Solutions News & Events</p>


        //     <div className='w-[1079px] h-[381px] flex gap-[30px]'>



        //         <div className='w-[247px] h-[373px] rounded-lg shadow-[0px_0px_3px_0px_#3C485826]'>
        //             <img src={r1} alt='r1' className='object-cover rounded-t-lg' />
        //             <div className='w-[247px] h-[197px] p-[24px] flex flex-col gap-[12px]'>
        //                 <p className='text-[16px]  h-[87px] leading-[30px] text-left'>
        //                     How Might A Mobile App Help Your Non- Profit Company?
        //                 </p>
        //                 <button className='w-full font-medium p-[8px_12px] rounded border-2 border-[#000]'>
        //                     Read more...
        //                 </button>
        //             </div>
        //         </div>


        //         <div className='w-[247px] h-[373px] rounded-lg shadow-[0px_0px_3px_0px_#3C485826]'>
        //             <img src={r2} alt='r1' className='object-cover rounded-t-lg' />
        //             <div className='w-[247px] h-[197px] p-[24px] flex flex-col gap-[12px]'>
        //                 <p className='text-[16px]  h-[87px] leading-[30px] text-left'>
        //                     The importance of mobile apps for educational institutions                            </p>
        //                 <button className='w-full font-medium p-[8px_12px] rounded border-2 border-[#000]'>
        //                     Read more...
        //                 </button>
        //             </div>
        //         </div>



        //         <div className='w-[247px] h-[373px] rounded-lg shadow-[0px_0px_3px_0px_#3C485826]'>
        //             <img src={r3} alt='r1' className='object-cover rounded-t-lg' />
        //             <div className='w-[247px] h-[197px] p-[24px] flex flex-col gap-[12px]'>
        //                 <p className='text-[16px]  h-[87px] leading-[30px] text-left'>
        //                     The Ultimate Tutorial:
        //                     Developing Your Own
        //                     Educational App in 2023
        //                 </p>
        //                 <button className='w-full font-medium p-[8px_12px] rounded border-2 border-[#000]'>
        //                     Read more...
        //                 </button>
        //             </div>
        //         </div>


        //         <div className='w-[247px] h-[373px] rounded-lg shadow-[0px_0px_3px_0px_#3C485826]'>
        //             <img src={r4} alt='r1' className='object-cover rounded-t-lg' />
        //             <div className='w-[247px] h-[197px] p-[24px] flex flex-col gap-[12px]'>
        //                 <p className='text-[16px] h-[87px] leading-[30px] text-left'>
        //                     Trends in UI/UX Design for
        //                     2023
        //                 </p>
        //                 <button className='w-full font-medium p-[8px_12px] rounded border-2 border-[#000]'>
        //                     Read more...
        //                 </button>
        //             </div>
        //         </div>
        //     </div>


        // </div>
    )
}

export default ReadmoreHome