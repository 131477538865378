import React from "react";
import logo from "../assets/logo.png";
import fb from "../assets/icons/fb.png";
import ig from "../assets/icons/ig.png";
import x from "../assets/icons/x.png";
import ln from "../assets/icons/in.png";
import yt from "../assets/icons/yt.png";
import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";

const Footer = () => {
  return (
    <div className="bg-[#0058AE] text-white">
      <footer className="">
        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0">
              <a href="https://flowbite.com/" className="flex items-center">
                <img src={logo} alt="logo" className="w-[60] h-[50px]" />
                <span className="self-center pl-1 leading-[30px] text-xl text-wrap font-semibold whitespace-nowrap">
                  eSparse Matrix Solutions Pvt Ltd
                </span>
              </a>
              <p className="text-xs md:text-sm text-justify w-full sm:w-[500px] md:w-[300px] lg:w-[500px] text-[white] leading-[25.6px] pt-3 pr-0 sm:pr-0 md:pr-5 lg:pr-0">
                Esparse Matrix Solution Private Limited is a software
                development company based in India that serves clients all over
                the world. We concentrated on providing innovative resources to
                help clients expand.
              </p>
              <div className="flex gap-[4.3px] justify-start pt-5">
                <a
                  href="https://www.facebook.com/esparsematrixsolutions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fb} alt="fb" className="w-[32px] h-[32px]" />
                </a>
                <a
                  href="https://www.instagram.com/esparse_matrix_solutions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={ig} alt="ig" className="w-[32px] h-[32px]" />
                </a>
                <a
                  href="https://x.com/MatrixSparse"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={x} alt="x" className="w-[32px] h-[32px]" />
                </a>
                <a
                  href="https://www.linkedin.com/company/sparse-matrix-solutions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={ln} alt="ln" className="w-[32px] h-[32px]" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCmmkB3zOe1d7g6FvXqtGcQw"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={yt} alt="yt" className="w-[32px] h-[32px]" />
                </a>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2 md:grid-cols-2">
              <div className="pl-0 sm:pl-10 md:pl-0 lg:pl-20">
                <h2 className="mb-6 text-lg font-bold text-white uppercase">
                  ABOUT
                </h2>
                <ul className="text-white font-medium">
                  <Link to="/">
                    <li className="mb-2 font-normal text-sm"> Home </li>
                  </Link>
                  <Link to="/aboutus">
                    <li className="mb-2 font-normal text-sm"> About us</li>
                  </Link>
                  {/* <Link to='/services'>
                          <li  className="mb-2 font-normal text-sm">  Services</li>
                      </Link> */}
                  <Link to="/career">
                    <li className="mb-2 font-normal text-sm"> Career</li>
                  </Link>
                  <Link to="/blog">
                    <li className="mb-2 font-normal text-sm"> Blog</li>
                  </Link>
                  {/* <Link to='/events'>
                          <li  className="mb-2 font-normal text-sm">  Events</li>
                      </Link> */}
                  <Link to="/contactus">
                    <li className="mb-2 font-normal text-sm"> Contact us</li>
                  </Link>
                </ul>
              </div>
              <div className="pl-0 sm:pl-10 md:pl-0 lg:pl-12">
                <h2 className="mb-6 text-lg font-bold text-white uppercase">
                  POLICY
                </h2>
                <ul className="text-white font-medium">
                  <li className="mb-2 font-normal text-sm">
                    {" "}
                    Terms of Services
                  </li>

                  <li className="mb-2 font-normal text-sm"> Privacy policy</li>

                  <li className="mb-2 font-normal text-sm"> Disclaimer</li>

                  <li className="mb-2 font-normal text-sm"> Sitemap</li>
                </ul>
              </div>
            </div>
            <div className="mt-5 sm:mt-0 md:mt-0">
              <h2 className="mb-6 text-lg font-bold text-white uppercase">
                OFFICE ADDRESS
              </h2>
              <ul className="text-white font-medium">
                <li className="mb-2 font-normal text-sm">
                  <p className="flex items-center gap-1">
                    <span>
                      <FaLocationDot />
                    </span>
                    133/1, Spectrum Building,
                  </p>
                  <p>Gurudwara Colony, Akurdi, Pune-411033</p>
                </li>
                <li className="mb-2 font-normal text-sm">
                  <p className="flex items-center gap-1">
                    {" "}
                    <span>
                      <BsFillTelephoneFill />
                    </span>{" "}
                    (+91) 8459694413
                  </p>
                </li>
                <li className="font-normal text-sm">
                  <a
                    href="mailto:info@sparsematrix.co.in"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="flex items-center gap-1">
                      {" "}
                      <span>
                        <MdMail />
                      </span>
                      Click here to Email us
                    </p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto  lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-center">
            <p className="text-sm text-white capitalize xl:text-center">
              © 2019 All rights reserved{" "}
            </p>
          </div>
        </div>
      </footer>
    </div>
    // <div className="bg-[#0058AE] h-[354.4px]  p-[40px_88px_36px_88px] flex justify-between">

    //     <div className='flex flex-col gap-[16px] w-[380px]'>
    //         <div className='flex justify-center'>
    //             <img src={logo} alt='logo' className='w-[60] h-[50px]' />
    //         </div>
    //         <p className='text-[20px] font-black text-[white] leading-[30px] text-left'>
    //             eSparse Matrix Solutions Pvt Ltd
    //         </p>
    //         <p className=' text-[16px] text-[white] leading-[25.6px] text-left pr-5'>
    //             Esparse Matrix Solution Private Limited is a software development company based in India that serves clients all over the world. We concentrated on providing innovative resources to help clients expand.
    //         </p>
    //         <div className='flex gap-[4.3px] justify-start'>
    //             <a href="https://www.facebook.com/esparsematrixsolutions/" target="_blank" rel="noreferrer">

    //                 <img src={fb} alt='fb' className='w-[32px] h-[32px]' />
    //             </a>
    //             <a href="https://www.instagram.com/esparse_matrix_solutions/" target="_blank" rel="noreferrer">

    //                 <img src={ig} alt='ig' className='w-[32px] h-[32px]' />
    //             </a>
    //             <a href="https://x.com/MatrixSparse" target="_blank" rel="noreferrer">

    //                 <img src={x} alt='x' className='w-[32px] h-[32px]' />
    //             </a>
    //             <a href="https://www.linkedin.com/company/sparse-matrix-solutions/" target="_blank" rel="noreferrer">

    //                 <img src={ln} alt='ln' className='w-[32px] h-[32px]' />
    //             </a>
    //             <a href="https://www.youtube.com/channel/UCmmkB3zOe1d7g6FvXqtGcQw" target="_blank" rel="noreferrer">

    //                 <img src={yt} alt='yt' className='w-[32px] h-[32px]' />
    //             </a>
    //         </div>

    //     </div>

    //     <div className=' h-[314px] p-[15px] text-[white]' >
    //         <p className='text-[20px] leading-[30px] tracking-[1px] text-left '>
    //             ABOUT
    //         </p>

    //         <ul className='py-3 flex flex-col gap-3'>
    //             <Link to='/' >
    //                 <li> Home </li>
    //             </Link>
    //             <Link to='/aboutus'>
    //                 <li> About us</li>
    //             </Link>
    //             {/* <Link to='/services'>
    //                 <li>  Services</li>
    //             </Link> */}
    //             <Link to='/career'>
    //                 <li> Career</li>
    //             </Link>
    //             <Link to='/blog'>
    //                 <li>  Blog</li>
    //             </Link>
    //             {/* <Link to='/events'>
    //                 <li>  Events</li>
    //             </Link> */}
    //             <Link to='/contactus' >
    //                 <li>  Contact us</li>
    //             </Link>
    //         </ul>
    //     </div>

    //     <div className=' h-[314px] p-[15px] text-[white]' >
    //         <p className='text-[20px] leading-[30px] tracking-[1px] text-left '>
    //             POLICY
    //         </p>

    //         <ul className='py-3 flex flex-col gap-3'>

    //             <li> Terms of Services</li>

    //             <li> Privacy policy</li>

    //             <li> Disclaimer</li>

    //             <li> Sitemap</li>

    //         </ul>
    //     </div>

    //     <div className='h-[314px] p-[15px] text-[white]' >
    //         <p className='text-[20px] leading-[30px] tracking-[1px] text-left '>
    //             OFFICE ADDRESS
    //         </p>

    //         <ul className='py-3 flex flex-col gap-3'>

    //             <div className=''>
    //                 <p className='flex items-center gap-1'> <span><FaLocationDot /></span> 133/1, Spectrum Building,</p>
    //                 <p>Gurudwara Colony, Akurdi,
    //                     Pune-411033</p>
    //             </div>

    //             <div>
    //                 <p className='flex items-center gap-1'> <span><BsFillTelephoneFill /></span>   (+91) 8459694413</p>
    //             </div>

    //             <div>
    //                 <a href="mailto:info@sparsematrix.co.in" target="_blank" rel="noreferrer">
    //                     <p className='flex items-center gap-1'> <span><MdMail /></span>Click here to Email us</p>
    //                 </a>
    //             </div>

    //         </ul>
    //     </div>

    // </div>
  );
};

export default Footer;
