import React from 'react'
import { BsPerson, BsTelephone } from 'react-icons/bs'
import { MdOutlineMail } from 'react-icons/md'

const AboutSection7 = () => {
    return (
        <div className="flex justify-center px-4 sm:px-8 md:px-16">
    <form className="w-full max-w-[1110px] py-6 px-4 mb-[14px] rounded border border-gray-300 flex flex-col gap-4">
        <p className="text-[20px] sm:text-[24px] md:text-[28px] font-semibold text-center text-[#161C2D]">
            Get In Touch!
        </p>
        <div className="flex flex-wrap justify-center gap-3">
            {/* Name Input */}
            <div className="w-full sm:w-[48%] md:w-[30%] px-2">
                <label className="text-[12px] font-bold">
                    Your Name <span className="text-[#E43F52]">*</span>
                </label>
                <div className="relative w-full mt-2">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <BsPerson className="text-lg" />
                    </span>
                    <input
                        type="text"
                        name="your name"
                        placeholder="Your Name:"
                        className="w-full border border-gray-300 py-1.5 pl-8 pr-2 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                </div>
            </div>
            {/* Mobile Input */}
            <div className="w-full sm:w-[48%] md:w-[30%] px-2">
                <label className="text-[12px] font-bold">
                    Your Mobile <span className="text-[#E43F52]">*</span>
                </label>
                <div className="relative w-full mt-2">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <BsTelephone className="text-lg" />
                    </span>
                    <input
                        type="tel"
                        name="your mobile"
                        placeholder="Your Mobile:"
                        className="w-full border border-gray-300 py-1.5 pl-8 pr-2 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                </div>
            </div>
            {/* Email Input */}
            <div className="w-full sm:w-[48%] md:w-[30%] px-2">
                <label className="text-[12px] font-bold">
                    Your Email <span className="text-[#E43F52]">*</span>
                </label>
                <div className="relative w-full mt-2">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <MdOutlineMail className="text-lg" />
                    </span>
                    <input
                        type="email"
                        name="your email"
                        placeholder="Your Email:"
                        className="w-full border border-gray-300 py-1.5 pl-8 pr-2 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                </div>
            </div>
            {/* Send Button */}
            <div className="w-full sm:w-[48%] md:w-[30%] flex items-center justify-end px-1">
            <button
              type="submit"
              class="group relative inline-flex border border-[#0058AE] focus:outline-none w-full md:mt-5 mb-10 md:mb-0"
            >
              <span class="w-full inline-flex items-center justify-center self-stretch px-4 py-2 text-sm text-white text-center font-bold uppercase bg-[#0058AE] ring-1 ring-[#0058AE] ring-offset-1 ring-offset-[#0058AE] transform transition-transform group-hover:-translate-y-1 group-hover:-translate-x-1 group-focus:-translate-y-1 group-focus:-translate-x-1">
                Send
              </span>
            </button>
            </div>
        </div>
    </form>
</div>

        // <div className='flex justify-center'>
        //     <form className='mx-28 mb-28 w-[1110px] h-auto py-9 px-5 rounded border-solid border-[1px] flex flex-col gap-5'>
        //         <p className='text-[32px] font-semibold leading-[23.4px] text-center text-[#161C2D]'>
        //             Get In Touch !
        //         </p>
        //         <div className='flex justify-center gap-[12px]'>
        //             <div className=' h-[73px] px-[15px]'>
        //                 <label className='text-[14px] font-bold leading-[21px] text-left'>
        //                     Your Name <span className='text-[#E43F52]'>*</span>
        //                 </label>
        //                 <div className='relative w-full my-3'>
        //                     <span className="absolute inset-y-0 left-0 flex items-center pl-3">
        //                         <BsPerson className='text-xl' />
        //                     </span>
        //                     <input
        //                         type='text'
        //                         name='your name'
        //                         placeholder='Your Name:'
        //                         className="border-2 w-full border-2 border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none-lg focus:ring-2-lg focus:ring-blue-500"
        //                     />
        //                 </div>
        //             </div>


        //             <div className='h-[73px] px-[15px]'>
        //                 <label className='text-[14px] font-bold leading-[21px] text-left'>
        //                     Your Mobile <span className='text-[#E43F52]'>*</span>
        //                 </label>
        //                 <div className='relative w-full my-3'>
        //                     <span className="absolute inset-y-0 left-0 flex items-center pl-3">
        //                         <BsTelephone className='text-lg' />
        //                     </span>
        //                     <input
        //                         type='tel'
        //                         name='your mobile'
        //                         placeholder='Your Mobile:'
        //                         className="border-2 w-full border-2 border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none-lg focus:ring-2-lg focus:ring-blue-500"
        //                     />
        //                 </div>
        //             </div>


        //             <div className='h-[73px] px-[15px]'>
        //                 <label className='text-[14px] font-bold leading-[21px] text-left'>
        //                     Your Email <span className='text-[#E43F52]'>*</span>
        //                 </label>
        //                 <div className='relative w-full my-3'>
        //                     <span className="absolute inset-y-0 left-0 flex items-center pl-3">
        //                         <MdOutlineMail className='text-xl' />
        //                     </span>
        //                     <input
        //                         type='text'
        //                         name='your email'
        //                         placeholder='Your Email:'
        //                         className="border-2 w-full border-2 border-[#DEE2E6] py-2 pl-10 pr-4 rounded-lg focus:outline-none-lg focus:ring-2-lg focus:ring-blue-500"
        //                     />
        //                 </div>
        //             </div>
        //             <div className='flex items-end px-[15px]'>
        //                 <button className='bg-[#0058AE] w-[242.5px] h-[42px] p-[9px_65.27px_9px_65.25px] shadow-[0px_3px_5px_0px_#2F55D44D] gap-0 rounded text-white text-center '>
        //                     Send
        //                 </button>
        //             </div>


        //         </div>
        //     </form>
        // </div>
    )
}

export default AboutSection7