import React from 'react'
import bgImage from '../../assets/products/hero2.png'
import Slider from 'react-slick'
import card from '../../assets/products/card.png'


const items = new Array(8).fill(
    {
        image: card,
        title: 'Recorded Sessions',
        desc: 'Access to recorded classes for review and revision Flexible viewing options for students with different schedules',
        link: ''
    }
)


const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3.5,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
        {
            breakpoint: 1024, // Desktop view
            settings: {
                slidesToShow: 3, // Show 3 slides at a time
                centerPadding: "100px", // Keep the centerPadding for desktop
            },
        },
        {
            breakpoint: 768, // Tablet view
            settings: {
                slidesToShow: 2, // Show 2 slides at a time
                centerPadding: "60px", // Adjust padding for tablet
            },
        },
        {
            breakpoint: 480, // Mobile view
            settings: {
                slidesToShow: 1, // Show 1 slide at a time
                centerPadding: "0px", // No padding on mobile
                centerMode: false, // Disable centerMode for mobile
            },
        },
    ],
};

const Logistics = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

    <div className='h-screen w-full text-[#fff] flex justify-start items-center pt-24 relative bg-cover bg-center'
        style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)] z-0"></div>
        <div className='flex flex-col gap-[48px] p-10 sm:p-16 lg:p-28 z-10'>
            <div className='flex flex-col gap-[32px]'>
                <p className='font-nunito text-[32px] sm:text-[40px] md:text-[48px] leading-[44px] sm:leading-[56px] text-left'>
                    Esparse Logistics Software
                </p>
                <p className="text-[14px] sm:text-[16px] w-[100%] md:w-[470px] font-normal leading-[20px] sm:leading-[24px] text-left">
                    The Ultimate Learning Hub for Students
                </p>
            </div>
        </div>
    </div>

    <div className='flex flex-col gap-[32px] w-full'>
        <p className='text-center text-[24px] sm:text-[22px] font-semibold leading-[23.4px] '>Push Notifications</p>
        <p className='text-left text-[14px] sm:text-[16px] leading-[23.4px] px-6 sm:px-12 md:px-24'>
            Developed with an eye on the complete workflow for ease of operations, this logistics solution is safe, scalable, easy to use, and highly customizable for businesses of all sizes. A state-of-the-art system that can manage the complete life cycle, from request pickup to delivery and financing.
        </p>
    </div>

    <p className='text-center font-nunito text-[32px] sm:text-[40px] font-semibold leading-[23.4px]'>What we offer</p>

    <div className='h-[455px] flex flex-col items-center justify-between '>
        <Slider {...settings} className="overflow-hidden w-full px-16 sm:px-10 my-3 items-center justify-between  ">
            {items.map((item) => (
                <div key={item.id} className=''>
                    <div className='w-[252px] sm:w-[320px] lg:w-[292px] h-[447px] flex flex-col items-center bg-[white] gap-[8px] px-7 '>
                        <img src={item.image} alt='card' className='rounded-tr-lg rounded-tl-lg' />
                        <div className='h-[90%] flex flex-col items-center gap-[10px] px-6'>
                            <p className='text-[#3C4858] text-center font-bold text-[16px] sm:text-[18px] lg:text-[20px]'>
                                {item.title}
                            </p>
                            <p className='font-[#161C2D] text-center text-[14px] sm:text-[16px] leading-[24px]'>
                                {item.desc}
                            </p>
                        </div>
                        <button className='bg-[#006BD3] w-[241px] h-[24px] rounded text-[white] mb-5'>View More</button>
                    </div>
                </div>
            ))}
        </Slider>
    </div>

    <p className='text-center text-[32px] sm:text-[40px] font-semibold leading-[23.4px]'>Key Features of the Esparse Logistics Software Solutions</p>

    <div className='h-[485px] flex flex-col justify-center mb-10 items-center justify-between '>
        <Slider {...settings} className="overflow-hidden w-full px-16 sm:px-10 my-3">
            {items.map((item) => (
                <div key={item.id} className=''>
                    <div className='w-[292px] sm:w-[320px] lg:w-[292px] h-[447px] flex flex-col items-center bg-[white] px-7 gap-[8px] rounded-lg'>
                        <img src={item.image} alt='card' className='rounded-tr-lg rounded-tl-lg' />
                        <div className='h-[90%] flex flex-col items-center gap-[10px] px-6'>
                            <p className='text-[#3C4858] text-center font-bold text-[16px] sm:text-[18px] lg:text-[20px]'>
                                {item.title}
                            </p>
                            <p className='font-[#161C2D] text-center text-[14px] sm:text-[16px] leading-[24px]'>
                                {item.desc}
                            </p>
                        </div>
                        <button className='bg-[#006BD3] w-[241px] h-[24px] rounded text-[white] mb-5'>View More</button>
                    </div>
                </div>
            ))}
        </Slider>
    </div>

</div>

        // <div className='flex flex-col gap-[64px]'>

        //     <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
        //         style={{ backgroundImage: `url(${bgImage})` }}>
        //         <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
        //         <div className='flex flex-col gap-[48px] p-28 z-10'>
        //             <div className='flex flex-col  gap-[32px]'>
        //                 <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
        //                     Esparse Logistics Software                       </p>
        //                 <p className="text-[18px] w-[470px] font-normal leading-[25.6px] text-left">
        //                     The Ultimate Learning Hub for Students                            </p>
        //             </div>
        //         </div>
        //     </div>

        //     <div className='flex flex-col  gap-[32px] w-full'>
        //         <p className='text-center text-[32px] font-semibold leading-[23.4px]'>Push Notifications</p>
        //         <p className='text-left text-[16px] leading-[23.4px] px-64'>Developed with an eye on the complete work flow for ease of operations, this logistics solution is safe, scalable, easy to use, and highly customizable for businesses of all sizes. A state-of-the-art system that can manage the complete life cycle, from request pickup to delivery and financing.</p>

        //     </div>

        //     <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>What we offer </p>

        //     <div className=' h-[485px] flex flex-col justify-center'>
        //         {/* <div className='flex '> */}
        //         <Slider {...settings} className="overflow-hidden w-full px-10 my-3">

        //             {items.map((item) => (
        //                 <div className=''>


        //                     <div className='w-[292px] h-[447px] flex flex-col items-center bg-[white] gap-[8px] border-2 rounded-lg'>
        //                         <img src={item.image} alt='card' className='rounded-tr-lg rounded-tl-lg' />

        //                         <div className=' h-[90%] flex flex-col items-center gap-[10px] px-6'>
        //                             <p className='text-[#3C4858] text-center font-bold font-[20px]'>
        //                                 {item.title}
        //                             </p>
        //                             <p className='font-[#161C2D] text-center font-[16px] leading-[24px]'>
        //                                 {item.desc}
        //                             </p>
        //                         </div>
        //                         <button className='bg-[#006BD3] w-[241px] h-[24px] rounded text-[white] mb-5'>View More</button>
        //                     </div>
        //                 </div>

        //             ))}
        //         </Slider>
        //         {/* </div> */}
        //     </div>


        //     <p className='text-center text-[32px] font-semibold leading-[23.4px]'>Key Features of the Esparse  Logistics Software Solutions</p>

        //     <div className=' h-[485px] flex flex-col justify-center mb-10'>
        //         {/* <div className='flex '> */}
        //         <Slider {...settings} className="overflow-hidden w-full px-10 my-3">

        //             {items.map((item) => (
        //                 <div className=''>


        //                     <div className='w-[292px] h-[447px] flex flex-col items-center bg-[white] gap-[8px] border-2 rounded-lg'>
        //                         <img src={item.image} alt='card' className='rounded-tr-lg rounded-tl-lg' />

        //                         <div className=' h-[90%] flex flex-col items-center gap-[10px] px-6'>
        //                             <p className='text-[#3C4858] text-center font-bold font-[20px]'>
        //                                 {item.title}
        //                             </p>
        //                             <p className='font-[#161C2D] text-center font-[16px] leading-[24px]'>
        //                                 {item.desc}
        //                             </p>
        //                         </div>
        //                         <button className='bg-[#006BD3] w-[241px] h-[24px] rounded text-[white] mb-5'>View More</button>
        //                     </div>
        //                 </div>

        //             ))}
        //         </Slider>
        //         {/* </div> */}
        //     </div>

        
        // </div>
    )
}

export default Logistics