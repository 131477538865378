import React from "react";
import bgImage from "../../assets/servicesImages/ds/hero.png";
import pic1 from "../../assets/servicesImages/ds/1.png";
import pic2 from "../../assets/servicesImages/ds/2.png";
import pic3 from "../../assets/servicesImages/ds/3.png";
import pic4 from "../../assets/servicesImages/ds/4.png";
import benefits from "../../assets/servicesImages/ds/benefits.png";
import imagea from "../../assets/servicesImages/ai/a.png";
import Slider from "react-slick";
import arrow from "../../assets/viewdetails.png";

const items = [
  {
    image: imagea,
    desc: "Expertise: Our team comprises experienced AI professionals with deep knowledge across various industries.",
  },
  {
    image: imagea,
    title: "Sales & Purchase Management",
    desc: "Increase Productivity & Lower Costs By Managing Your Sales & Purchased. It Manages complete cycle from Purchases to Sales Order.  ",
  },
  {
    image: imagea,
    title: "Sales & Purchase Management",
    desc: "Increase Productivity & Lower Costs By Managing Your Sales & Purchased. It Manages complete cycle from Purchases to Sales Order.  ",
  },
];

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 4,
  speed: 500,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1024, // Desktop view
      settings: {
        slidesToShow: 4, // Show 3 slides at a time
        centerPadding: "100px", // Keep the centerPadding for desktop
      },
    },
    {
      breakpoint: 768, // Tablet view
      settings: {
        slidesToShow: 2, // Show 2 slides at a time
        centerPadding: "60px", // Adjust padding for tablet
      },
    },
    {
      breakpoint: 480, // Mobile view
      settings: {
        slidesToShow: 1, // Show 1 slide at a time
        centerPadding: "0px", // No padding on mobile
        centerMode: false, // Disable centerMode for mobile
      },
    },
  ],
};
const cards = [
  {
    image: pic1,
    tile: "Data Science Consulting Services & Solutions",
    desc: "Expertise: Our team comprises experienced AI professionals with deep knowledge across various industries.",
  },
  {
    image: pic2,
    tile: "Data Preparation",
    desc: "Our experts are experienced in enriching the data sets by replacing the missing values, analysing outliers, and defining categorical variables appropriately.",
  },
  {
    image: pic3,
    tile: "DModel Generation",
    desc: "The team of engineers at Fusion Informatics has worked on several projects that helped them to generate the models effortlessly, test them, and refine them based on the output.",
  },
  {
    image: pic4,
    tile: "Migration",
    desc: "We offer migration of algorithms from one platform to another to enable our clients the benefits of cross-platform applications.",
  },
];
const DS = () => {
  return (
    <div className="flex flex-col gap-[64px]">
      <div
        className="h-screen w-full text-white flex justify-start items-center pt-24 relative bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)] z-0"></div>
        <div className="flex flex-col gap-12 p-6 sm:p-12 md:p-20 lg:p-28 z-10">
          <div className="flex flex-col max-w-[90%] sm:max-w-[470px] gap-6">
            <p className="font-nunito text-3xl sm:text-4xl md:text-5xl leading-tight text-left">
              Data Science Consulting Services{" "}
            </p>
            <p className="text-lg sm:text-xl font-normal leading-relaxed text-left">
              AI software development transforms data into intelligence,
              automating tasks and driving innovation in various industries.{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:gap-[36px] md:px-36">
        <p className="text-center font-nunito text-2xl sm:text-3xl font-semibold leading-tight">
          Data Science Consulting Services
        </p>
        <p className="font-nunito text-[16px] px-3 mt-5 md:mt-0 md:px-0 font-medium leading-[25.6px] text-justify">
          At Esparse Matrix Solutions, we offer comprehensive data science
          consulting services designed to help businesses harness the power of
          data to drive growth, optimize operations, and gain a competitive
          edge. Our team of experienced data scientists and analysts work
          closely with you to develop tailored solutions that meet your unique
          business needs.
        </p>
      </div>

      <div className="flex flex-col md:gap-[36px] md:px-10 lg:px-36 xl:px-36">
        <p className="text-center font-nunito text-2xl sm:text-3xl font-semibold leading-tight">
          Our Data Science & Consulting Services
        </p>

        {/* <div className="flex flex-wrap justify-center gap-10">
          {cards.map((item) => (
            <div
              className=" h-[410px] rounded-lg shadow-[0px_4px_4px_0px_#00000040]
            w-full sm:w-[260px] md:w-[260px] lg:w-[260px]flex flex-col gap-4 items-center  border mx-3 md:mx-0"
            >
              <img src={item.image} alt="icon1" className="mb-3" />
              <div className="flex flex-col  gap-[12px] px-4">
                <p className="text-[16px] px-5 font-semibold text-center">
                  {item.tile}
                </p>
                <p className="text-[16px] font-normal text-left">{item.desc}</p>
              </div>
            </div>
          ))}
        </div> */}

        <div className="flex flex-wrap gap-6 justify-center mt-5">
          {cards.map((item) => (
            <div className="w-full sm:w-[260px] md:w-[260px] lg:w-[260px] h-[420px] pb-5 flex flex-col gap-4 items-center rounded-lg shadow-md border mx-3 md:mx-0">
              <img src={item.image} alt="icon1" className="mb-3" />
              <div className="flex flex-col  gap-[12px] px-4">
                <p className="text-[16px] px-5 font-bold text-center">
                  {item.tile}
                </p>
                <p className="text-[16px] font-normal text-left">{item.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col md:gap-[36px] md:px-10 lg:px-36 xl:px-36">
        <p className="text-center font-nunito text-2xl sm:text-3xl font-semibold leading-tight">
          Benefits of our Our Data Science & Consulting Services
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 h-full gap-20 ">
          <div className="">
            <img src={benefits} alt="benefits" className="px-5 md:px-0 w-full h-full" />
          </div>

          <div className="px-5 md:px-0">
            <p className="text-lg md:text-3xl font-medium leading-[45px]">
              Informed Decision Making
            </p>
            <li className="text-sm md:text-xl md:py-4 leading-[45px]">
              Leverage data-driven insights to make well-informed strategic
              decisions.
            </li>

            <p className="text-lg md:text-3xl font-medium leading-[45px]">
              Enhanced Efficiency
            </p>
            <li className="text-sm md:text-xl md:py-4 leading-[45px]">
              Streamline operations and improve productivity through data
              automation.
            </li>

            <p className="text-lg md:text-3xl font-medium leading-[45px]">
              Competitive Advantage
            </p>
            <li className="text-sm md:text-xl md:py-4 leading-[45px]">
              {" "}
              Gain a competitive edge by utilizing advanced analytics and data
              science techniques.
            </li>

            <p className="text-lg md:text-3xl font-medium leading-[45px]">
              Scalability
            </p>
            <li className="text-sm md:text-xl md:py-4 leading-[45px]">
              Scalable solutions that grow with your business needs.
            </li>
          </div>
        </div>
      </div>

      <p className="text-center ont-nunito text-xl md:text-4xl font-semibold leading-[23.4px]">
        Why Choose Esparse Matrix Solutions?
      </p>

      <Slider {...settings} className="overflow-hidden w-full px-10">
        {items.map((item) => (
          <div className="px-0 md:px-2">
            <div className="w-full h-[400px] min-h-full mb-10 flex flex-col bg-[white] gap-[8px] shadow-[0px_4px_4px_0px_#00000040] rounded-lg">
              <div className=" flex flex-col items-center gap-[10px] ">
                <div className="h-full w-auto overflow-hidden  rounded-tr-lg  rounded-tl-lg">
                  <img
                    src={item.image}
                    alt="icon1"
                    className="transition-transform duration-300 ease-in-out transform hover:scale-110"
                  />
                </div>

                <p className="font-[#161C2D] text-left font-semibold font-[16px] px-3">
                  {item.desc}
                </p>
              </div>
              <div className="flex text-[#0058AE] text-[12px] p-4 gap-1">
                <a href="/">View Details</a>
                <img src={arrow} alt="arrow" className="w-[9px]" />
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default DS;
