import React from 'react'
import bgImage from '../assets/career/hero2.png'
import { FiUserCheck, FiMonitor } from "react-icons/fi";
import { FaBuilding, FaClipboardList, FaGraduationCap, FaLaptopCode, FaRegListAlt } from 'react-icons/fa'

const SingleCareer = () => {
    return (
        <div className='flex flex-col gap-16 md:gap-[64px]  md:px-8'>

            {/* Hero Section */}
            <div
                className='h-[50vh] md:h-screen w-full text-white flex justify-start items-center pt-16 md:pt-24 relative bg-cover bg-center'
                style={{ backgroundImage: `url(${bgImage})` }}
            >
                <div className="absolute inset-0 bg-gradient-to-r from-[rgba(47,58,181,0.2)] via-transparent to-[rgba(197,207,239,0.18)] z-0"></div>
                <div className='flex flex-col gap-6 md:gap-[48px] p-6 md:p-28 z-10 max-w-[90%] md:max-w-none'>
                    <div className='flex flex-col gap-4 md:gap-[32px]'>
                        <p className='font-nunito text-3xl md:text-[48px] leading-tight md:leading-[67.2px] text-justify'>
                            Android Developer
                        </p>
                        <p className="text-base md:text-[18px] font-normal leading-6 md:leading-[25.6px] text-justify">
                            We Esparse Matrix Solutions Team are working on Various Projects like Websites Design & Development, Mobile applications Developments, software Developments, ERP Development, and digital marketing services.
                        </p>
                    </div>
                </div>
            </div>


            {/* Main Content */}
            <div className='flex flex-col lg:flex-row mb-20 gap-6 lg:gap-16 items-start justify-center px-8'>

                {/* Job Information Card */}
                <div className='w-full sm:w-[386.75px] h-fit pb-10 rounded-lg shadow-md'>

                    <div className='h-[87.3px] px-5 py-2 border-b-2 flex items-center'>
                        <p className='text-lg md:text-[22.1px] font-semibold'>Job Information</p>
                    </div>
                    <div className='flex items-center mt-5 flex-col gap-5'>

                        <div className='flex items-center gap-4 w-full px-4'>
                            <FiUserCheck className='w-[22px] h-[22px]' />
                            <div>
                                <p className='text-sm md:text-[18px] font-semibold'>Employee Type:</p>
                                <p className='text-xs md:text-[16px] text-[#2F55D4]'>Internship</p>
                            </div>
                        </div>

                        <div className='flex items-center gap-4 w-full px-4'>
                            <FiMonitor className='w-[22px] h-[22px]' />
                            <div>
                                <p className='text-sm md:text-[18px] font-semibold'>Job Type:</p>
                                <p className='text-xs md:text-[16px] text-[#2F55D4]'>Android Developer</p>
                            </div>
                        </div>

                        <div className='flex items-center gap-4 w-full px-4'>
                            <FaBuilding className='w-[22px] h-[22px]' />
                            <div>
                                <p className='text-sm md:text-[18px] font-semibold'>Company Name:</p>
                                <p className='text-xs md:text-[16px] text-[#2F55D4]'>eSparse Matrix Solutions Pvt Ltd</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Job Details */}
                <div className='w-full lg:w-[780.13px] flex flex-col gap-5'>

                    {/* Job Description */}
                    <div className='flex items-center gap-4'>
                        <FaClipboardList className='w-[22px] h-[22px]' />
                        <p className='text-lg md:text-[18px] font-semibold'> Job Description:</p>
                    </div>
                    <p className='text-sm md:text-base'>
                        We Esparse Matrix Solutions Team are working on Various Projects like Websites Design & Development, Mobile applications Developments, software Developments, ERP Development, and digital marketing services.
                    </p>

                    {/* Responsibilities */}
                    <div className='flex items-center gap-4'>
                        <FaRegListAlt className='w-[22px] h-[22px]' />
                        <p className='text-lg md:text-[18px] font-semibold'> Responsibilities and Duties:</p>
                    </div>
                    <ul className='list-disc pl-5'>
                        <li className='text-sm md:text-base text-justify'>Support in the end-to-end delivery of app features by using the latest architecture, design patterns, and writing secure and performant code.</li>
                        <li className='text-sm md:text-base'>Developing, releasing, and maintaining Android Applications and overseeing mobile applications.</li>
                    </ul>

                    {/* Qualifications */}
                    <div className='flex items-center gap-4'>
                        <FaGraduationCap className='w-[22px] h-[22px]' />
                        <p className='text-lg md:text-[18px] font-semibold'> Required Qualifications:</p>
                    </div>
                    <p className='text-sm md:text-base'>Bachelor's degree in computer science or related field or equivalent education and experience.</p>

                    {/* Skills */}
                    <div className='flex items-center gap-4'>
                        <FaLaptopCode className='w-[22px] h-[22px]' />
                        <p className='text-lg md:text-[18px] font-semibold'> Skills:</p>
                    </div>
                    <ul className='list-disc pl-5'>
                        <li className='text-sm md:text-base'>Kotlin, and Java</li>
                    </ul>

                    {/* Apply Button */}
                    <button className='self-start border-2 border-[#2F55D4] text-[#2F55D4] h-[47.52px] w-[165.75px] rounded-lg'>
                        Apply Now
                    </button>

                </div>
            </div>

        </div>
    )
}

export default SingleCareer
