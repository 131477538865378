import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../assets/logo.png'
import searchIcon from '../assets/searchicon.png'
import ServicesNavbar from './ServicesNavbar'
import ProductsNavbar from './ProductsNavbar'

const Navbar = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
    const [productsDropdownOpen, setProductsDropdownOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false); // State to control the menu

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        if (!menuOpen) {
          document.body.style.overflow = 'hidden'; // Disable scrolling when menu is open
        } else {
          document.body.style.overflow = ''; // Enable scrolling when menu is closed
        }
      };
    const toggleServicesMenu = (event) => {
        event.preventDefault();
        setServicesDropdownOpen(!servicesDropdownOpen);
        setProductsDropdownOpen(false);

    };

    const toggleProductsMenu = (event) => {
        event.preventDefault();
        setProductsDropdownOpen(!productsDropdownOpen);
        setServicesDropdownOpen(false);
 
    };

    
      const closeMenu = () => {
        setMenuOpen(false); // Close the mobile menu
        setServicesDropdownOpen(false); // Close the Services dropdown
        setProductsDropdownOpen(false); // Close the Products dropdown
        document.body.style.overflow = ""; // Enable scrolling
      };
  

    return (
        <div className="w-full fixed bg-[#FFFFFF] flex justify-between items-center z-50 shadow-[0px_4px_4px_0px_#00000040] px-4 lg:px-8 py-4">
  {/* Logo */}
  <div className="w-1/6 flex justify-start">
    <img alt="logo" src={logo} className="w-[55.89px] h-[52.81px]" />
  </div>

  {/* Menu - For larger screens */}
  <ul className="hidden lg:flex w-2/4 justify-center items-center gap-8">
    <Link
      onClick={closeMenu}
      to="/"
      className={`pt-4 pb-1 ${
        currentPath === '/' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'
      } hover:text-[#2F55D4] transition duration-200`}
    >
      Home
    </Link>
    <Link
      onClick={closeMenu}
      to="/aboutus"
      className={`pt-4 pb-1 ${
        currentPath === '/aboutus' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'
      } hover:text-[#2F55D4] transition duration-200`}
    >
      About&nbsp;us
    </Link>

    <li
      className={`h-full pt-4 pb-1 cursor-pointer ${
        currentPath.includes('/services') ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'
      } hover:text-[#2F55D4] transition duration-5000`}
      onClick={toggleServicesMenu}
    >
      Services
      {servicesDropdownOpen && <ServicesNavbar />}
    </li>

    <li
      className={`pt-4 pb-1 h-full cursor-pointer ${
        currentPath.includes('/products') ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'
      } hover:text-[#2F55D4] transition duration-200`}
      onClick={toggleProductsMenu}
    >
      Products
      {productsDropdownOpen && <ProductsNavbar />}
    </li>

    <Link
      onClick={closeMenu}
      to="/career"
      className={`pt-4 pb-1 ${
        currentPath === '/career' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'
      } hover:text-[#2F55D4] transition duration-200`}
    >
      Career
    </Link>
    <Link
      onClick={closeMenu}
      to="/blog"
      className={`pt-4 pb-1 ${
        currentPath === '/blog' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'
      } hover:text-[#2F55D4] transition duration-200`}
    >
      Blog
    </Link>
    <Link
      onClick={closeMenu}
      to="/events"
      className={`pt-4 pb-1 ${
        currentPath === '/events' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'
      } hover:text-[#2F55D4] transition duration-200`}
    >
      Events
    </Link>
    <Link
      onClick={closeMenu}
      to="/contactus"
      className={`pt-4 pb-1 ${
        currentPath === '/contactus' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'
      } hover:text-[#2F55D4] transition duration-200`}
    >
      Contact&nbsp;us
    </Link>
  </ul>

  {/* Search and Login - For larger screens */}
  <div className="hidden lg:flex w-1/6 justify-end items-center gap-3">
    <img alt="search icon" src={searchIcon} className="w-[24px] h-[24px]" />
    <button className="bg-[#006BD3] text-[#FFFFFF] px-8 py-2 rounded">Login</button>
  </div>

  {/* Hamburger Menu for Mobile */}
  <div className="lg:hidden flex items-center gap-4">
    <button onClick={toggleMenu} className="text-[#000]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
      </svg>
    </button>
  </div>

  {/* Mobile Dropdown Menu */}
  {menuOpen && (
    <div className="lg:hidden absolute top-16 left-0 w-full bg-white shadow-lg ">
      <ul className="flex flex-col items-center gap-4 p-4">
        <Link
          onClick={closeMenu}
          to="/"
          className={`pt-4 pb-1 ${currentPath === '/' ? 'text-[#2F55D4]' : 'text-[#000]'}`}
        >
          Home
        </Link>
        <Link
          onClick={closeMenu}
          to="/aboutus"
          className={`pt-4 pb-1 ${currentPath === '/aboutus' ? 'text-[#2F55D4]' : 'text-[#000]'}`}
        >
          About us
        </Link>
        <li
      className={`h-full pt-4 pb-1 cursor-pointer ${
        currentPath.includes('/services') ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'
      } hover:text-[#2F55D4] transition duration-5000`}
      onClick={toggleServicesMenu}
    >
      Services
      {servicesDropdownOpen && <ServicesNavbar />}
    </li>

    <li
      className={`pt-4 pb-1 h-full cursor-pointer ${
        currentPath.includes('/products') ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'
      } hover:text-[#2F55D4] transition duration-200`}
      onClick={toggleProductsMenu}
    >
      Products
      {productsDropdownOpen && <ProductsNavbar />}
    </li>
        <Link
          onClick={closeMenu}
          to="/career"
          className={`pt-4 pb-1 ${currentPath === '/career' ? 'text-[#2F55D4]' : 'text-[#000]'}`}
        >
          Career
        </Link>
        <Link
          onClick={closeMenu}
          to="/blog"
          className={`pt-4 pb-1 ${currentPath === '/blog' ? 'text-[#2F55D4]' : 'text-[#000]'}`}
        >
          Blog
        </Link>
        <Link
          onClick={closeMenu}
          to="/events"
          className={`pt-4 pb-1 ${currentPath === '/events' ? 'text-[#2F55D4]' : 'text-[#000]'}`}
        >
          Events
        </Link>
        <Link
          onClick={closeMenu}
          to="/contactus"
          className={`pt-4 pb-1 ${currentPath === '/contactus' ? 'text-[#2F55D4]' : 'text-[#000]'}`}
        >
          Contact us
        </Link>
        <div className="flex items-center gap-4 pt-4">
          <img alt="search icon" src={searchIcon} className="w-[24px] h-[24px]" />
          <button className="bg-[#006BD3] text-[#FFFFFF] px-8 py-2 rounded">Login</button>
        </div>
      </ul>
    </div>
  )}
</div>

        // <div className="w-full fixed bg-[#FFFFFF] flex justify-between z-50 shadow-[0px_4px_4px_0px_#00000040] ">
        //     <div className='w-1/6 px-8 py-4 flex justify-end'>
        //         <img alt='logo' src={logo} className='w-[55.89px] h-[52.81px]' />
        //     </div>

        //     <ul className='flex w-2/4 p-4 justify-center items-center gap-8'>
        //         <Link onClick={closeMenu} to='/' className={`pt-4 pb-1 ${currentPath === '/' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-200`}>
        //             Home
        //         </Link>
        //         <Link onClick={closeMenu} to='/aboutus' className={`pt-4 pb-1 ${currentPath === '/aboutus' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-200`}>
        //             About us
        //         </Link>

        //         <li
        //             className={`h-full pt-4 pb-1 cursor-pointer ${currentPath.includes('/services') ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-5000`}
        //             onClick={toggleServicesMenu}
        //         >
        //             Services
        //             {servicesDropdownOpen && (
        //                 <ServicesNavbar />
        //             )}

        //         </li>


        //         <li
        //             className={`pt-4 pb-1 h-full  cursor-pointer ${currentPath.includes('/products') ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-200`}
        //             onClick={toggleProductsMenu}
        //         >
        //             Products
        //             {productsDropdownOpen && (
        //                 <ProductsNavbar />
        //             )}

        //         </li>

        //         <Link onClick={closeMenu} to='/career' className={`pt-4 pb-1 ${currentPath === '/career' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-200`}>
        //             Career
        //         </Link>
        //         <Link onClick={closeMenu} to='/blog' className={`pt-4 pb-1  ${currentPath === '/blog' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-200`}>
        //             Blog
        //         </Link>
        //         <Link onClick={closeMenu} to='/events' className={`pt-4 pb-1 ${currentPath === '/events' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-200`}>
        //             Events
        //         </Link>
        //         <Link onClick={closeMenu} to='/contactus' className={`pt-4 pb-1  ${currentPath === '/contactus' ? 'text-[#2F55D4] border-b-2 border-[#2F55D4]' : 'text-[#000] border-b-2 border-transparent'} hover:text-[#2F55D4] transition duration-200`}>
        //             Contact us
        //         </Link>
        //     </ul >

        //     <div className='w-1/6 flex items-center gap-3 pr-75'>
        //         <img alt='search icon' src={searchIcon} className='w-[24px] h-[24px]' />
        //         <button className='bg-[#006BD3] text-[#FFFFFF] px-8 py-2 rounded'>Login</button>
        //     </div>
        // </div >
    )
}

export default Navbar
