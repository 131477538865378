import React from "react";
import bgImage from "../../assets/servicesImages/allindustrymanufacturingerps/hero.png";
import icon1 from "../../assets/servicesImages/allindustrymanufacturingerps/icon1.png";
import icon2 from "../../assets/servicesImages/allindustrymanufacturingerps/icon2.png";
import icon3 from "../../assets/servicesImages/allindustrymanufacturingerps/icon3.png";
import icon4 from "../../assets/servicesImages/allindustrymanufacturingerps/icon4.png";
import icona from "../../assets/servicesImages/allindustrymanufacturingerps/icona.png";
import iconb from "../../assets/servicesImages/allindustrymanufacturingerps/iconb.png";
import iconc from "../../assets/servicesImages/allindustrymanufacturingerps/iconc.png";
import icond from "../../assets/servicesImages/allindustrymanufacturingerps/icond.png";
import icone from "../../assets/servicesImages/allindustrymanufacturingerps/icone.png";
import iconf from "../../assets/servicesImages/allindustrymanufacturingerps/iconf.png";
import icong from "../../assets/servicesImages/allindustrymanufacturingerps/icong.png";
import Slider from "react-slick";

const items = [
  {
    icon: icona,
    title: "Sales & Purchase Management",
    desc: "Increase Productivity & Lower Costs By Managing Your Sales & Purchased. It Manages complete cycle from Purchases to Sales Order.  ",
  },
  {
    icon: iconb,
    title: "Inventory Management",
    desc: "Systematically Manages the sourcing, storing, and selling inventory—both raw materials (components) and finished goods (products). So stock available in the right place, at the right time with right cost price.    ",
  },
  {
    icon: iconc,
    title: "Project Management",
    desc: "Help in Delivered Projects on Time with great Profits in Projects . It manages tasks status, Timesheet and project work on time.   ",
  },
  {
    icon: icond,
    title: "Financial Management",
    desc: "Manages all Financial transaction so real time view of Cash flow .It covers all features required for Accounting  ",
  },
  {
    icon: icone,
    title: "Document Management",
    desc: "Help in Maintain All Document required for Organization with some standard Template. So reduces tedious work of document management will be available on one click. ",
  },
  {
    icon: iconf,
    title: "Workorder Management",
    desc: "systematic approach of processing and completing maintenance work orders in a timely manner in order to minimize asset downtime  ",
  },
  {
    icon: icong,
    title: "Manufacturing",
    desc: "Our software development team is exceptionally skilled in planning the deployment and maintenance of large, complex, and high-visibility software development systems and mobile apps.",
  },
];

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 3.5,
  speed: 500,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1024, // Desktop view
      settings: {
        slidesToShow: 4, // Show 3 slides at a time
        centerPadding: "100px", // Keep the centerPadding for desktop
      },
    },
    {
      breakpoint: 768, // Tablet view
      settings: {
        slidesToShow: 2, // Show 2 slides at a time
        centerPadding: "60px", // Adjust padding for tablet
      },
    },
    {
      breakpoint: 480, // Mobile view
      settings: {
        slidesToShow: 1, // Show 1 slide at a time
        centerPadding: "0px", // No padding on mobile
        centerMode: false, // Disable centerMode for mobile
      },
    },
  ],
};

const cards = [
  {
    icon: icon1,
    title: "Availability",
    desc: "P Automate business processes, so businesses can Increase production efficiency, save costs & maximize profits.",
  },
  {
    icon: icon2,
    title: "Promises",
    desc: "Act on insights, improve performance and share results with performance and share results with reporting.",
  },
  {
    icon: icon3,
    title: "Creativity",
    desc: "All The data are highly secured on cloud",
  },
  {
    icon: icon4,
    title: "Determination",
    desc: "improves the decision-making ability of an organization. provides thorough insights and visibility into every department and business process..... ",
  },
];

const AllIndustryERP = () => {
  return (
    <div className="flex flex-col gap-[64px]">
      <div
        className="h-screen w-full text-white flex justify-start items-center pt-24 relative bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)] z-0"></div>
        <div className="flex flex-col gap-12 p-6 sm:p-12 md:p-20 lg:p-28 z-10">
          <div className="flex flex-col max-w-[90%] sm:max-w-[470px] gap-6">
            <p className="font-nunito text-3xl sm:text-4xl md:text-5xl leading-tight text-left">
              For all Industry Manufacturing ERP
            </p>
            <p className="text-lg sm:text-xl font-normal leading-relaxed text-left">
              The only platform you will ever need to help run your business.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:gap-[36px] md:px-36">
        <p className="text-center font-nunito text-2xl sm:text-3xl font-semibold leading-tight">
          What is Sparse Manufacturing ERP?
        </p>
        <p className="font-nunito text-[16px] px-3 mt-5 md:mt-0 md:px-0 font-medium leading-[25.6px] text-justify">
          Sparse Manufacturing ERP is a system that manages a manufacturing
          company’s processes, such as Sales & Purchase, Production Process,
          Inventory, HR Activities, Accounting and more. By Using Sparse
          Manufacturing ERP, All these processes integrate into one single
          system so to provide real-time Data/ information with multiple
          departments so businesses can Increase production efficiency, save
          costs & maximize profits with Sparse Manufacturing ERP software
        </p>
      </div>

      <div className="h-full flex flex-col gap-[28px]">
        <div className="flex flex-col md:gap-[36px] md:px-36">
          <p className="text-center font-nunito text-2xl sm:text-3xl font-semibold leading-tight">
            What are the Benefits of Sparse Manufacturing ERP?
          </p>
          <p className="font-nunito px-3 mt-5 md:mt-0 md:px-0 text-[16px] font-medium leading-[25.6px] text-center">
            Sparse Manufacturing ERP is a system that manages a manufacturing
            company’s processes.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-4 sm:px-8 md:px-10">
          {cards.map((c, index) => (
            <div
              key={index}
              className="shadow-md border rounded-lg p-6 flex flex-col items-center text-center"
            >
              <img src={c.icon} alt={c.title} className="w-16 h-16 mb-4" />
              <p className="text-lg font-bold">{c.title}</p>
              <p className="text-sm">{c.desc}</p>
            </div>
          ))}
        </div>

        {/* <div className="flex justify-center px-24 items-center gap-[43px]">
          {cards.map((c, index) => (
            <div key={index} className="">
              <div className="w-[267px] h-[296px] px-[24px_18px_24px_18px] shadow-[1px_1px_8px_2px_#515A9340] flex flex-col items-center py-10  gap-[10px]">
                <div className="w-[237px] flex justify-center">
                  <img
                    src={c.icon}
                    alt="icon1"
                    className="w-[60.33px] h-[59.49px]"
                  />
                </div>
                <p className="text-[#3C4858] text-center font-[900] font-[20px]">
                  {c.title}
                </p>
                <p className="font-[#161C2D] text-center font-[16px] leading-[24px] px-5">
                  {c.desc}
                </p>
              </div>
            </div>
          ))}
        </div> */}
      </div>

      <p className="text-[32px] font-semibold leading-[23.4px] text-center">
        Our Modules
      </p>

      <div className="bg-[#EAEEFB] h-[540px] flex flex-col justify-center mb-20">
        <div className="flex flex-col gap-[32px]">
          <Slider {...settings} className="overflow-hidden w-full px-10 my-3">
            {items.map((item) => (
              <div className="px-0 md:px-2">
                <div className="w-full h-[404px] md:py-[24px] md:px-[18px] flex flex-col items-center bg-[white] md:gap-[8px] shadow-[1px_1px_8px_2px_#515A9340]">
                  <div className="w-[237px] h-[90%] flex flex-col items-center gap-[10px] ">
                    <img
                      src={item.icon}
                      alt="icon1"
                      className="w-[60.33px] h-[59.49px] mt-2 md:mt-0"
                    />

                    <p className="text-[#3C4858] text-center font-bold px-2">
                      {item.title}
                    </p>
                    <p className="font-[#161C2D] text-center font-[16px] leading-[24px] px-3">
                      {item.desc}
                    </p>
                  </div>
                  <button className="bg-[#006BD3] w-[112px] mb-3 p-[8px_12px] rounded text-[#FFF]">
                    Read More
                  </button>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default AllIndustryERP;
