import React from 'react'
import bgImage from '../assets/eventsImg/hero.png'
import Slider from 'react-slick';
import a from '../assets/eventsImg/1.png'
import b from '../assets/eventsImg/2.png'
import { MdKeyboardArrowRight } from 'react-icons/md';

const event = [
    {
        image: a,
        title: 'How To Crack Your Job Interview??',
        desc: "It's time to recruit on campus! It's almost time for students to begin their new chapter in life. The only thing standing between them and their ideal job in a fast-paced market is an interview. Mr.Vijay Rout, Personality Developer Trainer, presented his expertise and key useful lessons to ...",
        link: '/read'

    },
    {
        image: b,
        title: 'Live Webinar on “How Podcasts can help Business in 2021”',
        desc: "Join us on July 17, 2021 to learn more about How Podcasts can help Business IN 2021! Esparse Matrix Solutions Private Limited is going to organise a webinar on How Podcasts can help Business in 2021.The webinars are open for Startups, Entrepreneurs, Business Owners, Business People, ...",
        link: '/read'

    },
    {
        image: b,
        title: 'How To Crack Your Job Interview??',
        desc: "It's time to recruit on campus! It's almost time for students to begin their new chapter in life. The only thing standing between them and their ideal job in a fast-paced market is an interview. Mr.Vijay Rout, Personality Developer Trainer, presented his expertise and key useful lessons to ...",
        link: '/read'

    }
]
const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 2.3,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
        {
            breakpoint: 1024, // Desktop view
            settings: {
                slidesToShow: 2, // Show 3 slides at a time
                centerPadding: "100px", // Keep the centerPadding for desktop
            },
        },
        {
            breakpoint: 768, // Tablet view
            settings: {
                slidesToShow: 1, // Show 2 slides at a time
                centerPadding: "60px", // Adjust padding for tablet
            },
        },
        {
            breakpoint: 480, // Mobile view
            settings: {
                slidesToShow: 1, // Show 1 slide at a time
                centerPadding: "0px", // No padding on mobile
                centerMode: false, // Disable centerMode for mobile
            },
        },
    ],
};
const Events = () => {
    return (
        <div className="flex flex-col gap-16">
  {/* Background Section */}
  <div
    className="h-[50vh] sm:h-[60vh] md:h-[75vh] lg:h-screen w-full text-white flex flex-col justify-center items-start px-4 sm:px-8 md:px-12 lg:px-20 pt-24 relative bg-cover bg-center"
    style={{ backgroundImage: `url(${bgImage})` }}
  >
    <div className="absolute inset-0 bg-gradient-to-r from-[#171f81]/[.33] to-[#c5cfeff0]/[.18] z-0"></div>
    <div className="z-10">
      <p className="font-nunito text-3xl sm:text-4xl md:text-5xl lg:text-6xl leading-tight">
        Events
      </p>
    </div>
  </div>

  {/* Section Title */}
  <div className="text-xl sm:text-2xl lg:text-3xl font-semibold text-center mb-10 px-4 sm:px-8 md:px-12">
    eSparse Matrix 360 Digital Marketing
  </div>

  {/* Slider Section */}
  <Slider {...settings} className="overflow-hidden w-full px-4 sm:px-5 lg:px-20">
    {event.map((c, index) => (
      <div key={index} className="flex justify-center mb-10">
        {/* Box with Fixed Dimensions */}
        <div className="w-72 sm:w-80 lg:w-96 h-auto flex flex-col gap-3 rounded-lg shadow-md bg-white overflow-hidden">
          {/* Image */}
          <img
            src={c.image}
            alt="icon"
            className="h-40 w-full rounded-t-lg object-cover"
          />
          {/* Content */}
          <div className="w-full flex flex-col gap-2 px-4 py-4 overflow-hidden">
            <p className="text-lg sm:text-xl font-semibold leading-snug line-clamp-2">
              {c.title}
            </p>
            <p className="text-sm sm:text-base text-gray-500 line-clamp-3">
              {c.desc}
            </p>
            <div className="flex items-center gap-1 text-gray-500 mt-auto">
              <a href={c.link} className="text-base hover:underline">
                Read More
              </a>
              <MdKeyboardArrowRight className="text-lg" />
            </div>
          </div>
        </div>
      </div>
    ))}
  </Slider>
</div>

        // <div className="flex flex-col gap-16">
        //     {/* Background Section */}
           
        //     <div
        //         className="h-[50vh] sm:h-[60vh] md:h-screen w-full text-white flex flex-col justify-center items-start px-4 sm:px-8 md:px-12 pt-24 relative bg-cover bg-center"
        //         style={{ backgroundImage: `url(${bgImage})` }}
        //     >
        //         <div className="absolute inset-0 bg-gradient-to-r from-[#171f81]/[.33] to-[#c5cfeff0]/[.18] z-0"></div>
        //         <div className="z-10">
        //             <p className="font-nunito text-3xl sm:text-4xl md:text-5xl leading-tight">
        //             Events
        //             </p>
        //         </div>
        //     </div>
        //     {/* Section Title */}
        //     <div className="text-xl sm:text-2xl lg:text-3xl font-semibold text-center mb-10">
        //         eSparse Matrix 360 Digital Marketing
        //     </div>

        //     <Slider {...settings} className="overflow-hidden w-full px-4 sm:px-8 lg:px-20">
        //         {event.map((c, index) => (
        //             <div key={index} className="flex justify-center mb-10">
        //                 {/* Box with Fixed Dimensions */}
        //                 <div className="w-80 h-96 flex flex-col gap-3 rounded-lg shadow-md bg-white overflow-hidden">
        //                     {/* Image */}
        //                     <img
        //                         src={c.image}
        //                         alt="icon"
        //                         className="h-40 w-full rounded-t-lg object-cover"
        //                     />
        //                     {/* Content */}
        //                     <div className="w-full flex flex-col gap-2 px-4 py-2 overflow-hidden">
        //                         <p className="text-lg font-semibold leading-snug line-clamp-2">{c.title}</p>
        //                         <p className="text-sm text-gray-500 line-clamp-3">{c.desc}</p>
        //                         <div className="flex items-center gap-1 text-gray-500 mt-auto">
        //                             <a href={c.link} className="text-base hover:underline">
        //                                 Read More
        //                             </a>
        //                             <MdKeyboardArrowRight className="text-lg" />
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         ))}
        //     </Slider>
        // </div>

        // <div className='flex flex-col gap-[64px]'>

        //     <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
        //         style={{ backgroundImage: `url(${bgImage})` }}>
        //         <div className="absolute inset-0 bg-gradient-to-r from-[#171f81]/[.33] to-[#c5cfeff0]/[.18] z-0"></div>
        //         <div className='flex flex-col gap-[32px] p-28 z-10'>
        //             <div className='flex flex-col w-[810px] '>
        //                 <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
        //                     Events
        //                 </p>
        //             </div>
        //         </div>
        //     </div>

        //     <p className='text-[32px] font-semibold leading-[23.4px] text-center'>
        //         eSparse Matrix 360 Digital Marketing
        //     </p>
        //     <Slider {...settings} className="overflow-hidden w-full px-5">
        //         {event.map((c, index) => (
        //             <div key={index} className='flex justify-center  mb-20'>
        //                 <div className='w-[540px] h-[500px] gap-[13px] rounded-lg flex flex-col shadow-[1px_1px_8px_2px_#515A9340]'>
        //                     <img src={c.image} alt='icon' className=' h-[250px] rounded-tr-lg rounded-tl-lg w-full  object-cover' />
        //                     <div className=' w-full flex flex-col  gap-[8px] px-5 '>
        //                         <p className='text-[20px] leading-[36px] text-left'>
        //                             {c.title}
        //                         </p>
        //                         <p className='text-[14px] font-normal leading-[20px]  text-[#8492A6]'>
        //                             {c.desc}
        //                         </p>
        //                         <div className='text-[#8492A6] text-[16px] flex items-center gap-1'>
        //                             <a href={c.link}>Read More</a>
        //                             <MdKeyboardArrowRight className='text-[24px]' />

        //                         </div>
        //                     </div>

        //                 </div>
        //             </div>
        //         ))}
        //     </Slider>
        // </div>
    )
}

export default Events