import React from 'react'
import icon1 from '../assets/homeServicesSection/icon1.png'
import icon2 from '../assets/homeServicesSection/icon2.png'
import icon3 from '../assets/homeServicesSection/icon3.png'
import icon4 from '../assets/homeServicesSection/icon4.png'
import icon5 from '../assets/homeServicesSection/icon5.png'
import icon6 from '../assets/homeServicesSection/icon6.png'
import icon7 from '../assets/homeServicesSection/icon7.png'
import image1 from '../assets/homeServicesSection/1.png'
import image2 from '../assets/homeServicesSection/2.png'
import image3 from '../assets/homeServicesSection/3.png'
import image4 from '../assets/homeServicesSection/4.png'
import image5 from '../assets/homeServicesSection/5.png'
import image6 from '../assets/homeServicesSection/6.png'
import image7 from '../assets/homeServicesSection/7.png'
import image8 from '../assets/homeServicesSection/8.png'
import image9 from '../assets/homeServicesSection/9.png'
import image10 from '../assets/homeServicesSection/10.png'
import Slider from "react-slick";

const services = [
    {
        id: 1,
        title: 'Software Development',
        description: 'Software development is bringing ideas to life in the digital world. It\'s like building with code, creating programs and apps that run on computers and devices.',
        image: image1,
        link: '',
        icon: icon1
    },
    {
        id: 2,
        title: 'Web Development',
        description: 'Web development is the process of bringing websites and web applications to life. It involves everything from the initial design,  planning, coding & Programming.',
        image: image2,
        link: '',
        icon: icon2
    },
    {
        id: 3,
        title: 'AI/ ML Devlopment',
        description: 'AI/ML (Artificial Intelligence/ Machine Learning) uses algorithms to enable machines to learn from data and perform tasks that traditionally require.',
        image: image3,
        link: '',
        icon: icon3
    },
    {
        id: 4,
        title: 'App development',
        description: 'Programming superpowers for your pocket. Its about creating mobile apps by coding the features you use every day.',
        image: image4,
        link: '',
        icon: icon4
    },
    {
        id: 5,
        title: 'FRONTEND: - ANGULAR/REACT/VUES',
        description: 'Angular, React, and Vue.js are popular frontend JavaScript frameworks used to build dynamic and interactive user interfaces for web applications.',
        image: image5,
        link: '',
        icon: icon5
    },
    {
        id: 6,
        title: 'Erp Software',
        description: 'ERP software centralizes and automates business processes, enhancing efficiency and decision-making with real-time visibility across departments.',
        image: image6,
        link: '',
        icon: icon1
    },
    {
        id: 7,
        title: 'ESPARSE HRMS Software',
        description: 'HRMS (Human Resource Management System) software streamlines HR processes like recruitment, payroll, and performance management.',
        image: image7,
        link: '',
        icon: icon2
    },
    {
        id: 8,
        title: 'ESPARSE CRM Software',
        description: 'CRM (Customer Relationship Management) software centralizes customer data and automates sales, marketing, and support processes.',
        image: image8,
        link: '',
        icon: icon4
    },
    {
        id: 9,
        title: 'UI/UX Design',
        description: 'UI/UX design combines user interface (UI) aesthetics with user experience (UX) functionality to create intuitive and visually appealing digital experiences.',
        image: image9,
        link: '',
        icon: icon6
    },
    {
        id: 10,
        title: ' BACKEND: - NODEJS',
        description: 'Node.js is a server-side runtime environment that allows developers to build scalable and efficient backend applications using JavaScript.',
        image: image10,
        link: '',
        icon: icon7
    }
]
const settings = {
  
    centerMode: true,
    infinite: true,
    centerPadding: "100px", // Adjust the centerPadding as needed
    slidesToShow: 3, // Default for larger screens
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    focusOnSelect: true,
    
    responsive: [
        {
            breakpoint: 1024, // Desktop view
            settings: {
                slidesToShow: 2, // Show 3 slides at a time
                centerPadding: "100px", // Keep the centerPadding for desktop
            },
        },
        {
            breakpoint: 768, // Tablet view
            settings: {
                slidesToShow: 1, // Show 2 slides at a time
                centerPadding: "60px", // Adjust padding for tablet
            },
        },
        {
            breakpoint: 480, // Mobile view
            settings: {
                slidesToShow: 1, // Show 1 slide at a time
                centerPadding: "0px", // No padding on mobile
                centerMode: false, // Disable centerMode for mobile
            },
        },
    ],
};
// const settings = {
//     className: "center",
//     centerMode: true,
//     infinite: true,
//     centerPadding: "100px",
//     slidesToShow: 3,
//     speed: 500,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 1000,
//     focusOnSelect: true,
// };


const HomeServices = () => {


    return (
<div className="bg-[#EAEEFB] h-auto sm:h-[300px] lg:h-[700px] md:h-auto px-6 sm:px-8 md:px-10 lg:px-[88px] flex items-center justify-center gap-6 md:gap-10 lg:gap-[29px] relative min-h-screen">
    <Slider {...settings} className="overflow-hidden w-full">
        {services.map((s) => (
            <div
                className="w-full max-w-[363px] h-auto md:h-[560px]  relative flex flex-col items-center justify-center"
                key={s.id}
            >
                {/* Image Section */}
                <img
                    alt="service"
                    src={s.image}
                    className="w-full h-[400px]  sm:h-[400px] md:h-[398.75px] object-cover px-2"
                />
                
                {/* Card Section */}
                <div className="w-full max-w-[280px] sm:h-[200px] h-auto md:h-[360px] bg-[#FFF] rounded flex flex-col items-center p-4 sm:p-5 md:p-6 gap-4 absolute bottom-5 text-justify mx-3">
                    {/* Icon */}
                    <div className="w-[70px] h-[70px] p-[15px] rounded-full shadow-[0_2px_2px_0px_rgba(0,0,0,0.25)] bg-[#FFFFFF] absolute -top-8">
                        <img src={s.icon} alt="icon" className="w-full h-full object-contain" />
                    </div>
                    
                    {/* Content */}
                    <div className="text-center mt-10">
                        <p className="text-[14px] sm:text-[16px] font-bold leading-[22px] sm:leading-[25.6px]">
                            {s.title}
                        </p>
                        <p className="text-[14px] sm:text-[16px] font-normal leading-[22px] sm:leading-[25.6px] text-justify px-3">
                            {s.description}
                        </p>
                    </div>
                    
                    {/* Button */}
                    <button className="text-[16px] rounded px-6 py-2 bg-[#006BD3] text-[#fff] mt-auto">
                        View Detail
                    </button>
                </div>
            </div>
        ))}
    </Slider>
</div>




/* <div className="bg-[#EAEEFB] h-auto px-6 md:px-10 lg:px-[88px] flex items-center justify-center gap-6 md:gap-10 lg:gap-[29px] relative min-h-screen">
    <Slider {...settings} className="overflow-hidden w-full">
        {services.map((s) => (
            <div
                className="w-full max-w-[363px] h-auto md:h-[560px] relative flex items-center justify-center"
                key={s.id}
            >
                <img
                    alt="sde"
                    src={s.image}
                    className="w-full max-w-[363px] h-auto md:h-[358.75px] object-cover px-2"
                />
                <div className="w-full max-w-[307px] h-auto md:h-[360px] bg-[#FFF] rounded flex flex-col items-center p-4 md:p-6 gap-4 absolute bottom-5  text-justify mx-5" >
                    <div className="w-[84px] h-[84px] p-[20px] rounded-full shadow-[0_2px_2px_0px_rgba(0,0,0,0.25)] bg-[#FFFFFF] absolute -top-10">
                        <img src={s.icon} alt="icon1" className="w-full h-full object-contain" />
                    </div>
                    <div className="text-center mt-10">
                        <p className="text-[14px] md:text-[16px] font-bold leading-[22px] md:leading-[25.6px]">
                            {s.title}
                        </p>
                        <p className="text-[14px] md:text-[16px] font-normal leading-[22px] md:leading-[25.6px] text-left px-3">
                            {s.description}
                        </p>
                    </div>
                    <button className="text-[16px] rounded px-6 py-2 bg-[#006BD3] text-[#fff] mt-auto">
                        View Detail
                    </button>
                </div>
            </div>
        ))}
    </Slider>
</div> */

    )
}

export default HomeServices
