import React from 'react'

const HomeQuickServices = () => {
    return (
        <div className="flex flex-col gap-6 px-4 sm:px-12 lg:px-64">
        {/* Heading */}
        <p className="text-center text-black font-nunito font-semibold text-2xl sm:text-3xl lg:text-[32px] text-black  leading-snug sm:leading-relaxed">
          Our Services
        </p>
      
        {/* Description */}
        <p className="font-nunito text-sm sm:text-base lg:text-lg font-medium leading-relaxed text-justify">
          <spam className='text-black font-bold'>eSparse Matrix Solutions </spam>Private Limited is a Software agency in Pune. We will provide a one-stop solution for all
          customer IT service needs. We will take care of all your needs, from strategic concept software development,
          business analysis, digital marketing, and software development to online marketing—all in one place. Once you join
          eSparse Matrix Solutions, you will never have to go anywhere else again.
        </p>
      </div>
      
    )
}

export default HomeQuickServices