import React from "react";
import Slider from "react-slick";
import image1 from "../assets/servicesImages/webdev/card1.png";
import image2 from "../assets/servicesImages/webdev/card2.png";
import image3 from "../assets/servicesImages/webdev/card3.png";
import image4 from "../assets/servicesImages/webdev/card4.png";
import image5 from "../assets/servicesImages/webdev/card5.png";
import image6 from "../assets/servicesImages/webdev/card6.png";

const process = [
  {
    image: image1,
    title: "Discovery",
    type: "(Site specification, site plan)",
    desc: "It involves defining goals, understanding the audience, and conducting a competitive analysis ....",
    link: "",
  },
  {
    image: image2,
    title: "Information architecture",
    type: "(Images,Video,Text)",
    desc: "Organize content, create sitemaps, and design intuitive navigation for a seamless user experience....",
    link: "",
  },
  {
    image: image3,
    title: "Website& UI Design",
    type: "(Images,Video,Text)",
    desc: "Create appealing, brand-consistent, user-friendly interfaces with responsive design...use wireframes and prototypes...",
    link: "",
  },
  {
    image: image4,
    title: "Website Lauch",
    type: "(Testing, SEO, Analytics Setup)",
    desc: "Finalize testing, ensure all functionalities work, optimize for SEO, set up analytics, and deploy the site for public access....",
    link: "",
  },
  {
    image: image5,
    title: "Testing",
    type: "(Functionality, Compatibility)",
    desc: "Ensure the website functions correctly across all devices and browsers, and check for bugs and performance issues...",
    link: "",
  },
  {
    image: image6,
    title: "Website Deployment",
    type: "(Coding, Integration, Testing)",
    desc: "Build and code the website’s functionality, ensuring it operates smoothly & meets design specifications.",
    link: "",
  },
];

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 4,
  speed: 500,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1024, // Desktop view
      settings: {
        slidesToShow: 3, // Show 3 slides at a time
        centerPadding: "100px", // Keep the centerPadding for desktop
      },
    },
    {
      breakpoint: 768, // Tablet view
      settings: {
        slidesToShow: 2, // Show 2 slides at a time
        centerPadding: "60px", // Adjust padding for tablet
      },
    },
    {
      breakpoint: 480, // Mobile view
      settings: {
        slidesToShow: 1, // Show 1 slide at a time
        centerPadding: "0px", // No padding on mobile
        centerMode: false, // Disable centerMode for mobile
      },
    },
  ],
};

const WebDevSection3 = () => {
  return (
    <div className="md:h-[488px] flex flex-col gap-[36px] justify-center items-center mx-20 ">
      <p className="text-[32px] font-semibold leading-[23.4px] text-center ">
        Our Process
      </p>
      <Slider {...settings} className="overflow-hidden w-full px-5">
        {process.map((p, index) => (
          <div key={index} className="flex justify-center items-center my-4">
            <div className="w-auto h-[404px] sm:w-[210px] sm:h-[404px] md-lg:w-[220px] md:h-[404px] gap-[13px] rounded-lg flex flex-col items-center  shadow-[1px_1px_8px_2px_#515A9340]">
              <img
                src={p.image}
                alt="icon"
                className=" h-[194.76px] rounded w-full  object-cover"
              />
              <div className=" w-full flex flex-col items-center gap-[8px] px-5 ">
                <p className="text-[20px] font-bold leading-[36px] text-center">
                  {p.title}
                </p>
                <p className="text-[16px] leading-[24px] text-center">
                  {p.type}
                </p>
                <p className="text-[14px] font-normal leading-[20px] text-center text-[#8492A6]">
                  {p.desc}
                </p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default WebDevSection3;
