import React from "react";
import bgImage from "../../assets/servicesImages/appdev/hero.png";
import AppDevSection2 from "../../Pages/AppDevSection2";
import AppDevSection3 from "../../Pages/AppDevSection3";
import bottomImage from "../../assets/servicesImages/appdev/bottom.png";

const AppDev = () => {
  return (
    <div className="flex flex-col gap-16">
      <div
        className="h-screen min-h-[500px] w-full text-white flex justify-start items-center pt-24 relative bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)] z-0"></div>
        <div className="flex flex-col gap-12 px-6 md:px-28 z-10">
          <div className="flex flex-col gap-8">
            <p className="font-nunito text-3xl md:text-5xl leading-tight text-left">
              Application Development
            </p>
            <p className="text-lg md:text-xl max-w-lg md:max-w-xl leading-relaxed text-left">
              We are leading app developers for iOS, Android, and hybrid mobile
              platforms. We serve different market segments and cater to
              clients' needs.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-8 px-6 md:px-16 lg:px-64">
        <p className="text-center font-nunito text-xl md:text-3xl font-semibold">
          Application Development
        </p>
        <p className="font-nunito text-sm md:text-lg leading-relaxed text-justify max-w-full md:max-w-4xl">
          Mobile Apps are making a major contribution to bringing Digital
          Transformation in the Business World while creating a rapid buzz.
          Mobile Apps are one of the crucial parts of the Digital Transformation
          Strategy, which is efficiently digitalizing your business while
          improving the engagement of your Target Audience. Having a Business
          app is a must for every growing company as it provides a great user
          experience while allowing you to reach your tech-savvy audience. Our
          team at eSparse Matrix Solutions Pvt. Ltd. offers interactive mobile
          experiences at a budget-friendly price. We assure you that using iOS
          mobile apps developed by one of the most trusted Mobile App
          Development Companies in India will lead your company towards healthy
          finances with higher revenue and increased ROI. Keep your business
          ahead of digital transformation!
        </p>
      </div>

      <AppDevSection2 />
      <AppDevSection3 />
      <div className="h-fit text-white flex justify-center items-center pb-24">
        <img src={bottomImage} alt="bottom" className="w-full object-cover" />
      </div>
    </div>
  );
};

export default AppDev;
