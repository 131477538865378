import React from 'react'
import homeAbout from '../assets/homeAbout.png'
const HomeAbout = () => {
    return (
        <div className='overflow-hidden px-6 sm:px-12 lg:px-24 h-auto flex flex-col justify-between '>
    <div className='w-full sm:w-[90%] lg:w-[1264px] max-w-full h-auto flex flex-col gap-6 sm:gap-8 lg:gap-9'>
        <p className='text-2xl  text-black sm:text-3xl lg:text-[32px] font-semibold leading-9 sm:leading-[45px] tracking-[0.5px] text-center'>
            About eSparse Matrix Solutions Private Limited
        </p>
        <p className='text-sm sm:text-base lg:text-[16px] font-semibold leading-[32px] text-justify '>
            eSparse Matrix Solutions Private Limited is a software agency in Pune that includes Enterprise Software development, Website designing and Software development services, Mobile application development, AI/ML Development.
        </p>

        <div className='flex flex-col sm:flex-row gap-6 sm:gap-8 pt-8' style={{marginTop:'-45px'}}>
            <div className='w-full sm:w-[50%] h-auto flex flex-col gap-6 sm:gap-[29px]'>
                <p className='text-sm sm:text-base lg:text-[16px] leading-[32px] text-justify'>
                    At eSparse Matrix Solutions, we prioritize excellence, relevance, accessibility, and usability. Our expert developers and digital marketers craft tailored business software solutions. We leverage cutting-edge technologies to deliver the best in software development and <strong>AI/ ML Development...</strong>
                </p>
                <p className='text-sm sm:text-base lg:text-[16px] leading-[32px] text-justify'>
                    What sets us apart is our focus on software that clearly communicates business objectives and ensures user-oriented functionality. Our goal is to articulate your business case effectively, providing top-notch software development services in Pune...
                </p>
                <button className='bg-[#006BD3] w-[112px] sm:w-[150px] h-[42px] p-[8px_12px] gap-[8px] rounded text-[#FFF]'>
                    Explore more
                </button>
            </div>

            <div className='w-full sm:w-[50%] h-auto sm:h-[360px] rounded'>
                <img src={homeAbout} alt='homeAbout' className='w-full h-full object-cover'/>
            </div>
        </div>
    </div>
</div>

    //     <div className='overflow-x-hidden px-6 sm:px-12 lg:px-24 h-auto flex flex-col justify-between'>
    //     <div className='w-full sm:w-[90%] lg:w-[1264px] max-w-full h-auto flex flex-col gap-6 sm:gap-8 lg:gap-9'>
    //         <p className='text-2xl sm:text-3xl lg:text-[32px] font-semibold leading-9 sm:leading-[45px] tracking-[0.5px] text-center'>
    //             About eSparse Matrix Solutions Private Limited
    //         </p>
    //         <p className='text-sm sm:text-base lg:text-[16px] font-semibold leading-[32px] text-left'>
    //             eSparse Matrix Solutions Private Limited is a software agency in Pune that includes Enterprise Software development, Website designing and Software development services, Mobile application development, AI/ML Development.
    //         </p>
    
    //         <div className='flex flex-col sm:flex-row gap-6 sm:gap-8 pt-8'>
    //             <div className='w-full sm:w-[50%] h-auto flex flex-col gap-6 sm:gap-[29px]'>
    //                 <p className='text-sm sm:text-base lg:text-[16px] leading-[32px] text-left'>
    //                     At eSparse Matrix Solutions, we prioritize excellence, relevance, accessibility, and usability. Our expert developers and digital marketers craft tailored business software solutions. We leverage cutting-edge technologies to deliver the best in software development and <strong>AI/ ML Development...</strong>
    //                 </p>
    //                 <p className='text-sm sm:text-base lg:text-[16px] leading-[32px] text-left'>
    //                     What sets us apart is our focus on software that clearly communicates business objectives and ensures user-oriented functionality. Our goal is to articulate your business case effectively, providing top-notch software development services in Pune...
    //                 </p>
    //                 <button className='bg-[#006BD3] w-[112px] sm:w-[150px] h-[42px] p-[8px_12px] gap-[8px] rounded text-[#FFF]'>
    //                     Explore more
    //                 </button>
    //             </div>
    
    //             <div className='w-full sm:w-[50%] h-auto sm:h-[360px] rounded'>
    //                 <img src={homeAbout} alt='homeAbout' className='w-full h-full object-cover'/>
    //             </div>
    //         </div>
    //     </div>
    // </div>
    

        // <div className='px-24 h-[533px] flex flex-col justify-between'>
        //     <div className='w-[1264px] h-[141px] flex flex-col gap-[36px]'>
        //         <p className='text-[32px] font-semibold leading-[45px] tracking-[0.5px] text-center'>About eSparse Matrix Solutions Private Limited</p>
        //         <p className='text-[16px] font-semibold leading-[32px] text-left'>eSparse Matrix Solutions Private Limited is a software agency in Pune that includes Enterprise Software development, Website designing and Software development services, Mobile application development, AI/ML Development.</p>

        //         <div className=' flex h-[272px] pt-[8px] gap-[45px]'>
        //             <div className='w-[50%] h-[334px] flex flex-col gap-[29px] '>
        //                 <p className='text-[16px] leading-[32px] text-left'>
        //                     At eSparse Matrix Solutions, we prioritize excellence, relevance, accessibility, and usability. Our expert developers and digital marketers craft tailored business software solutions. We leverage cutting-edge technologies to deliver the best in software development and <strong>AI/ ML Development...</strong>
        //                 </p>
        //                 <p className='text-[16px] leading-[32px] text-left'>
        //                     What sets us apart is our focus on software that clearly communicates business objectives and ensures user-oriented functionality. Our goal is to articulate your business case effectively, providing top-notch software development services in Pune...
        //                 </p>
        //                 <button className='bg-[#006BD3] w-[112px] h-[42px] p-[8px_12px] gap-[8px] rounded text-[#FFF]'>
        //                     Explore more
        //                 </button>
        //             </div>

        //             <div className='w-[597px] h-[360px] rounded'>
        //                 <img src={homeAbout} alt='homeAbout' />
        //             </div>
        //         </div>
        //     </div>



        // </div>
    )
}

export default HomeAbout