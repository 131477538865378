import React from 'react'
import heroImage from '../assets/heroImage.png'
import bgImage from '../assets/heroBg.png'
const Hero = () => {
    return (
<div
  className="h-screen w-full text-white flex flex-col justify-start items-start pt-16 sm:pt-24 relative bg-cover bg-center"
  style={{ backgroundImage: `url(${bgImage})` }}
>
  {/* Background Overlay */}
  <div className="absolute inset-0 bg-[rgba(44,54,88,0.65)] z-0"></div>

  {/* Main Content */}
  <div className="flex flex-col gap-6 sm:gap-8 px-4 sm:px-12 lg:px-28 z-10 w-full lg:w-1/2 text-left">
    {/* Text Section */}
    <div className="flex flex-col gap-4 sm:gap-6 mt-5 ">
      <p className="mt-5 font-nunito text-xl sm:text-3xl lg:text-4xl leading-tight sm:leading-snug lg:leading-[67.2px]">
        Digitally Yours.
      </p>
      <p className="font-nunito text-xl sm:text-3xl lg:text-4xl leading-tight sm:leading-snug lg:leading-[67.2px]">
        We are a Digital Agency.
      </p>
    </div>

    {/* Description */}
    <p className="font-nunito text-sm sm:text-base lg:text-lg font-normal leading-relaxed text-justify">
      eSparse Matrix Solutions Private Limited is a Best Software Development Company that includes Enterprise Software
      development, Website designing and development, Mobile application development, Digital marketing.
    </p>

    {/* Button */}
    <div>
      <button className="bg-[#006BD3] px-6 py-3 sm:px-8 sm:py-4 rounded text-sm sm:text-base lg:text-lg">
        Get Started
      </button>
    </div>
  </div>

  {/* Image Section */}
  <div className="absolute bottom-0 right-0 w-full sm:w-1/2 h-auto flex justify-end">
    <img
      alt="heroImage"
      src={heroImage}
      className="w-[80%] sm:w-[50%] lg:w-[641.48px] lg:h-[554px] object-contain"
    />
  </div>
</div>

        // <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
        //     style={{ backgroundImage: `url(${bgImage})` }}>
        //     <div className="absolute inset-0 bg-[rgba(44,54,88,0.65)] z-0"></div>
        //     <div className='flex flex-col gap-[48px] p-28 z-10'>
        //         <div className='flex flex-col items-center w-[550px] h-[270px] gap-[32px]'>
        //             <div>
        //                 <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
        //                     Digitally Yours.
        //                 </p>
        //                 <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
        //                     We are a Digital Agency.
        //                 </p>
        //             </div>
        //             <div>
        //                 <p className="font-nunito text-[18px] font-normal leading-[25.6px] text-left">
        //                     eSparse Matrix Solutions Private Limited is a Best Software Development Company that includes Enterprise Software development, Website designing and development, Mobile application development, Digital marketing.
        //                 </p>
        //             </div>
        //         </div>
        //         <div>
        //             <button className="bg-[#006BD3] px-[34px] py-4   rounded gap-[8px]">Get Started</button>
        //         </div>

        //     </div>
        //     <div className='absolute bottom-0 right-0 w-1/2 h-auto'>
        //         <img alt='heroImage' src={heroImage} className='w-[641.48px] h-[554px]' />
        //     </div>

        // </div>
    )
}

export default Hero