import React from 'react'
import bgImage from '../assets/career/hero.png'
import { IoIosCheckmark } from "react-icons/io";
import { Link } from 'react-router-dom';



const cards = [
    {
        type: 'Full Time',
        role: 'Website Developer',
        exp: '1-2 year experience',
    },
    {
        type: 'Full Time',
        role: 'Flutter Developer',
        exp: '1+ Years Experience',
    },
    {
        type: 'Internship',
        role: 'UI/UX Internship',
        exp: 'Fresher',
    },
    {
        type: 'Internship',
        role: 'Graphic Design Intern',
        exp: '0-1 year experience',
    },
    {
        type: 'Internship',
        role: 'Andriod Developer',
        exp: '0-1 year experience',
    },
    {
        type: 'Internship',
        role: 'APP DEVELOPMENT INTERN',
        exp: '0-1 year experience',
    },
    {
        type: 'Full Time',
        role: 'Website Developer',
        exp: '1-2 year experience',
    },
    {
        type: 'Full Time',
        role: 'Website Developer',
        exp: '1-2 year experience',
    },
]


const Career = () => {
    return (
        <div className="flex flex-col min-h-screen mb-10    ">
        {/* Hero Section */}
        <div
          className="min-h-[60vh] sm:min-h-[75vh] lg:min-h-screen w-full text-[#fff] flex justify-start items-center pt-24 relative bg-cover bg-center"
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)] z-0"></div>
          <div className="flex flex-col gap-12 p-6 sm:p-12 md:p-20 lg:p-28 z-10 max-w-[1200px] mx-auto">
            <div className="flex flex-col w-full sm:w-[350px] md:w-[415px] lg:w-[500px] gap-6">
              <p className="font-nunito text-2xl sm:text-3xl md:text-4xl text-left">
                ESPARSE MATRIX SOLUTIONS - New Job's
              </p>
            </div>
          </div>
        </div>
  
        {/* Content Section */}
        <div className="flex-grow flex flex-col gap-8 px-6 sm:px-12 md:px-24 lg:px-36 max-w-[1200px] mx-auto">
          <p className="text-center text-2xl sm:text-3xl font-semibold leading-[1.2]">
            eSparse Matrix 360 Digital Marketing
          </p>
          <p className="text-base sm:text-lg leading-[1.6] text-justify">
            Due to our extensive expertise, team of trained experts, core
            industry knowledge, and committed operating procedure, we are a
            leading custom software development firm that provides top-rated
            custom software development services. We recognise that every
            business has unique software specifications, and we provide a wide
            range of software development services to meet those needs.
          </p>
        </div>
  
        {/* Recommended Job Section */}
        <div className="px-6 sm:px-12 md:px-24 lg:px-36 max-w-[1200px] mx-auto">
          <p className="text-left text-xl sm:text-2xl font-semibold mb-6">
            Recommended Job:
          </p>
  
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {cards.map((data, index) => (
              <div
                className="w-full h-auto rounded-lg bg-[#F8F9FC] shadow-md"
                key={index}
              >
                {/* Header */}
                <div className="h-[55px] p-4 rounded-tl-lg rounded-tr-lg bg-[#00000008] text-[#8492A6] text-left text-lg mb-2">
                  <p>{data.type}</p>
                </div>
  
                {/* Job Info */}
                <div className="h-[130px] p-4 flex flex-col gap-3">
                  <p className="text-[#161C2D] text-left text-lg px-5">
                    {data.role}
                  </p>
                  <p className="text-[#8492A6] text-left text-sm px-5">
                    Requirements:
                  </p>
  
                  <div className="flex items-center text-[#8492A6] text-sm">
                    <IoIosCheckmark className="text-[30px]" />
                    <p className="text-[#8492A6] text-left">{data.exp}</p>
                  </div>
                </div>
  
                {/* Footer */}
                <div className="h-[76px] p-4 rounded-bl-lg rounded-br-lg bg-[#00000008] text-[#8492A6] text-left text-lg border-t-2 border-[#00000020]">
                  <Link to="/apply">
                    <button className="w-full h-[48px] p-2 text-[#2F55D4] border-2 border-[#2F55D4] rounded-lg">
                      View Details
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
  
       
      </div>
//         <div className='flex flex-col gap-16'>

//     <div className='h-[60vh] sm:h-screen w-full text-[#fff] flex justify-start items-center pt-24 relative bg-cover bg-center'
//         style={{ backgroundImage: `url(${bgImage})` }}>
//         <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)] z-0"></div>
//         <div className='flex flex-col gap-12 p-8 sm:p-16 md:p-28 z-10'>
//             <div className='flex flex-col w-full sm:w-[415px] lg:w-[500px] gap-8'>
//                 <p className='font-nunito text-2xl sm:text-3xl md:text-4xl text-left'>
//                     ESPARSE MATRIX SOLUTIONS - New Job's
//                 </p>
//             </div>
//         </div>
//     </div>

//     <div className='flex flex-col gap-8 px-8 sm:px-16 md:px-36'>
//         <p className='text-center text-2xl sm:text-3xl font-semibold leading-[1.2]'>eSparse Matrix 360 Digital Marketing</p>
//         <p className='text-base sm:text-lg leading-[1.6] text-justify'>
//             Due to our extensive expertise, team of trained experts, core industry knowledge, and committed operating procedure, we are a leading custom software development firm that provides top-rated custom software development services. We recognise that every business has unique software specifications, and we provide a wide range of software development services to meet those needs.
//         </p>
//     </div>

//     <div className='px-8 sm:px-16 md:px-36'>
//         <p className='text-left text-xl sm:text-2xl font-semibold mb-6'>Recommended Job :</p>

//         <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mb-10'>
//             {cards.map((data, index) => (
//                 <div className='w-full sm:w-[80%] md:w-[90%] lg:w-[70%] xl:w-[100%] h-auto rounded-lg bg-[#F8F9FC] shadow-md' key={index}>
                    
//                     <div className='h-[55.14px] p-4 rounded-tl-lg rounded-tr-lg bg-[#00000008] text-[#8492A6] text-left text-lg mb-2'>
//                         <p>{data.type}</p>
//                     </div>

//                     <div className='h-[129px] p-4 flex flex-col gap-3'>
//                         <p className='text-[#161C2D] text-left text-lg px-5'>{data.role}</p>
//                         <p className='text-[#8492A6] text-left text-sm px-5'>Requirements:</p>

//                         <div className='flex items-center text-[#8492A6] text-sm'>
//                             <IoIosCheckmark className='text-[30px]' />
//                             <p className='text-[#8492A6] text-left'>{data.exp}</p>
//                         </div>
//                     </div>

//                     <div className='h-[76.62px] p-4 rounded-bl-lg rounded-br-lg bg-[#00000008] text-[#8492A6] text-left text-lg border-t-2 border-[#00000020]'>
//                         <Link to='/apply'>
//                             <button className='w-full h-[48.35px] p-[10px_20px] text-[#2F55D4] border-2 border-[#2F55D4] rounded-lg'>
//                                 View Details
//                             </button>
//                         </Link>
//                     </div>
//                 </div>
//             ))}
//         </div>
//     </div>
// </div>

        // <div className='flex flex-col gap-[64px]'>

        //     <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
        //         style={{ backgroundImage: `url(${bgImage})` }}>
        //         <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
        //         <div className='flex flex-col gap-[48px] p-28 z-10'>
        //             <div className='flex flex-col w-[415px] h-[270px] gap-[32px]'>
        //                 <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
        //                     ESPARSE MATRIX SOLUTIONS - New Job's
        //                 </p>
        //             </div>
        //         </div>
        //     </div>


        //     <div className='flex flex-col gap-[36px] px-36'>
        //         <p className='text-center text-[32px] font-semibold leading-[23.4px]'>eSparse Matrix 360 Digital Marketing</p>
        //         <p className=' text-[16px] leading-[25.6px] text-justify'>Due to our extensive expertise, team of trained experts, core industry knowledge, and committed operating procedure, we are a leading custom software development firm that provides top-rated custom software development services. We recognise that every business has unique software specifications, and we provide a wide range of software development services to meet those needs.
        //         </p>
        //     </div>


        //     <div className='px-36'>
        //         <p className='text-left text-[22.61px] font-semibold mb-10'>Recommended Job :</p>

        //         <div className='flex flex-wrap mb-10 gap-5'>


        //             {cards.map((data, index) => (
        //                 <div className='h-[263] w-[395.75px] rounded-lg bg-[#F8F9FC]' key={index}>

        //                     <div className='h-[55.14px] p-[13.57px_22.61px_13.57px_22.61px] rounded-tl-lg rounded-tr-lg bg-[#00000008] text-[#8492A6] text-left text-[18.09px] mb-2'>
        //                         <p>{data.type}</p>
        //                     </div>

        //                     <div className='h-[129px] p-[0px_10.18px_27.14px_10.18px] flex flex-col gap-3'>
        //                         <p className='text-[#161C2D] text-left text-[22.61px] px-5'>{data.role}</p>
        //                         <p className='text-[#8492A6] text-left text-[18.09px] px-5'>Requirements:</p>

        //                         <div className='flex items-center text-[#8492A6] text-left text-[16.28px]'>
        //                             <IoIosCheckmark className='text-[30px]' />
        //                             <p className='text-[#8492A6] text-left'>{data.exp}</p>
        //                         </div>
        //                     </div>

        //                     <div className='h-[76.62px] p-[14.7px_22.61px_13.57px_22.61px] rounded-bl-lg rounded-br-lg bg-[#00000008] text-[#8492A6] text-left text-[18.09px] border-top-2 border-[#00000020]'>
        //                         <Link to='/apply'>
        //                             <button className='w-[348.26px] h-[48.35px] p-[10.18px_117.94px_10.18px_117.94px] text-[#2F55D4] border-2 border-[#2F55D4] rounded-lg'>View Details</button>
        //                         </Link>

        //                     </div>
        //                 </div>
        //             ))}


        //         </div>
        //     </div>
        // </div>


    )
}

export default Career