import React from "react";
import Slider from "react-slick";
import image1 from "../assets/servicesImages/appdev/image1.png";
import image2 from "../assets/servicesImages/appdev/image2.png";
import image3 from "../assets/servicesImages/appdev/image3.png";
import image4 from "../assets/servicesImages/appdev/image4.png";
import image5 from "../assets/servicesImages/appdev/image5.png";
import image6 from "../assets/servicesImages/appdev/image6.png";
import image7 from "../assets/servicesImages/appdev/image7.png";
import image8 from "../assets/servicesImages/appdev/image8.png";

const process = [
  {
    image: image1,
    title: "Discovery",
    type: "Identifying and discovering the company's objectives",
    link: "",
  },
  {
    image: image2,
    title: "Proposal for a project",
    type: "Creating comprehensive project plans.",
    link: "",
  },
  {
    image: image3,
    title: "UX/UI",
    type: "Forcing the introduction of",
    link: "",
  },
  {
    image: image4,
    title: "Putting it into action",
    type: "Developing and implementing workable systems and code.",
    link: "",
  },
  {
    image: image5,
    title: "Assurance of Quality",
    type: "At any step, there is rigorous quality control monitoring. ",
    link: "",
  },
  {
    image: image6,
    title: "Security review",
    type: "Every stage of development we take security review.",
    link: "",
  },
  {
    image: image7,
    title: "Project Deployment",
    type: "Copying, configuring, and enabling code is all part of the process. ",
    link: "",
  },
  {
    image: image8,
    title: "Support",
    type: "As long as you need us, we'll support ongoing maintenance. ",
    link: "",
  },
];

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 4,
  speed: 500,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1024, // Desktop view
      settings: {
        slidesToShow: 4, // Show 3 slides at a time
        centerPadding: "100px", // Keep the centerPadding for desktop
      },
    },
    {
      breakpoint: 768, // Tablet view
      settings: {
        slidesToShow: 2, // Show 2 slides at a time
        centerPadding: "60px", // Adjust padding for tablet
      },
    },
    {
      breakpoint: 480, // Mobile view
      settings: {
        slidesToShow: 1, // Show 1 slide at a time
        centerPadding: "0px", // No padding on mobile
        centerMode: false, // Disable centerMode for mobile
      },
    },
  ],
};

const AppDevSection3 = () => {
  return (
    <div className="h-[488px] flex flex-col gap-[36px] justify-center items-center mx-10 ">
      <p className="text-[32px] font-semibold leading-[23.4px] text-center">
        Our Process
      </p>
      <Slider {...settings} className="overflow-hidden w-full px-5">
        {process.map((p, index) => (
          <div
            key={index}
            className="flex justify-center items-center my-4 px-0 md:px-2"
          >
            <div className="w-full h-[355px] min-h-full gap-[8px] rounded-lg flex flex-col items-center  shadow-[1px_1px_8px_2px_#515A9340]">
              <img
                src={p.image}
                alt="icon"
                className=" h-[193px] rounded w-full  object-cover"
              />
              <div className=" w-full h-[149px] flex flex-col items-center gap-[8px] px-5 ">
                <p className="text-[20px] font-bold leading-[36px] text-center">
                  {p.title}
                </p>
                <p className="text-[16px] leading-[24px] text-center">
                  {p.type}
                </p>
                <p className="text-sm font-normal leading-[20px] text-center text-[#8492A6]">
                  {p.desc}
                </p>
              </div>
              <button className="bg-[#006BD3] w-[112px] mb-3 p-[8px_12px] rounded text-[#FFF]">
                Read More
              </button>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AppDevSection3;
