import React from "react";
import bgImage from "../../assets/servicesImages/hrms/hero.png";
import card1 from "../../assets/servicesImages/hrms/1.png";
import card2 from "../../assets/servicesImages/hrms/2.png";
import card3 from "../../assets/servicesImages/hrms/3.png";
import card4 from "../../assets/servicesImages/hrms/4.png";
import card5 from "../../assets/servicesImages/hrms/5.png";
import card6 from "../../assets/servicesImages/hrms/6.png";
import icon1 from "../../assets/servicesImages/hrms/icon1.png";
import icon2 from "../../assets/servicesImages/hrms/icon2.png";
import icon3 from "../../assets/servicesImages/hrms/icon3.png";
import icon4 from "../../assets/servicesImages/hrms/icon4.png";

import Slider from "react-slick";

const cards = [
  {
    image: card1,
    title: "RECRUITMENT",
    desc: "HR may receive input from different agencies on employee preparation, yearly  and ad - hoc personnel needs. The HRMS assists management workers with handling resume databases, credentials, and interacts with career portals.",
  },
  {
    image: card2,
    title: "PERFORMANCE MANAGEMENT",
    desc: "Esparse HRMS aids in the development of employee evaluation records on a regular basis. Any employee's efficiency parameters can be identified using HRMS. Promotions, preparation, and right-sizing will all benefit from the data.",
  },
  {
    image: card3,
    title: "EMPLOYEE SELF SERVICES",
    desc: "Employee self-services such as copying pay stubs, arranging for tax savings and investments, scheduling trips, internal communications, and outdoor activities can all be efficiently managed with adjustable schedules.",
  },
  {
    image: card4,
    title: "LEAVE MANAGEMENT",
    desc: "Esparse HRMS allows you to handle salaries, leave, leave sanctions, attendance, and regularity, as well as integrate with biometric systems. HRMS guarantees that payroll work at the end of the month is stress-free.",
  },
  {
    image: card5,
    title: "TRAINING",
    desc: "Training requirements are entered into HRMS and received by HR, which processes the data and schedules skill- based training. Competency maps created with HRMS can be used to monitor the success of training. ",
  },
  {
    image: card6,
    title: "HELP DESK",
    desc: "Esparse HRMS Help Desk is a point of touch for staff to speak with managers about concerns and challenges, as well as requests for materials and other products. It has the ability to leave a written note as well as a number of requisition types.",
  },
];

const card = [
  {
    icon: icon1,
    title: "Cost Effective",
    desc: "As a result of the reduced paper work, there is a higher yield per working hour. Per mission, the number of workers has been reduced. Monitoring in real time leads to a more effective Method.    ",
  },
  {
    icon: icon2,
    title: "Easy To Customization ",
    desc: "Client feedback, market, organisational culture, and other criteria were used to build this. Flexible to adapt to the needs of the company as it expands.",
  },
  {
    icon: icon3,
    title: "Unbiased",
    desc: "Making managerial decisions becomes easy. Performance metrics are fed into the system and can be tracked in real time for feedback.   ",
  },
  {
    icon: icon4,
    title: "Structured SYSTEM",
    desc: "Easily structured for Data analytics for board sessions, and evaluation  meetings, among other things. Within minutes of performing calculation  ",
  },
];

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 4,
  speed: 500,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1024, // Desktop view
      settings: {
        slidesToShow: 4, // Show 3 slides at a time
        centerPadding: "100px", // Keep the centerPadding for desktop
      },
    },
    {
      breakpoint: 768, // Tablet view
      settings: {
        slidesToShow: 2, // Show 2 slides at a time
        centerPadding: "60px", // Adjust padding for tablet
      },
    },
    {
      breakpoint: 480, // Mobile view
      settings: {
        slidesToShow: 1, // Show 1 slide at a time
        centerPadding: "0px", // No padding on mobile
        centerMode: false, // Disable centerMode for mobile
      },
    },
  ],
};

const HRMS = () => {
  return (
    <div className="flex flex-col gap-[64px]">
      {/* <div
        className="h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
        <div className="flex flex-col gap-[48px] p-28 z-10">
          <div className="flex flex-col w-[470px] h-[270px] gap-[32px]">
            <p className="font-nunito text-[48px] leading-[67.2px] text-left">
              ESPARSE HRMS SOLUTIONS
            </p>
            <p className="text-[18px] w-[500px] font-normal leading-[25.6px] text-left">
              A modern organization's most critical and cost-intensive role is
              human resource management.
            </p>
          </div>
        </div>
      </div> */}

      <div
        className="h-screen w-full text-white flex justify-start items-center pt-24 relative bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)] z-0"></div>
        <div className="flex flex-col gap-12 p-6 sm:p-12 md:p-20 lg:p-28 z-10">
          <div className="flex flex-col max-w-[90%] sm:max-w-[470px] gap-6">
            <p className="font-nunito text-3xl sm:text-4xl md:text-5xl leading-tight text-left">
              ESPARSE HRMS SOLUTIONS
            </p>
            <p className="text-lg sm:text-xl font-normal leading-relaxed text-left">
              A modern organization's most critical and cost-intensive role is
              human resource management.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:gap-[36px] md:px-36">
        <p className="text-center font-nunito text-2xl sm:text-3xl font-semibold leading-tight">
          About eSparse HRMS Solutions
        </p>
        <p className="font-nunito text-[16px] px-3 mt-5 md:mt-0 md:px-0 font-medium leading-[25.6px] text-justify">
          A modern organization's most critical and cost-intensive role is human
          resource management. The HR department is often burdened with
          cumbersome documentation and monotonous duties due to its various
          roles, which include recruiting, resource control, and employee
          grievance redressal. Esparse HRMS Solutions has developed a formidable
          reputation for delivering customised HRMS solutions. Esparse HRMS is a
          one-stop shop for all HR needs, including recruiting, employee
          database management, leave tracking, payroll management, preparation,
          and success management. Since each client is different in terms of
          team size, processes, and specifications, each HRMS Module is
          customised to meet the specific needs of the client customers.{" "}
        </p>
      </div>

      <div className="flex flex-col md:gap-[36px] md:px-36">
        <p className="text-center font-nunito text-2xl px-3 md:px-0 sm:text-3xl font-semibold leading-tight">
          Esparse HRMS's Solutions Key Features
        </p>
        <p className="font-nunito text-[16px] px-3 mt-5 md:mt-0 md:px-0 font-medium leading-[25.6px] text-center">
          Human resources are unique and provide a significant competitive edge
          to every business. In today's demanding climate, attracting and
          keeping the best workforce has been the most difficult task. The
          Esparse HRMS allows smooth incorporation between departmental,
          intra-departmental, and entity levels.
        </p>
      </div>

      <div className=" h-[500px] flex flex-col justify-center">
        <div className="flex flex-col gap-[29px]">
          <Slider {...settings} className="overflow-hidden w-full px-10 my-3">
            {cards.map((item) => (
              <div className="py-10 px-0 md:px-2">
                <div className="w-full h-[490px] rounded-lg shadow-[1px_4px_4px_1px_#00000036]">
                  <img
                    src={item.image}
                    alt="card"
                    className="h-[200px] w-full rounded-tl-lg rounded-tr-lg"
                  />
                  <div className="text-[#3C4858] mt-1">
                    <p className="text-[20px] font-extrabold leading-[30px] tracking-[0.5px] text-center h-[46px]">
                      {item.title}
                    </p>
                    <p className="text-[14px] font-normal leading-[25.6px] text-center px-5">
                      {item.desc}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className="flex flex-col md:gap-[36px] md:px-36">
        <p className="text-center font-nunito text-2xl px-3 md:px-0 sm:text-3xl font-semibold leading-tight">
          Why ESPARSE HRMS?
        </p>
        <p className="font-nunito text-[16px] px-3 mt-5 md:mt-0 md:px-0 font-medium leading-[25.6px] text-center">
          Esparse HRMS is useful because it allows for more operating hours a
          day. The presented personalised HRMS approach eliminates the system's
          flaws. See how HRMS benefits our customers.
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-4 sm:px-8 md:px-10 mb-10">
        {card.map((c, index) => (
          <div
            key={index}
            className="shadow-md border rounded-lg p-6 flex flex-col items-center text-center"
          >
            <img src={c.icon} alt="icon1" className="w-[60.33px] h-[59.49px]" />

            <p className="text-lg font-bold">{c.title}</p>
            <p className="text-sm">{c.desc}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HRMS;
