import React from "react";
import bgImage from "../../assets/servicesImages/crm/hero.png";
import Slider from "react-slick";
import card1 from "../../assets/servicesImages/crm/1.png";
import card2 from "../../assets/servicesImages/crm/2.png";
import card3 from "../../assets/servicesImages/crm/3.png";
import card4 from "../../assets/servicesImages/crm/4.png";
import card5 from "../../assets/servicesImages/crm/5.png";
import card6 from "../../assets/servicesImages/crm/6.png";
import icon1 from "../../assets/servicesImages/crm/icon1.png";
import icon2 from "../../assets/servicesImages/crm/icon2.png";
import icon3 from "../../assets/servicesImages/crm/icon3.png";
import icon4 from "../../assets/servicesImages/crm/icon4.png";

const cards = [
  {
    image: card1,
    title: "Contact Management",
    desc: "Manage your clients, prospects, and vendors, as well as all of your contacts and follow-up activities.  ",
  },
  {
    image: card2,
    title: "Sales Pipeline Management",
    desc: "Keep track of the sales leads and prioritise them in the sales process. ",
  },
  {
    image: card3,
    title: "Reporting",
    desc: "With a single click, create detailed management information reports.  ",
  },
  {
    image: card4,
    title: "Sales Quotations",
    desc: "Create quotes based on your business opportunities and deliver them to your clients directly.   ",
  },
  {
    image: card5,
    title: "Importing leads from Excel",
    desc: "Can you have a large amount of data to import? You can conveniently import data using our bulk import function by simply importing an excel file.     ",
  },
  {
    image: card6,
    title: "User Roles & Profiles",
    desc: "You will control which users in your company have access to which data and fields. You have full control of what things they can see and what decisions they can take.      ",
  },
];

const card = [
  {
    icon: icon1,
    title: "Simple to use",
    desc: "As a result of the reduced paper work, there is a higher yield per working hour. Per mission, the number of workers has been reduced. Monitoring in real time leads to a more effective Method.    ",
  },
  {
    icon: icon2,
    title: "Focus on your activities",
    desc: "Client feedback, market, organisational culture, and other criteria were used to build this. Flexible to adapt to the needs of the company as it expands.   ",
  },
  {
    icon: icon3,
    title: "UMake better decisions",
    desc: "Making managerial decisions becomes easy. Performance metrics are fed into the system and can be tracked in real time for feedback.   ",
  },
  {
    icon: icon4,
    title: "Increase the performance",
    desc: "Easily structured for Data analytics for board sessions, and evaluation  meetings, among other things. Within minutes of performing calculation  ",
  },
];

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 4,
  speed: 500,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1024, // Desktop view
      settings: {
        slidesToShow: 4, // Show 3 slides at a time
        centerPadding: "100px", // Keep the centerPadding for desktop
      },
    },
    {
      breakpoint: 768, // Tablet view
      settings: {
        slidesToShow: 2, // Show 2 slides at a time
        centerPadding: "60px", // Adjust padding for tablet
      },
    },
    {
      breakpoint: 480, // Mobile view
      settings: {
        slidesToShow: 1, // Show 1 slide at a time
        centerPadding: "0px", // No padding on mobile
        centerMode: false, // Disable centerMode for mobile
      },
    },
  ],
};

const CRM = () => {
  return (
    <div className="flex flex-col gap-[64px]">
      <div
        className="h-screen w-full text-white flex justify-start items-center pt-24 relative bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)] z-0"></div>
        <div className="flex flex-col gap-12 p-6 sm:p-12 md:p-20 lg:p-28 z-10">
          <div className="flex flex-col max-w-[90%] sm:max-w-[470px] gap-6">
            <p className="font-nunito text-3xl sm:text-4xl md:text-5xl leading-tight text-left">
              ESPARSE CRM SOLUTIONS
            </p>
            <p className="text-lg sm:text-xl font-normal leading-relaxed text-left">
              Help Your Business grow By Nurturing Leads
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:gap-[36px] md:px-36">
        <p className="text-center font-nunito text-2xl sm:text-3xl font-semibold leading-tight">
          About ESPARSE CRM
        </p>
        <p className="font-nunito text-[16px] px-3 mt-5 md:mt-0 md:px-0 font-medium leading-[25.6px] text-justify">
          Esparse CRM software creates new tasks for your team. Automate
          everything from appointment reminders to follow-up emails by putting
          your calendar online. With complete contact views, deal tracks,
          real-time DATA, and other features, you can achieve a significantly
          higher ROI. In the Sales CRM, the contact timeline contains all of the
          relevant details about the customer's progress.
        </p>
      </div>

      <div className="flex flex-col md:gap-[36px] md:px-36">
        <p className="text-center px-3 md:px-0 font-nunito text-2xl sm:text-3xl font-semibold leading-tight">
          Esparse CRM Key Features
        </p>
        <p className="font-nunito text-[16px] px-3 mt-5 md:mt-0 md:px-0 font-medium leading-[25.6px] text-justify">
          Human resources are unique and provide a significant competitive edge
          to every business. In today's demanding climate, attracting and
          keeping the best workforce has been the most difficult task. The
          Esparse crm allows smooth incorporation between departmental,
          intra-departmental, and entity levels.
        </p>
      </div>

      <div className=" h-[500px] flex flex-col justify-center">
        <div className="flex flex-col gap-[29px]">
          <Slider {...settings} className="overflow-hidden w-full px-10 my-3">
            {cards.map((item) => (
              <div className="py-10 px-0 md:px-2">
                <div className="w-full h-[490px] min-h-full rounded-lg shadow-[1px_4px_4px_1px_#00000036]">
                  <img
                    src={item.image}
                    alt="card"
                    className="h-[200px] w-full rounded-tl-lg rounded-tr-lg"
                  />
                  <div className="text-[#3C4858] mt-1">
                    <p className="text-[20px] font-extrabold leading-[30px] tracking-[0.5px] text-center h-[60px] px-2">
                      {item.title}
                    </p>
                    <p className="text-sm font-normal leading-[25.6px] text-center px-5">
                      {item.desc}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className="flex flex-col md:gap-[36px] md:px-36">
        <p className="text-center font-nunito text-2xl sm:text-3xl font-semibold leading-tight">
          Why ESPARSE HRMS?
        </p>
        <p className="font-nunito text-[16px] px-3 mt-5 md:mt-0 md:px-0 font-medium leading-[25.6px] text-justify">
          Esparse HRMS is useful because it allows for more operating hours a
          day. The presented personalised HRMS approach eliminates the system's
          flaws. See how HRMS benefits our customers.
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 px-4 sm:px-8 md:px-10 mb-10">
        {card.map((c, index) => (
          <div
            key={index}
            className="shadow-md border rounded-lg p-6 flex flex-col items-center text-center"
          >
            <img src={c.icon} alt="icon1" className="w-[60.33px] h-[59.49px]" />

            <p className="text-lg font-bold">{c.title}</p>
            <p className="text-sm">{c.desc}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CRM;
