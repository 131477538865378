import React from 'react'
import bgImage from '../../assets/products/hero.png'
import Slider from 'react-slick'
import card from '../../assets/products/card.png'


const items = new Array(8).fill(
    {
        image: card,
        title: 'Recorded Sessions',
        desc: 'Access to recorded classes for review and revision Flexible viewing options for students with different schedules',
        link: ''
    }
)


const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 4.3,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
        {
            breakpoint: 1024, // Desktop view
            settings: {
                slidesToShow: 3, // Show 3 slides at a time
                centerPadding: "100px", // Keep the centerPadding for desktop
            },
        },
        {
            breakpoint: 768, // Tablet view
            settings: {
                slidesToShow: 2, // Show 2 slides at a time
                centerPadding: "60px", // Adjust padding for tablet
            },
        },
        {
            breakpoint: 480, // Mobile view
            settings: {
                slidesToShow: 1, // Show 1 slide at a time
                centerPadding: "0px", // No padding on mobile
                centerMode: false, // Disable centerMode for mobile
            },
        },
    ],
};

const Elearning = () => {
    return (
        <div className='flex flex-col gap-[64px]'>

    <div className='h-screen w-full text-[#fff] flex justify-start items-center pt-24 relative bg-cover bg-center'
        style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
        <div className='flex flex-col gap-[48px] p-8 sm:p-12 md:p-16 lg:p-28 z-10'>
            <div className='flex flex-col gap-[32px]'>
                <p className='font-nunito text-[36px] sm:text-[42px] md:text-[48px] leading-[54px] sm:leading-[60px] md:leading-[67.2px] text-left'>
                    Avinya E-learning Platform
                </p>
                <p className="text-[16px] sm:text-[18px] w-[90%] sm:w-[470px] font-normal leading-[22px] sm:leading-[25.6px] text-left">
                    The Ultimate Learning Hub for Students
                </p>
            </div>
        </div>
    </div>

    <p className='text-center font-nunito text-[28px] sm:text-[32px] font-semibold leading-[20px] sm:leading-[23.4px]'>Student Panel Features List</p>

    <div className='h-[485px] flex flex-colitem-center  justify-center'>
        <Slider {...settings} className="overflow-hidden w-full px-16 sm:px-10 my-3 items-center justify-between ">
            {items.map((item) => (
                <div key={item.id} className=''>
                    <div className='w-[252px] sm:w-[320px] lg:w-[292px] h-[447px] flex flex-col items-center bg-[white] gap-[8px] px-7 '>
                        <img src={item.image} alt='card' className='rounded-tr-lg rounded-tl-lg' />
                        <div className='h-[90%] flex flex-col items-center gap-[10px] px-8 sm:px-6'>
                            <p className='text-[#3C4858] text-center font-bold text-[16px] sm:text-[20px]'>
                                {item.title}
                            </p>
                            <p className='font-[#161C2D] text-center text-[14px] sm:text-[16px] leading-[20px] sm:leading-[24px]'>
                                {item.desc}
                            </p>
                        </div>
                        <button className='bg-[#006BD3] w-[200px] sm:w-[241px] h-[24px] rounded text-[white] mb-5'>View More</button>
                    </div>
                </div>
            ))}
        </Slider>
    </div>

    <p className='text-center text-[28px] sm:text-[32px] font-semibold leading-[20px] sm:leading-[23.4px]'>Admin Features</p>

    <div className='h-[485px] flex flex-col item-center justify-center'>
        <Slider {...settings} className="overflow-hidden w-full px-16 sm:px-10 my-3 items-center justify-between ">
            {items.map((item) => (
                <div key={item.id} className=''>
                    <div className='w-[252px] sm:w-[320px] lg:w-[292px] h-[447px] flex flex-col items-center bg-[white] gap-[8px] px-7 '>
                        <img src={item.image} alt='card' className='rounded-tr-lg rounded-tl-lg' />
                        <div className='h-[90%] flex flex-col items-center gap-[10px] px-4 sm:px-6'>
                            <p className='text-[#3C4858] text-center font-bold text-[16px] sm:text-[20px]'>
                                {item.title}
                            </p>
                            <p className='font-[#161C2D] text-center text-[14px] sm:text-[16px] leading-[20px] sm:leading-[24px]'>
                                {item.desc}
                            </p>
                        </div>
                        <button className='bg-[#006BD3] w-[200px] sm:w-[241px] h-[24px] rounded text-[white] mb-5'>View More</button>
                    </div>
                </div>
            ))}
        </Slider>
    </div>

    <div className='flex flex-col gap-[32px] w-full'>
        <p className='text-center text-[28px] sm:text-[32px] font-semibold leading-[20px] sm:leading-[23.4px]'>Benefits of Avinya eLearning Platforms</p>
        <p className='text-center text-[14px] sm:text-[16px] leading-[20px] sm:leading-[23.4px]'>
            eLearning platforms have revolutionized the educational landscape, offering numerous advantages for students, educators, and institutions.
        </p>
    </div>

    <div className='flex flex-wrap justify-center gap-[30px] mb-10'>
        {items.map((item) => (
            <div key={item.id} className=''>
                <div className='w-[292px] h-[447px] flex flex-col items-center bg-[white] gap-[8px] border-2 rounded-lg'>
                    <img src={item.image} alt='card' className='rounded-tr-lg rounded-tl-lg' />
                    <div className='h-[90%] flex flex-col items-center gap-[10px] px-4 sm:px-6'>
                        <p className='text-[#3C4858] text-center font-bold text-[16px] sm:text-[20px]'>
                            {item.title}
                        </p>
                        <p className='font-[#161C2D] text-center text-[14px] sm:text-[16px] leading-[20px] sm:leading-[24px]'>
                            {item.desc}
                        </p>
                    </div>
                    <button className='bg-[#006BD3] w-[200px] sm:w-[241px] h-[24px] rounded text-[white] mb-5'>View More</button>
                </div>
            </div>
        ))}
    </div>

</div>

        // <div className='flex flex-col gap-[64px]'>

        //     <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
        //         style={{ backgroundImage: `url(${bgImage})` }}>
        //         <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
        //         <div className='flex flex-col gap-[48px] p-28 z-10'>
        //             <div className='flex flex-col  gap-[32px]'>
        //                 <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
        //                     Avinya E-learning Platform                        </p>
        //                 <p className="text-[18px] w-[470px] font-normal leading-[25.6px] text-left">
        //                     The Ultimate Learning Hub for Students                            </p>
        //             </div>
        //         </div>
        //     </div>

        //     <p className='text-center ont-nunito text-[32px] font-semibold leading-[23.4px]'>Student Panel Features List</p>

        //     <div className=' h-[485px] flex flex-col justify-center'>
        //         {/* <div className='flex '> */}
        //         <Slider {...settings} className="overflow-hidden w-full px-10 my-3">

        //             {items.map((item) => (
        //                 <div className=''>


        //                     <div className='w-[292px] h-[447px] flex flex-col items-center bg-[white] gap-[8px] border-2 rounded-lg'>
        //                         <img src={item.image} alt='card' className='rounded-tr-lg rounded-tl-lg' />

        //                         <div className=' h-[90%] flex flex-col items-center gap-[10px] px-6'>
        //                             <p className='text-[#3C4858] text-center font-bold font-[20px]'>
        //                                 {item.title}
        //                             </p>
        //                             <p className='font-[#161C2D] text-center font-[16px] leading-[24px]'>
        //                                 {item.desc}
        //                             </p>
        //                         </div>
        //                         <button className='bg-[#006BD3] w-[241px] h-[24px] rounded text-[white] mb-5'>View More</button>
        //                     </div>
        //                 </div>

        //             ))}
        //         </Slider>
        //         {/* </div> */}
        //     </div>


        //     <p className='text-center text-[32px] font-semibold leading-[23.4px]'>Admin Features</p>

        //     <div className=' h-[485px] flex flex-col justify-center'>
        //         {/* <div className='flex '> */}
        //         <Slider {...settings} className="overflow-hidden w-full px-10 my-3">

        //             {items.map((item) => (
        //                 <div className=''>


        //                     <div className='w-[292px] h-[447px] flex flex-col items-center bg-[white] gap-[8px] border-2 rounded-lg'>
        //                         <img src={item.image} alt='card' className='rounded-tr-lg rounded-tl-lg' />

        //                         <div className=' h-[90%] flex flex-col items-center gap-[10px] px-6'>
        //                             <p className='text-[#3C4858] text-center font-bold font-[20px]'>
        //                                 {item.title}
        //                             </p>
        //                             <p className='font-[#161C2D] text-center font-[16px] leading-[24px]'>
        //                                 {item.desc}
        //                             </p>
        //                         </div>
        //                         <button className='bg-[#006BD3] w-[241px] h-[24px] rounded text-[white] mb-5'>View More</button>
        //                     </div>
        //                 </div>

        //             ))}
        //         </Slider>
        //         {/* </div> */}
        //     </div>

        //     <div className='flex flex-col  gap-[32px] w-full'>
        //         <p className='text-center text-[32px] font-semibold leading-[23.4px]'>Benefits of  Avinya eLearning Platforms</p>
        //         <p className='text-center text-[16px] leading-[23.4px]'>eLearning platforms have revolutionized the educational landscape, offering numerous advantages for students, educators, and institutions.</p>

        //     </div>


        //     <div className='flex flex-wrap justify-center gap-[30px] mb-10'>
        //             {items.map((item) => (
        //                 <div className=''>


        //                     <div className='w-[292px] h-[447px] flex flex-col items-center bg-[white] gap-[8px] border-2 rounded-lg'>
        //                         <img src={item.image} alt='card' className='rounded-tr-lg rounded-tl-lg' />

        //                         <div className=' h-[90%] flex flex-col items-center gap-[10px] px-6'>
        //                             <p className='text-[#3C4858] text-center font-bold font-[20px]'>
        //                                 {item.title}
        //                             </p>
        //                             <p className='font-[#161C2D] text-center font-[16px] leading-[24px]'>
        //                                 {item.desc}
        //                             </p>
        //                         </div>
        //                         <button className='bg-[#006BD3] w-[241px] h-[24px] rounded text-[white] mb-5'>View More</button>
        //                     </div>
        //                 </div>

        //             ))}
               
        //     </div>

        // </div>
    )
}

export default Elearning