import React from "react";
import bgImage from "../../assets/servicesImages/webdev/hero.png";
import WebDevSection3 from "../../Pages/WebDevSection3";
import WebDevsection4 from "../../Pages/WebDevsection4";
const WebDev = () => {
  return (
    <div className="flex flex-col md:gap-[64px]">
      <div
        className="h-screen w-full text-[#fff] flex justify-start items-center pt-24 relative bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
        <div className="flex flex-col md:gap-[48px] p-5 md:p-28 z-10">
          <div className="flex flex-col md:w-[520px] md:h-[270px] md:gap-[32px]">
            <p className="font-bold text-2xl md:text-4xl md:text-[48px] leading-[67.2px] text-left">
              Website Development
            </p>
            <p className="text-sm md:text-[18px] font-normal leading-[25.6px] text-left">
              We are a top web design and development company in the market. We
              deliver highly professional, responsive budget-friendly website
              development services{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-[36px] px-5 md:px-60 py-10 md:py-0">
        <p className="text-center ont-nunito text-2xl md:text-[32px] text-black font-semibold leading-[23.4px]">
          Website Development
        </p>
        <p className="font-nunito text-[16px] font-medium leading-[25.6px] text-justify">
          eSparse Matrix Solutions Pvt Ltd is a leading Web & Software
          development company in Pune which provides best software development
          services. We help our clients to convert their business processes into
          the workflow-based application. Having the right web & software for
          your business will not only solve the issues, which the businesses are
          facing but also, will have an edge over your competitors We have a
          team of Software developers who have in-depth knowledge in available
          software development tools like C, C++, Client/Server Programming,
          database concepts, MFC, SQL and windows internals C#, Linux/Unix, Web
          Services, Web Sockets, SVN, etc. Dedicated Developers for each
          requirements like Portal, Web Portal, CMS, E-Commerce, CRM, ERP,
          Database, Document Management System Development, Saas Application and
          Much more.{" "}
        </p>
      </div>

      <WebDevSection3 />
      <WebDevsection4 />
    </div>
  );
};

export default WebDev;
