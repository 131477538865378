import React from 'react';
import Slider from "react-slick";
import icon1 from '../assets/homeApproachesSection/icon1.png';
import icon2 from '../assets/homeApproachesSection/icon2.png';
import icon3 from '../assets/homeApproachesSection/icon3.png';
import icon4 from '../assets/homeApproachesSection/icon4.png';
import icon5 from '../assets/homeApproachesSection/icon5.png';
import icon6 from '../assets/homeApproachesSection/icon6.png';
// import nextArrowImg from '../assets/arrowNext.png';
// import prevArrowImg from '../assets/arrowPrev.png';

// const SampleNextArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ 
//         ...style, 
//         display: "block", 
//         background: `url(${nextArrowImg}) no-repeat center center`,
//         backgroundSize: 'contain',
//         width: '10%',
//         height: '56px',
//         cursor: 'pointer'
//       }}
//       onClick={onClick}
//     />
//   );
// }

// const SamplePrevArrow = (props) => {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ 
//         ...style, 
//         display: "block", 
//         background: `url(${prevArrowImg}) no-repeat center center`,
//         backgroundSize: 'contain',
//         width: '5%',
//         height: '56px',
//         cursor: 'pointer'
//       }}
//       onClick={onClick}
//     />
//   );
// }

const approaches = [
  {
    icon: icon1,
    title: 'Discussion and Analysis',
    desc: 'Together with our software developers and analysts, we begin by discussing and analyzing our client’s needs, sketching the outline of the desired software development services, identifying gaps and proposing Software development solutions along the way.',
    link: ''
  },
  {
    icon: icon2,
    title: 'Prototyping',
    desc: 'We help our clients and their end- users visualize the look-and-feel and interaction of the finished product before it is actually built using state- of-the-art design and prototyping tools. ',
    link: ''
  },
  {
    icon: icon3,
    title: 'Software Specifications',
    desc: 'We write a Software development specifications document (Software development blueprint) that ensures that our clients get exactly what they expected Software development services and agreed to - in terms of functionality and the product’s UI and UX design.',
    link: ''
  },
  {
    icon: icon4,
    title: 'Software Development Approach',
    desc: 'We use agile software development style alongside the traditional approaches to ensure reliability of delivery schedule and quality of the end product.  ',
    link: ''
  },
  {
    icon: icon5,
    title: 'Rapid Application Development',
    desc: 'Our software developers make use of cutting-edge tools and software development frameworks to ensure quality code construction in the shortest time possible.',
    link: ''
  },
  {
    icon: icon6,
    title: 'Software Development Approach',
    desc: 'Our software development team is exceptionally skilled in planning the deployment and maintenance of large, complex, and high-visibility software development systems and mobile apps.',
    link: ''
  },
]

const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  slidesToShow: 3,
  speed: 500,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 1000,
  centerPading:'20px',
  dots:true,
  // nextArrow: <SampleNextArrow />,
  // prevArrow: <SamplePrevArrow />
  responsive: [
        {
            breakpoint: 1024, // Desktop view
            settings: {
                slidesToShow: 2, // Show 3 slides at a time
                centerPadding: "100px", // Keep the centerPadding for desktop
            },
        },
        {
            breakpoint: 768, // Tablet view
            settings: {
                slidesToShow: 2, // Show 2 slides at a time
                centerPadding: "60px", // Adjust padding for tablet
            },
        },
        {
          breakpoint: 640, // For smaller screens
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      
        {
            breakpoint: 480, // Mobile view
            settings: {
                slidesToShow: 1, // Show 1 slide at a time
                centerPadding: "0px", // No padding on mobile
                centerMode: false, // Disable centerMode for mobile
            },
        },
    ],
};

const OurApproach = () => {
  return (
//    <div className='h-auto flex flex-col gap-6 justify-center items-center mx-4 sm:mx-8 lg:mx-10'>
//   <p className='text-lg sm:text-2xl lg:text-3xl font-semibold text-center'>
//     Our Approach
//   </p>
//   <Slider
//     {...settings}
//     className='overflow-hidden w-full px-4 sm:px-6 lg:px-8'
//   >
//     {approaches.map((a, index) => (
//       <div key={index} className='flex justify-center items-center my-5 gap-4 sm:gap-6'>
//         <div className='max-w-[304px] max-h-[404px] p-6 gap-2 flex flex-col items-center justify-center shadow-md'>
//           <div className='flex flex-col items-center gap-2'>
//             <img src={a.icon} alt='icon' className='w-12 h-12' />
//             <p className='text-base sm:text-lg font-bold text-center'>
//               {a.title}
//             </p>
//             <p className='text-sm sm:text-base font-normal text-left'>
//               {a.desc}
//             </p>
//           </div>
//           <button className='bg-blue-600 w-28 h-10 p-2 rounded text-white hover:bg-blue-700'>
//             View Detail
//           </button>
//         </div>
//       </div>
//     ))}
//   </Slider>
// </div>

<div className='h-[500px]  flex flex-col  gap-6 justify-center items-center mx-4 sm:mx-8 lg:mx-10'>
  <p className='text-lg sm:text-2xl lg:text-3xl font-semibold text-center '>
    Our Approach
  </p>
  <Slider
    {...settings}
    className='overflow-hidden w-full px-4 sm:px-6 lg:px-8'
  >
    {approaches.map((a, index) => (
      <div
        key={index}
        className='flex justify-center items-center px-4'
      >
        <div className='w-full h-[394px] max-w-[324px] p-6 gap-2 flex flex-col items-center justify-between shadow-lg' style={{boxShadow:'0px 4px 8 px rgba(0,0,0,0.1)'}}>
          <div className='flex flex-col items-center gap-2 flex-grow'>
            <img src={a.icon} alt='icon' className='w-12 h-12' />
            <p className='text-base sm:text-lg font-bold text-center'>
              {a.title}
            </p>
            <p className='text-sm sm:text-base font-normal text-justify'>
              {a.desc}
            </p>
          </div>
          
          <button className="text-[16px] rounded px-6 py-2 bg-[#006BD3] text-[#fff] mt-auto ">
                        View Detail
                    </button>
          {/* </div> */}
        </div>
      </div>
    ))}
  </Slider>
</div>


  );
}

export default OurApproach;
