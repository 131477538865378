import React from "react";
import card1 from "../assets/servicesImages/appdev/card1.1.png";
import card2 from "../assets/servicesImages/appdev/card2.1.png";
import card3 from "../assets/servicesImages/appdev/card3.1.png";

const cards = [
  {
    image: card1,
    title: "Android Application Development",
    desc: "We've built over Various Android apps in a variety of categories, including on-demand, health & Live-streaming, and other popular categories. We build Android apps from the ground up using Java and Kotlin for anything.",
  },
  {
    image: card2,
    title: "iOS Application Development",
    desc: "To include high-end iPhone app development services, we use modern programming languages such as Swift and Objective-C. We've developed iPhone applications in a variety of categories using tried-and-true technologies and methods.",
  },
  {
    image: card3,
    title: "Hybrid Application Development",
    desc: "Hybrid mobile applications are those that use the same code to work on a variety of mobile devices, such as iOS and Android. Many companies prefer hybrid apps because they are more flexible.",
  },
];

const AppDevSection2 = () => {
  return (
    <div className="flex flex-col gap-8 px-4 md:px-20 lg:px-32 mb-10">
      <p className="text-center font-nunito text-xl md:text-3xl font-semibold">
        Our Field of Specialization
      </p>

      <p className="font-nunito text-sm md:text-lg font-medium text-center md:text-justify">
        We provide a wide range of mobile app development services that benefit
        businesses and organizations. Our dedicated team of mobile app
        developers provides various services to meet different business needs.
        Our specialties include:
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 justify-center">
        {cards.map((c, index) => (
          <div
            key={index}
            className="w-full max-w-[350px] mx-auto flex flex-col items-center rounded-lg shadow-lg p-5 bg-white"
          >
            <img
              src={c.image}
              alt="card"
              className="w-full h-[200px] object-cover rounded-lg"
            />
            <p className="text-lg md:text-xl font-bold text-center mt-4 px-4">
              {c.title}
            </p>
            <p className="text-sm md:text-base text-center px-4 mt-2">
              {c.desc}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppDevSection2;
