import React from 'react'
import image1 from '../assets/servicesNavbar/1.png'
import image2 from '../assets/servicesNavbar/2.png'
import image3 from '../assets/servicesNavbar/3.png'
import image4 from '../assets/servicesNavbar/4.png'
import icon1 from '../assets/homeServicesSection/icon3.png'
import icon2 from '../assets/homeServicesSection/icon1.png'
import icon3 from '../assets/homeServicesSection/icon2.png'
import arrow from '../assets/viewdetails.png'
import { Link } from 'react-router-dom'




const services = [
    {
        icon: icon1,
        image: image1,
        title: "AI/ ML Development",
        desc: 'AI/ML (Artificial Intelligence/Machine Learning) uses algorithms to enable machines to learn from data and perform tasks that traditionally require.',
        link: ''

    },

    {
        icon: icon2,
        image: image3,
        title: "Erp Software",
        desc: 'ERP software centralizes and automates business processes, enhancing efficiency and decision-making with real-time visibility across departments.',
        link: ''

    },
    {
        icon: icon3,
        image: image4,
        title: "Web Development",
        desc: 'Web development is the process of bringing websites and web applications to life. It involves everything from the initial design,  planning, coding & Programming ',
        link: ''

    },

]

const ServicesNavbar = () => {

    return (
      <div className='w-full flex bg-[white] h-[390px] p-[24px] px-[18px] shadow-[0px_4px_4px_0px_#00000040] gap-[40px] absolute top-20 left-0 z-10 flex-wrap md:flex-nowrap overflow-x-auto md:overflow-x-visible'>
  {/* Left Section with Services Cards */}
  <div className='hidden md:flex h-[333.3px] pt-[2px] pb-0 pl-0 pr-0 gap-[20px] relative flex-wrap md:flex-nowrap w-full md:w-auto overflow-y-auto'>
    {services.map((s) => (
      <div className='w-full sm:w-[48%] md:w-[209px] h-[330px] shadow-[0px_0px_3px_0px_#3C485826] mb-6 sm:mb-0'>
        <img src={s.image} className='object-cover h-[160px] w-full rounded-t' alt='service' />
        <div className='w-full bg-[#FFF] rounded flex flex-col items-center gap-4'>
          <div className='w-[49px] h-[49px] p-[10px] rounded-[79.48px] shadow-[0_2px_2px_0px_rgba(0,0,0,0.25)] bg-[#FFFFFF] absolute bottom-36'>
            <img src={s.icon} alt='icon1' className='' />
          </div>
          <div className='text-center w-[166px] flex flex-col gap-[9.19px] mt-10'>
            <p className='text-[12px] font-bold leading-[14.71px] text-centers text-[black]'>
              {s.title}
            </p>
            <p className='text-[10px] font-normal leading-[14.71px] text-left text-[black]'>
              {s.desc}
            </p>
            <div className='flex items-center text-[12px] gap-1'>
              <a href='/webdev'>View Details</a>
              <img src={arrow} alt='arrow' className='w-[9px]' />
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>

  <div className="p-4 flex flex-col w-full md:w-[260px] lg:w-[250px] space-y-4 border-l-4 border-gray-300">
     
  {/* Right Section with Navigation Links */}

    <ul className="font-medium space-y-4">
      <Link to="/webdev">
        <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
          <span className="text-sm">Website Development</span>
        </li>
      </Link>
      <Link to="/appdev">
        <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
          <span className="text-sm">Application Development</span>
        </li>
      </Link>
      <Link to="/allindustrymanufacturingerps">
        <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
          <span className="text-sm">Industry Manufacturing ERP</span>
        </li>
      </Link>
      <Link to="/hrms">
        <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
          <span className="text-sm">HRMS Solution</span>
        </li>
      </Link>
      <Link to="/crm">
        <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
          <span className="text-sm">CRM Solution</span>
        </li>
      </Link>
      <Link to="/erp">
        <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
          <span className="text-sm">All-in-One ERP for Schools</span>
        </li>
      </Link>
      <Link to="/csds">
        <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
          <span className="text-sm">Custom Software Development</span>
        </li>
      </Link>
    </ul>
  </div>

  <div className="p-4 flex flex-col w-full md:w-[250px] lg:w-[230px] space-y-4 border-l-4 border-gray-300 overflow-x-auto md:overflow-x-visible">
    <ul className="font-medium space-y-4">
      <Link to="/ai">
        <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
          <span className="text-sm">AI Software Development</span>
        </li>
      </Link>
      <Link to="/ds">
        <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
          <span className="text-sm">Data Science Consulting</span>
        </li>
      </Link>
      <Link to="/devops">
        <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
          <span className="text-sm">DevOps Product</span>
        </li>
      </Link>
      <Link to="/ml">
        <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
          <span className="text-sm">ML Product</span>
        </li>
      </Link>
      <Link to="/st">
        <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
          <span className="text-sm">Software Testing Products</span>
        </li>
      </Link>
      <Link to="/uiux">
        <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
          <span className="text-sm">UI/UX Design</span>
        </li>
      </Link>
    </ul>
  </div>
</div>

// {/* <div className='w-full flex bg-[white] h-[390px] p-[24px] px-[18px] shadow-[0px_4px_4px_0px_#00000040] gap-[40px] absolute top-20 left-0 z-10 flex-wrap md:flex-nowrap overflow-x-auto md:overflow-x-invisible'>
//   {/* Left Section with Services Cards */}
//   <div className='h-[333.3px] pt-[2px] pb-0 pl-0 pr-0 gap-[20px] relative flex flex-wrap md:flex-nowrap w-full md:w-auto overflow-y-auto'>
//     {services.map((s) => (
//       <div className='w-full sm:w-[48%] md:w-[209px] h-[330px] shadow-[0px_0px_3px_0px_#3C485826] mb-6 sm:mb-0'>
//         <img src={s.image} className='object-cover h-[160px] w-full rounded-t' alt='service' />
//         <div className='w-full bg-[#FFF] rounded flex flex-col items-center gap-4'>
//           <div className='w-[49px] h-[49px] p-[10px] rounded-[79.48px] shadow-[0_2px_2px_0px_rgba(0,0,0,0.25)] bg-[#FFFFFF] absolute bottom-36'>
//             <img src={s.icon} alt='icon1' className='' />
//           </div>
//           <div className='text-center w-[166px] flex flex-col gap-[9.19px] mt-10'>
//             <p className='text-[12px] font-bold leading-[14.71px] text-centers text-[black]'>
//               {s.title}
//             </p>
//             <p className='text-[10px] font-normal leading-[14.71px] text-left text-[black]'>
//               {s.desc}
//             </p>
//             <div className='flex items-center text-[12px] gap-1'>
//               <a href='/webdev'>View Details</a>
//               <img src={arrow} alt='arrow' className='w-[9px]' />
//             </div>
//           </div>
//         </div>
//       </div>
//     ))}
//   </div>

//   {/* Right Section with Navigation Links */}
//   <div className="p-4 flex flex-col w-full md:w-[260px] lg:w-[250px] space-y-4 border-l-4 border-gray-300 overflow-x-auto md:overflow-x-visible">
//     <ul className="font-medium space-y-4">
//       <Link to="/webdev">
//         <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
//           <span className="text-sm">Website Development</span>
//         </li>
//       </Link>
//       <Link to="/appdev">
//         <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
//           <span className="text-sm">Application Development</span>
//         </li>
//       </Link>
//       <Link to="/allindustrymanufacturingerps">
//         <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
//           <span className="text-sm">Industry Manufacturing ERP</span>
//         </li>
//       </Link>
//       <Link to="/hrms">
//         <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
//           <span className="text-sm">HRMS Solution</span>
//         </li>
//       </Link>
//       <Link to="/crm">
//         <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
//           <span className="text-sm">CRM Solution</span>
//         </li>
//       </Link>
//       <Link to="/erp">
//         <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
//           <span className="text-sm">All-in-One ERP for Schools</span>
//         </li>
//       </Link>
//       <Link to="/csds">
//         <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
//           <span className="text-sm">Custom Software Development</span>
//         </li>
//       </Link>
//     </ul>
//   </div>

//   <div className="p-4 flex flex-col w-full md:w-[250px] lg:w-[230px] space-y-4 border-l-4 border-gray-300 overflow-x-auto md:overflow-x-visible">
//     <ul className="font-medium space-y-4">
//       <Link to="/ai">
//         <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
//           <span className="text-sm">AI Software Development</span>
//         </li>
//       </Link>
//       <Link to="/ds">
//         <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
//           <span className="text-sm">Data Science Consulting</span>
//         </li>
//       </Link>
//       <Link to="/devops">
//         <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
//           <span className="text-sm">DevOps Product</span>
//         </li>
//       </Link>
//       <Link to="/ml">
//         <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
//           <span className="text-sm">ML Product</span>
//         </li>
//       </Link>
//       <Link to="/st">
//         <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
//           <span className="text-sm">Software Testing Products</span>
//         </li>
//       </Link>
//       <Link to="/uiux">
//         <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
//           <span className="text-sm">UI/UX Design</span>
//         </li>
//       </Link>
//     </ul>
//   </div>
// </div> */

//         <div className='w-full flex bg-white h-[390px] p-[24px] px-[18px] shadow-[0px_4px_4px_0px_#00000040] gap-[40px] absolute top-20 left-0 z-10 overflow-x-auto' >
//     {/* <!-- Apply overflow-x-auto only on the flex container that should be scrollable --> */}
//     <div className='flex flex-nowrap space-x-[20px]  w-full'>
//         {services.map((s) => (
//             <div className='w-[209px] h-[330px] shadow-[0px_0px_3px_0px_#3C485826] mb-6'>
//                 <img src={s.image} className='object-cover h-[160px] w-full rounded-t' alt='service' />
//                 <div className='w-full bg-[#FFF] rounded flex flex-col items-center gap-4 relative'>
//                     {/* <!-- Icon container adjusted for mobile responsiveness --> */}
//                     <div className='w-[49px] h-[49px] p-[10px] rounded-[79.48px] shadow-[0_2px_2px_0px_rgba(0,0,0,0.25)] bg-[#FFFFFF] absolute -top-[25px] sm:top-[-40px]'>
//                         <img src={s.icon} alt='icon1' className='w-[24px] h-[24px] object-contain' />
//                     </div>
//                     <div className='text-center w-[166px] flex flex-col gap-[9.19px] mt-[30px]'>
//                         <p className='text-[12px] font-bold leading-[14.71px] text-center text-[black]'>
//                             {s.title}
//                         </p>
//                         <p className='text-[10px] font-normal leading-[14.71px] text-left text-[black]'>
//                             {s.desc}
//                         </p>
//                         <div className='flex items-center text-[12px] gap-1'>
//                             <a href='/webdev'>View Details</a>
//                             <img src={arrow} alt='arrow' className='w-[9px]' />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         ))}
//     </div>
// {/* </div> */}

    
 
    
    
     
//         <div className="p-4 flex flex-col w-full md:w-[280px] lg:w-[250px] space-y-4 border-l-4 border-gray-300">
//         <ul className="font-medium space-y-4">
//             <Link to="/webdev">
//                 <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
                   
//                     <span className="text-[10px] sm:text-xs md:text-sm ">Website Development</span>
//                 </li>
//             </Link>
//             <Link to="/appdev">
//                 <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
                
//                     <span className="text-[10px] sm:text-xs md:text-sm ">Application Development</span>
//                 </li>
//             </Link>
//             <Link to="/allindustrymanufacturingerps">
//                 <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
                    
//                     <span className="text-[10px] sm:text-xs md:text-sm ">Industry Manufacturing ERP</span>
//                 </li>
//             </Link>
//             <Link to="/hrms">
//                 <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
                  
//                     <span className="text-[10px] sm:text-xs md:text-sm ">HRMS Solution</span>
//                 </li>
//             </Link>
//             <Link to="/crm">
//                 <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
                   
//                     <span className="text-[10px] sm:text-xs md:text-sm ">CRM Solution</span>
//                 </li>
//             </Link>
//             <Link to="/erp">
//                 <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
              
//                     <span className="text-[10px] sm:text-xs md:text-sm ">All-in-One ERP for Schools</span>
//                 </li>
//             </Link>
//             <Link to="/csds">
//                 <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
                  
//                     <span className="text-[10px] sm:text-xs md:text-sm ">Custom Software Development</span>
//                 </li>
//             </Link>
//         </ul>
//     </div>
    
    
//     <div className="p-4 flex flex-col w-full md:w-[250px] lg:w-[230px] space-y-4 border-l-4 border-gray-300 ">
//         <ul className="font-medium space-y-4">
//             <Link to="/ai">
//                 <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
                    
//                     <span className="text-[10px] sm:text-xs md:text-sm ">AI Software Development</span>
//                 </li>
//             </Link>
//             <Link to="/ds">
//                 <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
                  
//                     <span className="text-[10px] sm:text-xs md:text-sm ">Data Science Consulting</span>
//                 </li>
//             </Link>
//             <Link to="/devops">
//                 <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
//                     {/* <span className="material-icons-outlined text-[#0058AE] text-base">devices</span> */}
//                     <span className="text-[10px] sm:text-xs md:text-sm ">DevOps Product</span>
//                 </li>
//             </Link>
//             <Link to="/ml">
//                 <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
                   
//                     <span className="text-[10px] sm:text-xs md:text-sm ">ML Product</span>
//                 </li>
//             </Link>
//             <Link to="/st">
//                 <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
    
//                     <span className="text-[10px] sm:text-xs md:text-sm ">Software Testing Products</span>
//                 </li>
//             </Link>
//             <Link to="/uiux">
//                 <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
                  
//                     <span className="text-[10px] sm:text-xs md:text-sm ">UI/UX Design</span>
//                 </li>
//             </Link>
//         </ul>
//     </div>
//         {/* Right Section */}
        
    
       
//     </div>
//         <div className='w-full flex bg-white h-[390px]  overflow-x-auto p-[24px] px-[18px] shadow-[0px_4px_4px_0px_#00000040] gap-[40px] absolute top-20 left-0 z-10'>
//     <div className='flex flex-nowrap space-x-[20px]'>
//         {services.map((s) => (
//             <div className='w-[209px] h-[330px] shadow-[0px_0px_3px_0px_#3C485826] mb-6'>
//                 <img src={s.image} className='object-cover h-[160px] w-full rounded-t' alt='service' />
//                 <div className='w-full bg-[#FFF] rounded flex flex-col items-center gap-4'>
//                     <div className='w-[49px] h-[49px] p-[10px] rounded-[79.48px] shadow-[0_2px_2px_0px_rgba(0,0,0,0.25)] bg-[#FFFFFF] absolute bottom-36'>
//                         <img src={s.icon} alt='icon1' className='' />
//                     </div>
//                     <div className='text-center w-[166px] flex flex-col gap-[9.19px] mt-10'>
//                         <p className='text-[12px] font-bold leading-[14.71px] text-center text-[black]'>
//                             {s.title}
//                         </p>
//                         <p className='text-[10px] font-normal leading-[14.71px] text-left text-[black]'>
//                             {s.desc}
//                         </p>
//                         <div className='flex items-center text-[12px] gap-1'>
//                             <a href='/webdev'>View Details</a>
//                             <img src={arrow} alt='arrow' className='w-[9px]' />
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         ))}
//     </div>
// {/* </div> */}


 
//     <div className="p-4 flex flex-col w-full md:w-[260px] lg:w-[250px] space-y-4 border-l-4 border-gray-300">
//     <ul className="font-medium space-y-4">
//         <Link to="/webdev">
//             <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
               
//                 <span className="text-sm">Website Development</span>
//             </li>
//         </Link>
//         <Link to="/appdev">
//             <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
            
//                 <span className="text-sm">Application Development</span>
//             </li>
//         </Link>
//         <Link to="/allindustrymanufacturingerps">
//             <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
                
//                 <span className="text-sm">Industry Manufacturing ERP</span>
//             </li>
//         </Link>
//         <Link to="/hrms">
//             <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
              
//                 <span className="text-sm">HRMS Solution</span>
//             </li>
//         </Link>
//         <Link to="/crm">
//             <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
               
//                 <span className="text-sm">CRM Solution</span>
//             </li>
//         </Link>
//         <Link to="/erp">
//             <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
          
//                 <span className="text-sm">All-in-One ERP for Schools</span>
//             </li>
//         </Link>
//         <Link to="/csds">
//             <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
              
//                 <span className="text-sm">Custom Software Development</span>
//             </li>
//         </Link>
//     </ul>
// </div>


// <div className="p-4 flex flex-col w-full md:w-[250px] lg:w-[230px] space-y-4 border-l-4 border-gray-300 ">
//     <ul className="font-medium space-y-4">
//         <Link to="/ai">
//             <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
                
//                 <span className="text-sm">AI Software Development</span>
//             </li>
//         </Link>
//         <Link to="/ds">
//             <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
              
//                 <span className="text-sm">Data Science Consulting</span>
//             </li>
//         </Link>
//         <Link to="/devops">
//             <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
//                 {/* <span className="material-icons-outlined text-[#0058AE] text-base">devices</span> */}
//                 <span className="text-sm">DevOps Product</span>
//             </li>
//         </Link>
//         <Link to="/ml">
//             <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
               
//                 <span className="text-sm">ML Product</span>
//             </li>
//         </Link>
//         <Link to="/st">
//             <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">

//                 <span className="text-sm">Software Testing Products</span>
//             </li>
//         </Link>
//         <Link to="/uiux">
//             <li className="flex items-center space-x-3 text-black hover:text-[#0058AE] hover:bg-[#f0f9ff] hover:shadow-lg p-3 rounded-md transition-all duration-200">
              
//                 <span className="text-sm">UI/UX Design</span>
//             </li>
//         </Link>
//     </ul>
// </div>
//     {/* Right Section */}
    

   
// </div>

        // <div className='w-full flex bg-[white] h-[390px] p-[24px] px-[18px] shadow-[0px_4px_4px_0px_#00000040] gap-[40px] absolute top-20 left-0 z-10 '>

        //     <div className=' h-[333.3px] pt-[2px] pb-0 pl-0 pr-0 gap-[20px] relative flex'>

        //         {services.map((s) => (
        //             <div className='w-[209px] h-[330px] shadow-[0px_0px_3px_0px_#3C485826]'>

        //                 <img src={s.image} className='object-cover h-[160px] w-full rounded-t' alt='service' />

        //                 <div className='w-[209px] bg-[#FFF] rounded flex flex-col items-center  gap-4 '>
        //                     <div className='w-[49px] h-[49px] p-[10px] rounded-[79.48px] shadow-[0_2px_2px_0px_rgba(0,0,0,0.25)] bg-[#FFFFFF] absolute bottom-36'>
        //                         <img src={s.icon} alt='icon1' className=' '
        //                         />
        //                     </div>
        //                     <div className='text-center w-[166px] flex flex-col gap-[9.19px] mt-10'>
        //                         <p className='text-[12px] font-bold leading-[14.71px] text-centers text-[black]'>
        //                             {s.title}
        //                         </p>
        //                         <p className='text-[10px] font-normal leading-[14.71px] text-left text-[black] '>
        //                             {s.desc}
        //                         </p>
        //                         <div className='flex items-center text-[12px] gap-1'>
        //                             <a href='/webdev'>View Details</a>
        //                             <img src={arrow} alt='arrow' className='w-[9px]' />

        //                         </div>
        //                     </div>

        //                 </div>
        //             </div>
        //         ))}
        //     </div>
        //     <div className='border-l-2 p-4 flex flex-col justify-center'>
        //         <ul className='font-medium'>
        //             <Link to='/webdev'><li className='mb-[8px] text-[black] hover:text-[#0058AE]'>Website Devlopment</li></Link>
        //             <Link to='/appdev'><li className='mb-[8px] text-[black] hover:text-[#0058AE]'>Application Development</li></Link>
        //             <Link to='/allindustrymanufacturingerps'><li className='mb-[8px] text-[black] hover:text-[#0058AE]'>For all Industry manufacturing ERP</li></Link>
        //             <Link to='/hrms'><li className='mb-[8px] text-[black] hover:text-[#0058AE]'>Esparse HRMS Solution</li></Link>
        //             <Link to='/crm'><li className='mb-[8px] text-[black] hover:text-[#0058AE]'>Esparse CRM Solution</li></Link>
        //             <Link to='/erp'><li className='mb-[8px] text-[black] hover:text-[#0058AE]'>All in one ERP for School and Collage</li></Link>
        //             <Link to='/csds'><li className='mb-[8px] text-[black] hover:text-[#0058AE]'>Custom Software development Services</li></Link>

        //         </ul>
        //     </div>

        //     <div className='border-l-2 p-4 flex flex-col '>
        //         <ul className='font-medium'>
        //             <Link to='/ai'><li className='mb-[8px] text-[black] hover:text-[#0058AE]'>AI Software Devlopment</li></Link>
        //             <Link to='/ds'><li className='mb-[8px] text-[black] hover:text-[#0058AE]'>Data Science Consulting services</li></Link>
        //             <Link to='/devops'><li className='mb-[8px] text-[black] hover:text-[#0058AE]'>Dev ops Product</li></Link>
        //             <Link to='/ml'><li className='mb-[8px] text-[black] hover:text-[#0058AE]'>ML Product</li></Link>
        //             <Link to='/st'><li className='mb-[8px] text-[black] hover:text-[#0058AE]'>ESoftware Testing Products</li></Link>
        //             <Link to='/uiux'><li className='mb-[8px] text-[black] hover:text-[#0058AE]'>UI/UX Design</li></Link>
        //         </ul>
        //     </div>

        // </div>

    )
}

export default ServicesNavbar