import React from 'react'
import bgImage from '../assets/aboutHero.png'

const AboutSection1 = () => {
    return (
        <div
        className="min-h-screen w-full text-white flex justify-start items-center pt-24 relative bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)] z-0"></div>
      
        {/* Content */}
        <div className="relative z-10 flex flex-col gap-6 px-4 sm:px-8 md:px-16 lg:px-28">
          <div className="flex flex-col gap-4 md:gap-6 lg:gap-8 max-w-3xl">
            {/* Title */}
            <p className="font-nunito text-[28px] sm:text-[36px] md:text-[42px] lg:text-[48px] leading-tight text-left">
              About Us
            </p>
      
            {/* Description */}
            <p className="text-[14px] sm:text-[16px] md:text-[18px] lg:text-[18px] leading-relaxed text-justify">
              At eSparse Matrix Solutions, We will provide a one-stop software
              development services for all customers. We will take care of all your
              needs right from Strategic concept software development, Business
              analysis, software development services to Digital Marketing all in one
              place. Once you join with eSparse Matrix Solutions, you will never have
              to go anywhere else again.
            </p>
          </div>
        </div>
      </div>
      

        // <div className='h-screen w-full text-[#fff] flex  justify-start items-center pt-24 relative bg-cover bg-center'
        //     style={{ backgroundImage: `url(${bgImage})` }}>
        //     <div className="absolute inset-0 bg-[rgba(89, 119, 221, 0.15)]  z-0"></div>
        //     <div className='flex flex-col gap-[48px] p-28 z-10'>
        //         <div className='flex flex-col w-[790px] h-[270px] gap-[32px]'>
        //             <p className='font-nunito text-[48px] leading-[67.2px] text-left'>
        //                 About Us
        //             </p>
        //             <p className="text-[18px] font-normal leading-[25.6px] text-left">
        //                 At eSparse Matrix Solutions, We will provide a one-stopSoftware development services for all customer, we will take care of all your needs right from Strategic conceptSoftwaredevelopment, Business analysis, Software developmentservices to Digital Marketing all in one place. Once you join with eSparse Matrix Solutions, you will never have to go anywhere else again.
        //             </p>
        //         </div>
        //     </div>
        // </div>
    )
}

export default AboutSection1