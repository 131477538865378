import React from 'react'


const HomeStats = () => {
    return (
      <div className='bg-[#EAEEFB] mx-4 sm:mx-8 lg:mx-24 p-6 sm:p-12 lg:p-[68px_88px] flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0'>
  {/* First Box */}
  <div className='bg-[#FFFFFFBA] w-full sm:w-[250px] lg:w-[321px] h-[140px] sm:h-[165px] pt-4 px-4 sm:px-6 flex flex-col gap-2 sm:gap-3 rounded border-2'>
    <p className='text-center text-[48px] sm:text-[56px] font-semibold leading-[75px] text-[#2F55D4]'>100+</p>
    <p className='text-[16px] sm:text-[20px] font-bold leading-[25.6px] text-center text-[#2F55D4] px-4 sm:px-10'>PROJECTS DELIVERED</p>
  </div>

  {/* Second Box */}
  <div className='bg-[#FFFFFFBA] w-full sm:w-[250px] lg:w-[321px] h-[140px] sm:h-[165px] pt-4 px-4 sm:px-6 flex flex-col gap-2 sm:gap-3 rounded border-2'>
    <p className='text-[48px] sm:text-[56px] font-semibold leading-[75px] text-center text-[#2F55D4]'>4+</p>
    <p className='text-[16px] sm:text-[20px] font-bold leading-[25.6px] text-center text-[#2F55D4] px-4 sm:px-10'>YEARS IN OPERATION</p>
  </div>

  {/* Third Box */}
  <div className='bg-[#FFFFFFBA] w-full sm:w-[250px] lg:w-[321px] h-[140px] sm:h-[165px] pt-4 px-4 sm:px-6 flex flex-col gap-2 sm:gap-3 rounded border-2 font-nunito'>
    <p className='text-[48px] sm:text-[56px] font-semibold leading-[75px] text-center text-[#2F55D4]'>1</p>
    <p className='text-[16px] sm:text-[20px] font-bold leading-[25.6px] text-center text-[#2F55D4] px-4 sm:px-10'>GLOBAL OFFICES</p>
  </div>
</div>

//         <div className='bg-[#EAEEFB] mx-4 sm:mx-8 lg:mx-24 p-6 sm:p-[68px_88px] flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0'>
//   {/* <!-- First Box --> */}
//   <div className='bg-[#FFFFFFBA] w-full sm:w-[250px] lg:w-[321px] h-[140px] sm:h-[165px] pt-[4px] px-[15px] flex flex-col gap-[7.2px] rounded border-2 '>
//     <p className='text-center text-[48px] sm:text-[56px] font-semibold leading-[75px] text-[#2F55D4]'>100+</p>
//     <p className='text-[16px] sm:text-[20px] font-bold leading-[25.6px] text-center text-[#2F55D4] px-4 sm:px-20'>PROJECTS DELIVERED</p>
//   </div>

//   {/* <!-- Second Box --> */}
//   <div className='bg-[#FFFFFFBA] w-full sm:w-[250px] lg:w-[321px] h-[140px] sm:h-[165px] pt-[4px] px-[15px] flex flex-col gap-[7.2px] rounded border-2 '>
//     <p className='text-[48px] sm:text-[56px] font-semibold leading-[75px] text-center text-[#2F55D4]'>4+</p>
//     <p className='text-[16px] sm:text-[20px] font-bold leading-[25.6px] text-center text-[#2F55D4] px-4 sm:px-20'>YEARS IN OPERATION</p>
//   </div>

//   {/* <!-- Third Box --> */}
//   <div className='bg-[#FFFFFFBA] w-full sm:w-[250px] lg:w-[321px] h-[140px] sm:h-[165px] pt-[4px] px-[15px] flex flex-col gap-[7.2px] rounded border-2 font-nunito '>
//     <p className='text-[48px] sm:text-[56px] font-semibold leading-[75px] text-center text-[#2F55D4] text-nunito'>1</p>
//     <p className='text-[16px] sm:text-[20px] font-bold leading-[25.6px] text-center text-[#2F55D4] px-4 sm:px-20'>GLOBAL OFFICES</p>
//   </div>
// </div>

        // <div className='bg-[#EAEEFB] h-[250px] mx-24 p-[68px_88px] flex justify-between items-center'>

        //     <div className='bg-[#fff] w-[321px] h-[165px] pt-[4px] px-[15px] flex flex-col gap-[7.2px] rounded'>
        //         <p className='text-center text-[56px] font-semibold leading-[75px] text-[#2F55D4]'>100+</p>
        //         <p className='text-[20px] font-bold leading-[25.6px] text-center text-[#2F55D4] px-20'>PROJECTS DELIVERED</p>
        //     </div>
        //     <div className='bg-[#fff] w-[321px] h-[165px] pt-[4px] px-[15px] flex flex-col gap-[7.2px] rounded'>
        //         <p className='text-[56px] font-semibold leading-[75px] text-center text-[#2F55D4]'>4+</p>
        //         <p className='text-[20px] font-bold leading-[25.6px] text-center text-[#2F55D4] px-20'>YEARS IN OPERATION</p>
        //     </div>
        //     <div className='bg-[#fff] w-[321px] h-[165px] pt-[4px] px-[15px] flex flex-col gap-[7.2px] rounded'>
        //         <p className='text-[56px] font-semibold leading-[75px] text-center text-[#2F55D4]'>1</p>
        //         <p className='text-[20px] font-bold leading-[25.6px] text-center text-[#2F55D4] px-20'>GLOBAL OFFICES</p>
        //     </div>

        // </div>
    )
}

export default HomeStats