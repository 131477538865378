import React from "react";
import l1 from "../assets/icons/one-stop.png";
import l2 from "../assets/icons/continuous-image.png";
import l3 from "../assets/icons/strong-tech.png";
import l4 from "../assets/icons/priority-to-client.png";
import wcu1 from "../assets/homeWCU/image1.png";
import wcu2 from "../assets/homeWCU/image2.png";
import wcu3 from "../assets/homeWCU/image3.png";
import wcu4 from "../assets/homeWCU/image4.png";

const listItems = [
  {
    icon: l1,
    text: "One-stop Solutions",
  },
  {
    icon: l2, // No icon for this item
    text: "Continuous Improvement",
  },
  {
    icon: l3, // No icon for this item
    text: "Strong Technical Expertise",
  },
  {
    icon: l4, // No icon for this item
    text: "Priority to Client Satisfaction",
  },
];

const WhyChooseUs = () => {
  return (
    <div className="h-auto md:h-[608px] lg:h-[608px] flex flex-col md:gap-[36px] items-center relative">
      {/* <p className="text-[32px] font-semibold leading-[23.4px] text-center">
        Why to choose us
      </p> */}
      <p className="text-xl md:text-2xl font-semibold uppercase  font-medium">
        Why to choose us
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 px-5 md:px-20 gap-x-10">
        <div className="w-full flex flex-col gap-y-5">
          {/* <p className="text-[28px] font-medium leading-[36px] text-left">
            "Why eSparse Matrix Solutions is Your Ideal Technology Partner"
          </p> */}
          <h1 className="text-lg md:text-xl text-start font-bold leading-tight text-gray-700 py-3 capitalize">
            Why eSparse Matrix Solutions is Your Ideal Technology Partner
          </h1>
          {/* <p className="text-gray-500">
            Business tailored it design, management & support services business
            agency elit, sed do eiusmod tempor.
          </p> */}

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-6">
            {listItems.map((item, index) => (
              <div
                key={index}
                className="flex flex-col items-center gap-4 p-4 sm:p-6 border-2 rounded-[20px]  hover:border-dashed hover:border-[#1E90FF]"
              >
                {item.icon ? (
                  <div className="bg-[#1E90FF] w-16 h-16 sm:w-20 sm:h-20 rounded-full flex items-center justify-center">
                    <img
                      src={item.icon}
                      alt={`icon-${index}`}
                      className="object-contain p-3 sm:p-4"
                    />
                  </div>
                ) : (
                  <div className="w-16 h-16 sm:w-20 sm:h-20 rounded-full bg-gray-300 flex items-center justify-center">
                    <span className="text-gray-500">N/A</span>
                  </div>
                )}
                <p className="text-[16px] sm:text-[18px] font-semibold text-center">
                  {item.text}
                </p>
              </div>
            ))}
          </div>
          {/* <div className="flex flex-col gap-[28px]">
            {listItems.map((item, index) => (
              <p
                key={index}
                className="flex text-[20px] font-semibold leading-[30px] text-left items-center gap-[44px] w-[540px] h-[64px]"
              >
                {item.icon && (
                  <div className="bg-[#1E90FF]  w-[64px] h-[64px] rounded-[79.48px] ">
                    <img
                      src={item.icon}
                      alt={`icon-${index}`}
                      className="object-contain p-4"
                    />
                  </div>
                )}
                <li>{item.text}</li>
              </p>
            ))}
          </div> */}
        </div>
        <div className="w-full md:w-1/2 lg:w-1/2 ">
          {/* {/ <div className="bg-[#EAEEFB] w-full h-[399.64px] lg:block hidden z-0 absolute bottom-0 right-4"></div> /} */}
          <div className="bg-[#EAEEFB]  md:w-[500px] h-[399.64px] z-0 absolute bottom-0 right-17"></div>
          <div className="relative w-full lg:w-[537.77px] lg:h-[521.07px] z-10">
            <img
              src={wcu2}
              alt="wcu"
              className="w-full sm:w-full pt-5 md:pt-0 sm:h-auto object-contain block lg:hidden"
            />
            <div className="lg:block hidden">
              <img
                src={wcu1}
                alt="wcu"
                className="w-[178.52px] h-[254.53px] object-contain absolute top-0 left-2 bottom-3"
              />
              <img
                src={wcu2}
                alt="wcu"
                className="w-[334.8px] h-[223.35px] object-contain absolute bottom-8 left-0"
              />
              <img
                src={wcu3}
                alt="wcu"
                className="w-[347.25px] h-[231.5px] object-contain absolute top-0 right-0"
              />
              <img
                src={wcu4}
                alt="wcu"
                className="w-[178.53px] h-[254.53px] object-contain absolute bottom-0 right-0"
              />
            </div>
          </div>
        </div>
        {/*  <div className='w-1/2'>
                    <div className="bg-[#EAEEFB] w-[728.83px] h-[399.64px] z-0 absolute bottom-0 right-4"></div>
                    <div className='w-[537.77px] h-[521.07px] z-10 absolute right-32'>
                        <img src={wcu1} alt='wcu' className='w-[178.52px] h-[254.53px]'/>
                        <img src={wcu2} alt='wcu' className='w-[334.8px] h-[223.35px] absolute bottom-8'/>
                        <img src={wcu3} alt='wcu' className='w-[347.25px] h-[231.5px] absolute top-0 right-0'/>
                        <img src={wcu4} alt='wcu' className='w-[178.53px] h-[254.53px] absolute bottom-0 right-0'/>
                    </div>

                </div> */}
      </div>
    </div>
  );
};

export default WhyChooseUs;
